import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { DB } from "../FirebaseConfig/FirebaseConfig";
import { PlanInterface } from "../Interfaces/PlansInterface";

export const GetActivePlans = async (): Promise<PlanInterface[]> => {
    const q = query(collection(DB, "Plan"), orderBy("createdAt", "desc"));
    const response = await getDocs(q);
    let data: PlanInterface[] = [];
    response.forEach((doc) => {
        data.push(doc.data() as PlanInterface);
    });
    return data;
}

export const GetPlanById = async (id: string): Promise<PlanInterface | undefined> => {
    const response = await getDoc(doc(DB, "Plan", id));
    if(response.exists()) {
        return response.data() as PlanInterface;
    } else {
        return undefined;
    }
}

export const GetPlanByCulqiId = async (id: string): Promise<PlanInterface | undefined> => {
    const q = query(collection(DB, "Plan"), where("culqiPlanId", "==", id));

    const response = await getDocs(q);
    let data: PlanInterface[] = [];
    response.forEach((doc) => {
        data.push(doc.data() as PlanInterface);
    });
    return data.length > 0 ? data[0] : undefined;
}