import React from "react";
import "./CardGradient.css";

interface CardGradientProps {
    children?: React.ReactNode;
    className?: string;
    handleClickCard?: () => void;
};

export const CardGradient: React.FC<CardGradientProps> = ({
    children,
    className="",
    handleClickCard = () => {}
}) => {
  return (
    <div className={`card-gradient ${className}`} onClick={()=>handleClickCard()}>
      <div className="card-gradient-content card--gradient-text">
        {children}
      </div>
    </div>
  );
};