import React, { useState } from "react";

import './RecoverAccount.css';
import { LabelText } from "../LabelText/LabelText";
import { Button } from "../Button/Button";
import { CardBackground } from "../CardBackground/CardBackground";
import { TextField } from "../TextField/TextField";

interface RecoverAccountProps {
    handleClick: () => void;
}

export const RecoverAccount: React.FC<RecoverAccountProps> = ({
    handleClick
}) => {

    return (
        <div className="recoverAccount">
            <TextField
                label="Ingresa código"
                placeholder=""
                value="Ingresa el código"
                onChange={()=> {}}
            />

            <Button
                text="Verificar"
                color="orange"
                onClick={() => handleClick()}
            />
        </div>
    )
}