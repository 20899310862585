import axios from "axios";
import { IpData } from "../Interfaces/IpDataInterface";

//method to get the ip data
export const getIpData = async (): Promise<IpData>=> {
    try {
        const response = await axios.get("https://ipapi.co/json/");
        return response.data;
    } catch (error) {
        console.log("🚀 ~ getIpData ~ error:", error)
        throw error;
    }
};