import React from "react";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

type GmailProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Gmail: React.FC<GmailProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/IconGmail.svg"} alt="" />
    </div>
  );
};