import React from "react";

import './LandingPage.css';

import { Cover } from "./Cover/Cover";
import { AboutSection } from "../../Components/AboutSection/AboutSection";
import { Horoscope } from "./Horoscope/Horoscope";
import { PlanSection } from "../../Components/PlanSection/PlanSection";
import { ReffledProducts } from "../../Components/ReffledProducts/ReffledProducts";
import { WhatsappSection } from "../../Components/WhatsappSection/WhatsappSection";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";


interface LandingPageProps {

}

export const LandingPage: React.FC<LandingPageProps> = () => {

  const handleButtonClick = () => {
    const element = document.getElementById('planSection');

    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="landingPage">
      <div className="landingPage-content">
        <div className="landingPage-First" 
          style={{backgroundImage: window.innerWidth > 501 ? 
            `url(${STORAGE_ASSETS}/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TAMA_SIN-ZINOS.png`: 
            `url(${STORAGE_ASSETS}/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TAMA_SIN-ZINOS.png`}}>
          <div className="landingPage-container">
            <Cover onClickButton={handleButtonClick} />
            <Horoscope onClickShowMore={handleButtonClick} />
            {/* <ReffledProducts onClickProduct={handleButtonClick} /> */}
            <PlanSection /> 
          </div>
        </div>
      </div>
      <div className="landingPage-Second">
        <WhatsappSection />
        <AboutSection />
        </div>
    </div>
  )
}