import React from "react";

import './TableHeader.css';

interface TableHeaderProps {
    name: string[]
}

export const TableHeader: React.FC<TableHeaderProps> = ({
    name,
}) => {
    return (
        <div className="tableHeader">
            {name.map((item, idx) => (
                <div key={idx} className="tinytext-header tableHeader-text">{item}</div>
            ))}
        </div>
    ) 
}