import React, { useEffect, useState } from "react";

// Importando estilo
import "./Hamburger.css";
import { Menu } from "../../Icons/outline/Menu";

interface HamburgerProps {
    item: any[],
    handleClickHamburger: () => void,
}

export const Hamburger: React.FC<HamburgerProps> = ({
    item,
    handleClickHamburger
}) => {

  
  return (
    <div className="hamburger">
        <Menu onClick={()=>handleClickHamburger()}/>
    </div>
  );
};