import React, { useEffect, useState } from "react";

// Importando estilo
import "./Navbar.css";
import { LogoWhite } from "../../Icons/solid/LogoWhite";
import { Hamburger } from "../Hamburger/Hamburger";
import { Close } from "../../Icons/outline/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTH_LOGIN, AUTH_MODAL, HOME, LANDING, MY_PROFILE } from "../../Const/BaseUrlConst";
import { useSelector } from "react-redux";
import { NavItemInterface } from "../../Interfaces/NavItemInterface";
import AuthService from "../../Service/Auth.service";
import { toast } from "react-toastify";

interface NavbarProps {
  item: NavItemInterface[]
}

export const Navbar: React.FC<NavbarProps> = ({
  item
}) => {

  const navigation = useNavigate();
  const location = useLocation();

  const {userDetail} = useSelector((state: any) => state.user);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [pageActive, setPageActive] = useState<string>("");

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleLoginClick = () => {
    navigation(`?${AUTH_MODAL}=${AUTH_LOGIN}`);
  }


  // method to scroll to the section
  const scrollToSection = (section: string) => {
    const element = document.getElementById(section);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // method to handle the click on the nav item
  const handleNavItemClick = async (item: NavItemInterface) => {
    setShowNavbar(false);
    if(item.text === "Cerrar sesión") {
     try {
      const response = await AuthService.LogOutUser();
      if(response) {
          window.location.href = window.location.origin+'/';
      }
     } catch (error) {
      console.log("🚀 ~ handleNavItemClick ~ error:", error)
      toast.error("Error al cerrar sesión");
     }
    }
    
    if(item.activePath.includes(LANDING) && !isLoggedIn) {
      scrollToSection(item.id || "");
    } else {  
      navigation( item.text === "Inicio" ?  `/${HOME}` : item.path);
    }
  }

  // method to handle the click on the menu
  const handleMenuClick = ()=> {
    navigation(`/${!isLoggedIn ? "" : HOME}`);
  }

  // method to handle the click on the my profile
  const handleMyProfileClick = () => {
    navigation(`/${MY_PROFILE}`);
  }

  // method to get the page active
  const getPageActive = () => {
    const path = location.pathname;
    if(path === "/") {
      setPageActive("/");
    } else {
      setPageActive(path.split("/")[1]);
    }   
  };

  // methods to check if the nav item is active
  const checkNavitemActive = (arrayPath: string[]) : boolean => {
    return arrayPath.includes(pageActive);
  }

  useEffect(() => {
    getPageActive();
  }, [location.pathname]);

  useEffect(() => {
    setIsLoggedIn(userDetail && userDetail.id ? true : false);
  }, [userDetail]);

   
  return (
    <div className={`navbar ${showNavbar && "navbar-showMenu"}`} 
        // style={{borderImage: "linear-gradient(135deg, rgb(255 255 255) 0%, rgb(255 255 255 / 25%) 100%) 1"}}
        >
      <div className="navbar-container">
        <div className="navbar-left">
          <div className="menu-icon">
            <Hamburger 
              item={item}
              handleClickHamburger={handleShowNavbar}
            />
          </div>
          <div className="logo" onClick={()=>handleMenuClick()}>
            <LogoWhite/>
          </div>
        </div>

        <div className={`navbar-elements ${showNavbar && "active"}`}>
          <div className="navbar-elements-contents">
            <div className="navbar-elements-logo" onClick={()=>handleMenuClick()}>
              <LogoWhite/>
            </div>
            <div className="navbar-list-item">
              {item.length>0 && (isLoggedIn ? (window.innerWidth >= 768 ? item.slice(0,3) : item) : item.slice(0, 3)).map((item, idx) => (
                checkNavitemActive(item.activePath) &&  <div 
                  key={idx}
                  className={`${window.innerWidth < 601 ?  "smalltext-header" : "small-subheader"} navbar-elements-items`}
                  onClick={()=>handleNavItemClick(item)} >
                  {item.text}
                </div>
              ))}
            </div> 
          </div>

          <div className="navbar-elements-icon-close" onClick={handleShowNavbar}>
            <Close/>
          </div>
        </div>


        <div className="navbar-right" onClick={()=> isLoggedIn ? handleMyProfileClick() : handleLoginClick()}>
          <div className="smalltext navbar-elements-items">{isLoggedIn? "Mi perfil" : "Iniciar sesión"}</div>
        </div>

      </div>
    </div>
  );
};
