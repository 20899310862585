import React, { useEffect, useState } from "react";

import "./Giveaways.css";


import { Product } from "../Product/Product";
import { CarouselMonth } from "../CarouselMonth/CarouselMonth";
import { RaffledProductInterface } from "../../Interfaces/RaffledProductInterface";
import { GetReffledProducts } from "../../Service/ReffledProduct.service";
import { CardGradient } from "../CardGradient/CardGradient";
import format from "date-fns/format";
import { TranslateDayName } from "../../Utils/TranslateDate";
import { differenceInDays,subMonths } from "date-fns"; 
import { GetThursdaysOfMonth } from "../../Utils/GetThursdaysOfMonth";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";


interface GiveawaysProps {
};


export const Giveaways: React.FC<GiveawaysProps> = ({
}) => {

  const [loading, setLoading] = useState<boolean>(false);

  // state to save array day (thursday) of month
  const [daysOfMonth, setDaysOfMonth] = useState<Date[]>([]);
  // state to save current day
  const [currentDay, setCurrentDay] = useState<Date>(new Date());
  // state to save active date
  const [activeDate, setActiveDate] = useState<Date>(new Date());

  // state to save all raffled products from firestore
  const [reffledProducts, setReffledProducts] = useState<RaffledProductInterface[]>([]);
  // state to save all raffled products by month by active date
  const [reffledProductsByMonth, setReffledProductsByMonth] = useState<RaffledProductInterface[]>([]);

  // method to get all raffled products from firestore
  const getAllReffledProducts = async () => {
    if(!loading) {
        try {
            setLoading(true);
            const response:any = await GetReffledProducts();
            setReffledProducts(response);
            getDateRangeFromReffled(response, currentDay);
        } catch (error) {
            console.log("🚀 ~ getAllReffledProducts ~ error:", error);
            setLoading(false);
        }
    }
  }

  // method to get the thursday days of the month
  const getThursdaysOfMonth = (numMonth: number, year: number) => {
    const tmpDateMonth = GetThursdaysOfMonth(numMonth, year); 
    setDaysOfMonth(tmpDateMonth);
    getActiveDate(tmpDateMonth);
  }

  // method to get the date range from reffled products
  const getDateRangeFromReffled = async (products: RaffledProductInterface[], currentDate: Date) => { 
    setLoading(true);

    // get thursday days of the month
    getThursdaysOfMonth(currentDate.getMonth(), currentDate.getFullYear()); 

    setReffledProductsByMonth(getProductOfMonth(products, currentDate.getMonth(), currentDate.getFullYear()));
    
    setLoading(false);
  }

  // method to get the products of the month
  const getProductOfMonth = (products: RaffledProductInterface[], month: number, year: number) => {
    return products.filter(c => c.drawDate.toDate().getMonth() === month && c.drawDate.toDate().getFullYear() === year);
  }

  // method to get the date of min distance from the current date
  const getActiveDate = (date: Date[]) => {
    let tmp  = 999999;
    date.forEach(c => {
      const difference = differenceInDays(c, currentDay)
      if( difference >= 0 && difference <= tmp) {
        tmp = differenceInDays(c, currentDay);
        setActiveDate(c);
      } else if (difference < 0) {
        handleClickRigth();
      }
    });
  }

  // method to move the giveCarousel to the left
  const hadleClickLeft = () => {
    const result = subMonths(currentDay, 1);
    if(getProductOfMonth(reffledProducts, result.getMonth(), result.getFullYear()).length>0) {
      setCurrentDay(result);
      getDateRangeFromReffled(reffledProducts, result);
    }       
  }

  // method to move the giveCarousel to the rigth
  const handleClickRigth = () => {
    const result = subMonths(currentDay, -1);
    if(getProductOfMonth(reffledProducts, result.getMonth(), result.getFullYear()).length>0) {
      setCurrentDay(result);
      getDateRangeFromReffled(reffledProducts, result);
    }       
  }

  const handleDateClick = (date: Date) => {
    setActiveDate(date);
  }

  useEffect(() => {
    getAllReffledProducts();
  }, []);


  return (
    <CardGradient className="giveaways-gradient">
      <div className="giveaways">
        <div className="paragraph-header giveaways-title giveaways-date-content-text">Llamadas</div>

        <div className="giveaways-header">
          <CarouselMonth
            currentDate={currentDay}
            hadleClickLeft={hadleClickLeft}
            handleClickRigth={handleClickRigth}
          />

          <div className="giveaways-date">
            { daysOfMonth.map((c, idx) => {
              return(
                <div 
                  style={{backgroundImage:`url(${STORAGE_ASSETS}/images/png/selectBackground.png`}}
                  onClick={()=>handleDateClick(c)} key={idx} 
                  className={"giveaways-date-gradient"+(c.getDate() === activeDate.getDate() ? " giveaways-date-gradient-active" : "")} >
                  <div className="giveaways-date-content">
                    <div className="smalltext giveaways-date-content-text">{TranslateDayName(format(c, "EEE").toLowerCase())}.</div>
                    <div className="smalltext giveaways-date-content-text text_">{format(c, "dd")}</div>
                  </div>
                </div>
              )
            })}
          </div>   
        </div>

        {/* <div className="giveaways-products">
          {reffledProductsByMonth.length>0 && reffledProductsByMonth.map((item, idx) => {
              return (
                item.drawDate.toDate().getDate() === activeDate.getDate() ? 
                <Product 
                  key={idx}
                  position={"row"}
                  product={item}
                  footer={ window.innerWidth >= 1200}
                  footer2={ window.innerWidth < 1200}
                /> : null
              )
          })}
        </div> */}
      </div>
    </CardGradient>
  );
};