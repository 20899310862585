import React, { useEffect, useState, useRef } from "react";

import './Login.css';
import { LoginSocial } from "../LoginSocial/LoginSocial";
import { LoginMail } from "../LoginGmail/LoginMail";
import { GoBack } from "../GoBack/GoBack";
import { Close } from "../../Icons/outline/Close";
import { AuthSteps_item } from "../../Const/AuthConst";
import { Loader } from "../Loader/Loader";
import { RecoverAccount } from "../RecoverAccount/RecoverAccount";
import { CardBackground } from "../CardBackground/CardBackground";
import { useNavigate } from "react-router-dom";
import { AUTH_MODAL, AUTH_REGISTER, HOME, STORAGE_ASSETS } from "../../Const/BaseUrlConst";
import AuthService from "../../Service/Auth.service";
import { GetUserByEmail } from "../../Service/User.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/store";
import { GetUserByUID } from "../../Slice/User";
import { setAuth } from "../../Slice/Auth";

interface LoginProps {
    closeModal: () => void;
    logout: () => void;
} 

export const Login: React.FC<LoginProps> = ({
    closeModal=()=>{},
    logout
}) => {

    const navigation = useNavigate();

    const dispatch = useDispatch<AppDispatch>();


    // useState Loading
    const [ loading, setLoading ] = useState(false);
    const [ title, setTitle ] = useState('Iniciar Sesión');

    const ref = useRef(null);
    // state to manage authStep  
    const [authStep, setAuthStep] = useState<AuthSteps_item>('MAIN_LOGIN_STEP');

    // const handleCloseAuth = async () => {
    //     setShow(!show)
    // }

    // handle go back click
    const handleGoBack = () => {
        if(authStep === "MAIN_LOGIN_EMAIL") {
            setAuthStep("MAIN_LOGIN_STEP");
        } else if(authStep === "MAIN_LOGIN_STEP") {
            closeModal();
        }
       
    }

    const handleEmailClick = () => {
        setAuthStep("MAIN_LOGIN_EMAIL");
        setTitle('Iniciar Sesión')
    }

    const handleLoginWithGoogle = async () => {
        if(!loading) {
            setLoading(true);
            const response:any = await AuthService.RegisterWithGoogle();
            if(response) {
                const isCreated = await GetUserByEmail(response.email);
                if(isCreated) {
                    toast.success('Inicio de sesión exitoso');
                    setTimeout(() => {
                        dispatch(GetUserByUID(response.uid?response.uid:''))
                        .unwrap()
                            .then((responseUser) => {
                                if(response.status === true) {
                                    dispatch(setAuth({
                                        isLoggedIn: true,
                                        displayName: responseUser.name+" "+responseUser.fathersLastName,
                                        userId: responseUser.id,
                                        uid: responseUser.uid,
                                        imgProfile: responseUser.imgProfile || "",
                                    }));
                                    handleLoginClick();
                                } else {
                                    toast.error("usuario desactivado, por favor contacta a soporte");
                                    setTimeout(() => {
                                        logout();
                                   }, 3000);
                                }
                            })
                            .catch((error) => {
                                console.log("🚀 ~ file: MainLogin.tsx:122 ~ setTimeout ~ error:", error);
                                toast.error('Hubo un error al iniciar sesión con su cuenta de google, por favor intenta de nuevo');
                            })
                    }, 500);
                } else {
                    toast.warning('¡No existe ningún usuario creado con este cuenta!, por favor suscribete para poder acceder a la plataforma');
                    closeModal();
                }
            }
        }
    }

    const handleRecover = () => {
        setAuthStep("RESTORE_STEP");
        setTitle('Recuperar Cuenta');
    }
    

    const handleLoginClick = () => {
        closeModal();
        navigation(`/${HOME}`);
    }

    const handleLoginClickRegister = () => {
        navigation(`?${AUTH_MODAL}=${AUTH_REGISTER}`);
    }

    return (

        <div className="login" ref={ref} 
            // style={{backgroundImage:`url(${STORAGE_ASSETS}/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TA.png`}}
        >
            <CardBackground>
                <div className="login-container">
                    <div className="login-container--header">
                        <GoBack handleClick={()=>handleGoBack()}/>
                        <div className="medium-header login-text">{title}</div>
                        <div className="login-container--close" onClick={()=>closeModal()}>
                            <Close />
                        </div>
                    </div>
                    {!loading ? 
                    <div className="login-body">
                        {
                            authStep === "MAIN_LOGIN_STEP" ? 
                            <LoginSocial
                                handleClick={handleEmailClick}
                                handleLoginWithGoogle={handleLoginWithGoogle}
                                handleClickRegister={handleLoginClickRegister}
                                
                            /> : 
                            authStep === "MAIN_LOGIN_EMAIL" ? 
                            <LoginMail
                                handleClick={handleLoginClick}
                                handleRecover={()=>handleRecover()}
                                closeModal={closeModal} 
                                logout={logout}
                            />: 
                            authStep === "RESTORE_STEP" && 
                            <RecoverAccount handleClick={()=>{}}/>
                        }
                    </div> :<div className="login-loader"><Loader /></div>}
                </div>
            </CardBackground>
        </div>
    
    )
}