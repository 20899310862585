import React from "react";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

type HeartWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const HeartWhite: React.FC<HeartWhiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/png/heart_white.png"} alt="" />
    </div>
  );
};