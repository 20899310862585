type ValidateField = "email" | "password" | "required" | "DNI" | "number";

export const Validatefield = (field: ValidateField, value: any) => {
    switch (field) {
        case "email":
            return (value.length > 0 && value.includes("@") && value.includes(".")) ? false : true;
        case "password":
            return (value.length > 0) ? false : true;
        case "required":
            return (value.length > 0) && value !== undefined ? false : true;
        case "DNI":
            let dni = Number(value);
            return (dni > 9999999 && dni <= 99999999) ? false : true;
        case 'number':
            return value !== undefined && !isNaN(value) && Number(value) && value>0? false : true;
        default:
            return true;
    }
}