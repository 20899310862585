export const GenderConst: {
    id: number;
    label: string;
}[] = [{
    id: 0,
    label: "Masculino"
}, {
    id: 1,
    label: "Femenino"
}, {
    id: 2,
    label: "Pa k' quieres saber"
}]