import axios from "axios";
import { FUNCTIONS_CREATE_SUBSCRIBER } from "../Const/BaseUrlConst";

export const CreateSubscriberManychat = async (phone: string, zodiacSign: string) => {
    try {
        const options = {
            method: 'POST',
            url: FUNCTIONS_CREATE_SUBSCRIBER,
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': 'Basic RnJvbnRFbmQ6aE85WHlENDZ3UW4yM1VUVllnS2I='
            },
            data: {
                phone: phone,
                zodiacSign: zodiacSign
            },
        };
        const response = await axios(options);
        console.log("🚀 ~ CreateSubscriberManychat ~ response:", response);
        return response.data;
    } catch (error) {
        console.log("🚀 ~ CreateSubscriberManychat ~ error:", error);
        throw error;
    }
}