import React, { useState } from "react";

import './DetailInformation.css';
import { LabelText } from "../LabelText/LabelText";
import { TextField } from "../TextField/TextField";
import { PhoneCountry } from "../PhoneCountry/PhoneCountry";
import { CountryList } from "../CountryList/CountryList";
import { Button } from "../Button/Button";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Edit } from "../../Icons/solid/Edit";
import { UserInterface } from "../../Interfaces/UserInterface";
import { FormatDate } from "../../Utils/formatDate";
import { Dropdown } from "../Dropdown/Dropdown";
import { GenderConst } from "../../Const/GenderConst";
import { UpdateUser } from "../../Service/User.service";
import { toast } from "react-toastify";
import { CardGradient } from "../CardGradient/CardGradient";
import { FormatISODate } from "../../Utils/FormatISODate";

interface DetailInformationProps {
    user: UserInterface;
    updatedUser: () => void;
}

export const DetailInformation: React.FC<DetailInformationProps> = ({
    user,
    updatedUser
}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [editField, setEditField] = useState<boolean>(false);

    const [userEdit, setUserEdit] = useState<UserInterface>(user);

    // handle change phone, phoneCode, phoneFlag
    const handlePhoneChange = (value: string, field: "phone" | "phoneCode" | "phoneFlag") => {
        setUserEdit((prev) => ({...prev, [field]: value}));
    }

    // handle change country
    const handleChangeCountry = (name: string,imgFlag: string, alpha2Code: string) => {
        setUserEdit((prev) => ({...prev, country: {name, imgFlag, alpha2Code}}));
    }

    const handleCancelClick = () => {
        setUserEdit(user);
        setEditField(false);
    }
    
    const handleUpdateClick = async () => {
        if(loading) return;

        setLoading(true);
        try {
            const response = await UpdateUser(user.id || "", {...userEdit, updatedAt: new Date()});
            updatedUser();
            setEditField(false);
            setLoading(false);
            toast.success("Información actualizada");
        } catch (error) {
            console.log("🚀 ~ handleUpdateClick ~ error:", error)
            toast.error("Error al actualizar la información");
        }
    }

    const handleFieldChange = (value: string, field: "gender" | "city" | "direction" | "phone") => {
        setUserEdit((prev) => ({...prev, [field]: value}));
    }


    return (
        <CardGradient>
            <div className="detailInformation-content">
                <div className="paragraph-header detailInformation-textColor">Tu información de contacto</div>
                {/* <hr className="detailInformation-line" /> */}
                <div className="detailInformation-row detailInformation-row-four detailInformation">
                    <div className="detailInformation-row">
                        <LabelText text="Tipo Doc" value={user.typeIt} />
                        <LabelText text="Nro Documento" value={user.itNumber.toString()} />
                    </div>
                    <div className="detailInformation-row">
                        <LabelText text="Correo" value={user.email}  />
                        <LabelText text="Código único" value={user.itNumber.toString()} />
                    </div>
                </div>
                {/* <hr className="detailInformation-line" /> */}
                <div className="detailInformation-row detailInformation-row-four">
                    <div className="detailInformation-row">
                        <LabelText text="Nombres" value={user.name} />
                        <LabelText text="Apellido Paterno" value={user.fathersLastName} />
                    </div>
                    <div className="detailInformation-row">
                        <LabelText text="Apellido Materno" value={user.mothersLastName} />
                        <LabelText text="Fecha de nacimiento" value={user.birthday} />
                    </div>
                </div>
                
                <div className="detailInformation-row detailInformation-row-four">
                    <div className="detailInformation-row">
                        <PhoneCountry 
                            phone={userEdit.phone.toString()}
                            phoneCode={userEdit.phoneCode}
                            phoneFlag={userEdit.phoneFlag}
                            handleCountryChange={(value)=> handlePhoneChange(value, "phoneFlag")}
                            handlePhoneCodeChange={(value)=>handlePhoneChange(value, "phoneCode")}
                            handlePhoneChange={(value)=>handleFieldChange(value.target.value, "phone")}
                            disable={!editField}                       
                        />
                        <Dropdown
                            name="Género"
                            items={GenderConst}
                            handleItemClick={(label) => handleFieldChange(label, "gender")}
                            disable={!editField}
                            value={userEdit.gender}
                        />
                        {/* <TextField 
                            label="Género" 
                            placeholder="" 
                            value={userEdit.gender}
                            onChange={(e)=> handleFieldChange(e.target.value, "gender")}
                            disabled={!editField} /> */}
                    </div>
                    
                    <div className="detailInformation-row">
                        <CountryList
                            name="País"
                            value={userEdit.country.name}
                            valueFlag={userEdit.country.imgFlag}
                            onClick={handleChangeCountry}
                            disabled={!editField}
                        />
                       <TextField 
                        label="Cuidad" 
                        placeholder="" 
                        value={userEdit.city} 
                        onChange={(e)=> handleFieldChange(e.target.value, "city")}
                        disabled={!editField} />
                    </div>
                </div>
                
                <TextField 
                label="Dirección exacta " 
                placeholder="" 
                value={userEdit.direction} 
                onChange={(e)=> handleFieldChange(e.target.value, "direction")} 
                disabled={!editField} />
                {/* <TextField label="Referencia de la dirección " placeholder="" value={user.addressReference} onChange={()=> {}} /> */}
             
            </div>

            <div className="detailInformation-button">
               {!editField ? <ButtonIcon
                    content="Actualizar información"
                    color="orange"
                    size="extra-small"
                    Icon={<Edit />}
                    onClick={() => setEditField(true)}
                /> : <div className="detailInformation-editButtons">
                    <Button
                      text="Cancelar"
                      color="red"
                      onClick={() => handleCancelClick()}
                    />
                    <Button
                      text="Actualizar"
                      color="orange"
                      onClick={() => handleUpdateClick()}
                    />
                </div>}
            </div>
        </CardGradient>
    )
}