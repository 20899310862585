import React, { useEffect } from "react";

import "./ContactProfile.css";
import { ContactInformation } from "../../../Components/ContactInformation/ContactInformation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Store/store";
import { GetUser } from "../../../Slice/User";
import { toast } from "react-toastify";

interface ContactProfileProps {

}

export const ContactProfile: React.FC<ContactProfileProps> = () => {

    const dispatch = useDispatch<AppDispatch>();

    const {userDetail} = useSelector((state: any) => state.user);


    const getUserById = () => {
        if(userDetail && userDetail.id) {
            dispatch(GetUser(userDetail.id))
            .unwrap()
            .then((response) => {
            })
            .catch((error) => {
                console.log("🚀 ~ file: Orders.tsx ~ line 264 ~ getUser ~ error", error)
                toast.error("Error al obtener el usuario")
            });
        }      
    }

    return (
        <div className="contactProfile-container">
            {userDetail && <ContactInformation user={userDetail} updatedUser={getUserById} />}
        </div>
    )
}