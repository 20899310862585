import React, {useEffect, useRef, useState} from "react";

import { Outlet } from "react-router-dom";

/** importando componentes*/

import './Admin.css';


// Importando utilitarios
import { ToastContainer } from "react-toastify";
import { Navbar } from "../../Components/Navbar/Navbar";
import { NavbarItem } from "../../Const/navbarConst";
import { Auth } from "../../Components/Auth/Auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AppDispatch } from "../../Store/store";
import { useDispatch } from "react-redux";
import { GetUserByUID } from "../../Slice/User";
import { setAuth } from "../../Slice/Auth";


export const AdminPage: React.FC<{}> = () => {

    const dispatch = useDispatch<AppDispatch>();

    const inputRef = useRef<HTMLDivElement>(null);

    const [isLogin, setIsLogin] = useState<boolean>(false);

    

    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });

    const getCurretnAuthUser = async () => {
        return new Promise((resolve, reject) => {
            try {
                const auth = getAuth();
                onAuthStateChanged(auth, (userCurrent) => {
                    resolve(userCurrent);     
                });
            } catch (error) {
                console.log("🚀 ~ file: AdminPage.tsx:466 ~ returnnewPromise ~ error:", error);
                reject(error);
            }
        });
    }

    // redux get user by uid
    const getUserById = (uid: string) => {
        dispatch(GetUserByUID(uid))
        .unwrap()
        .then(async(response) => {
            dispatch(setAuth({
                isLoggedIn: true,
                displayName: response.userName,
                userId: response.id,
                uid: response.uid,
                imgProfile: response.photoUrl
            }));    
        })
        .catch((error)=> {
            console.log("🚀 ~ file: AdminPage.tsx:232 ~ getUserById ~ error:", error);
        })
    }

    useEffect(() => {
        const auth = async() => {
            const userCurrent: any = await getCurretnAuthUser();
            if(userCurrent) {
                // register notification
                setUser(userCurrent);   
                getUserById(userCurrent.uid); 
            }
        } 
        auth();
    }, []);

    return (
        <div className={`admin-container ${isLogin ? " admin-bg" : ""}`}>
            <ToastContainer  hideProgressBar limit={5} position='top-right'  autoClose={3500} pauseOnFocusLoss={false} pauseOnHover={false} />
            
            <div 
                className="content-outlet"  
                ref={inputRef} 
            >
                <Navbar item={NavbarItem}/>

                <Outlet/>
                {/* <Footer 
                    isLogin={isLogin}
                    handleTerms={handleTerms}
                    handlePrivacy={handlePrivacy}
                    handleQuestions={handleQuestions}
                    handleReglament={handleReglament}
                    handleSoporte={handleSoporte}
                />  */}
            </div>
           
            <Auth />
            
        </div>
    )
}