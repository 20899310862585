import { NavItemInterface } from "../Interfaces/NavItemInterface"
import { HOME, HOROSCOPE, LANDING, MY_AWARDS, MY_PROFILE, MY_RECEIPTS, MY_SUBSCRIPTION, REFFLED } from "./BaseUrlConst"

export const NavbarItem : NavItemInterface[] = [
    {
        text: 'Inicio',
        path: `/`,
        activePath: [HOME, LANDING, MY_PROFILE],
        id: "cover",   
    },
    {
        text: 'HoróZcopo',
        path: `/`,
        activePath: [LANDING],
        id: "horoscopeSection"
    },
    // {
    //     text: 'Sorteos',
    //     path: `/`,
    //     activePath: [LANDING],
    //     id: "reffledProducts"
    // },
    {
        text: 'Mi perfil',
        path: `/${MY_PROFILE}`,
        activePath: [HOME, MY_PROFILE],

    },
    {
        text: 'Mi suscripción',
        path: `/${MY_PROFILE}/${MY_SUBSCRIPTION}`,
        activePath: [HOME, MY_PROFILE],
    },
    {
        text: 'Mis premios',
        path: `/${MY_PROFILE}/${MY_AWARDS}`,
        activePath: [HOME,  MY_PROFILE],
    },
    {
        text: 'Mis recibos',
        path: `/${MY_PROFILE}/${MY_RECEIPTS}`,
        activePath: [HOME, MY_PROFILE],
    },
    {
        text: 'Cerrar sesión',
        path: LANDING,
        activePath: [HOME, MY_PROFILE],
    },
]