import React, { useState } from "react";

import './LoginMail.css';
import { Button } from "../Button/Button";
import { CardBackground } from "../CardBackground/CardBackground";
import { TextField } from "../TextField/TextField";
import { toast } from "react-toastify";
import AuthService from "../../Service/Auth.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/store";
import { GetUserByUID } from "../../Slice/User";
import { setAuth } from "../../Slice/Auth";
import { useNavigate } from "react-router-dom";
import { AUTH_MODAL, AUTH_REGISTER } from "../../Const/BaseUrlConst";

interface LoginMailProps {
    handleClick: () => void;
    handleRecover: () => void;
    closeModal(): void;
    logout: () => void;
}

export const LoginMail: React.FC<LoginMailProps> = ({
    handleClick,
    handleRecover,
    closeModal,
    logout
}) => {

    const navigation = useNavigate();

    const dispatch = useDispatch<AppDispatch>();

    const [loading, setLoading] = useState<boolean>(false);

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }


    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const redirectToRegister = (registerStep: string, planId: string) => {
        //closeModal();
        toast.warning("El usuario no ha completado el registro, te estamos redirigiendo para que lo termines");
        navigation(`/?${AUTH_MODAL}=${AUTH_REGISTER}&planId=${planId}&registerStep=${registerStep}`);

    }

    const loginUser = async () => {
        if(loading) return;

        setLoading(true);
        try {
            const response:any = await AuthService.LoginWithEmail(email, password, true);
            if(response && response.uid) {
                dispatch(GetUserByUID(response.uid))
                    .unwrap()
                    .then((resp: any) => {
                        if(resp.status === true) {
                            let finishReg: boolean = resp.registerPhase === 4;                     
                            dispatch(setAuth({
                                isLoggedIn: finishReg,
                                displayName: finishReg ? (resp.name+" "+resp.lastName) : "",
                                userId: resp.id,
                                uid: response.uid,
                                imgProfile: finishReg ? resp.photoUrl : "",
                            }));
                            setTimeout(() => {
                                finishReg ? handleClick() : redirectToRegister(resp.registerPhase, resp.planId);
                            }, 500);
                        } else {
                            toast.error("usuario desactivado, por favor contacta a soporte");
                            setLoading(false);
                            setTimeout(() => {
                                logout();
                           }, 3000);
                        }                        
                    }).catch((error) => {
                        console.log("🚀 ~ .then ~ error:", error)
                        setLoading(false);
                    });
            } else {
                setLoading(false);
                toast.error("Email o contraseña incorrectos");
            }
        } catch (error) {
            console.log("🚀 ~ loginUser ~ error:", error);
            setLoading(false);
            toast.error("Error al iniciar sesión");
        }
    }

    return (
        <div className="loginGmail">
            <TextField
                label="Correo"
                placeholder="Ingresa tu correo electrónico"
                value={email}
                onChange={(e)=>handleEmail(e)}
            />

            <TextField
                label="Contraseña"
                placeholder="Ingresa la contraseña"
                value={password}
                type="password"
                onChange={(e)=> handlePassword(e)}
            />

            <Button
                text={loading ? "Cargando..." : "Iniciar Sesión"}
                color="orange"
                onClick={()=>!loading ? loginUser() : {}}
            />
            <div className="loginGmail-footer">
                <div className="tinytext loginGmail-footer-text">¿Olvidaste tu contraseña? </div>
                <div className="tinytext-header-1 loginGmail-footer-text-orange" onClick={()=>handleRecover()}>Recupérala aqui</div>
            </div>
        </div>
    )
}