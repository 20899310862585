import React from "react";
import "./ButtonGradient.css";

type ButtonGradientProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    content: string;
    resalt?: string;
    handleClick: () => void;
    className?: string;
};

export const ButtonGradient: React.FC<ButtonGradientProps> = ({
    content,
    resalt,
    handleClick,
    className = ""
}) => {
  return (
    <button className={ `${className} button button--gradient-text`} onClick={()=>handleClick()}>
        <span className="smalltext">{content}</span>
        {resalt && <span className="button-text-resalt">{resalt}</span>}
    </button>
  );
};