import React, { useEffect, useRef } from "react";

import './ModalPrivacy.css';
import { Modal } from "../Modal/Modal";
import { Close } from "../../Icons/outline/Close";

interface ModalPrivacyProps {
    showPrivacy: boolean;
    setShowPrivacy: (e:boolean) => void;
}

export const ModalPrivacy: React.FC<ModalPrivacyProps> = ({
    showPrivacy,
    setShowPrivacy
}) => {

    return (
        <Modal 
            title = "Política de privacidad"
                icon = {<Close/>}
                show = {showPrivacy}
                setShow = {setShowPrivacy}
                children = {
                    <div className="modalPrivacy-container">
<div className="modalPrivacy-title ">1. Introducción</div>
<div className="modalPrivacy-paragraph">
1.1. Mediante el presente documento, AZTRO X S.A.C., identificada con Registro Único de Contribuyente Nro. 20612211338, domiciliada en Jr. Rio Urubamba nro. 559 Urb. Villa del norte , informa y solicita el consentimiento a los usuarios y suscriptores del sitio web “www.santazinos.com” sobre su  política de privacidad y tratamiento de datos personales, de conformidad con lo establecido en la Ley de Protección de Datos Personales – Ley N° 29733 y su reglamento, aprobado por Decreto Supremo N° 003-2013-JUS, comprometiéndose a proteger y respetar la privacidad de sus datos de carácter personal.
<br/>
1.2. La lectura y revisión detenida de este documento es necesaria a fin de conocer aspectos relevantes relacionados con los datos personales que proporcionen los usuarios al sitio web, ya que describe cómo se recopilan, utilizan, conservan y protegen los datos personales de los usuarios.
<br/>
1.3. Nuestra empresa hace los mejores cuidados para almacenar con seguridad y procesar con cuidado la información que proporcionan los usuarios al sitio web.
</div>

<div className="modalPrivacy-title ">2. Definiciones</div>
<div className="modalPrivacy-paragraph">
2.1. Para efectos de la Política de Privacidad, se enumeran los conceptos más importantes que deben ser tomados en consideración:
<br/>
AZTRO X: es AZTRO X S.A.C., sociedad constituida bajo las leyes de la República del Perú, identificada con Registro Único de Contribuyente Nro. 20612211338.
Datos Personales: aquella información sobre una persona natural que la identifica o la hace identificable (nombres y apellidos completos, DNI o documento de identidad, dirección de residencia, dirección de correo electrónico y número telefónico).
Ley: es la Ley Nro. 29733 que aprueba la Ley de Protección de Datos Personales. 
Titular de datos personales: persona natural a quien corresponde los datos personales en cuestión.
Política de Privacidad: es la presente política de privacidad.
Reglamento: es el reglamento de la Ley, aprobado mediante Decreto Supremo N° 003-2013-JUS.
Sitio Web: es la página web www.santazinos.com 
Suscriptor: persona natural, de 18 años o mayor de 18 años residentes en el Perú o personas naturales de otros países, consideradas con mayoría de edad en su respectiva jurisdicción, que se registre en el Sitio Web bajo los planes y/o programas ofrecidos en el mismo y cuente con una suscripción mensual activa en el Sitio Web.
Usuario: persona natural, mayor de 18 años residentes en el Perú o consideradas con mayoría de edad en su respectiva jurisdicción, que acceda, utilice, compre o realice transacciones en la tienda virtual del Sitio Web.
Reglamento: es el reglamento de las campañas de premios de agradecimiento de AZTRO X, disponibles en el siguiente enlace: [INSERTAR ENLACE DEL REGLAMENTO]
Premio de AZTRO X: Consiste en un procedimiento en el que aleatoriamente se escoge a un Suscriptor para hacerle entrega de un premio especificado en el Sitio Web. La elegibilidad de los Suscriptores y la mecánica de realización del premio se describen en el Reglamento.
</div>

<div className="modalPrivacy-title ">3. Manifestación de Voluntad</div>
<div className="modalPrivacy-paragraph">
3.1. La Política de Privacidad es aceptada por los Usuarios y Suscriptores al momento de realizar transacciones u operaciones en el Sitio Web  al hacer clic en “ACEPTO LA POLÍTICA DE PRIVACIDAD”, manifiestan su voluntad a través del presente medio electrónico, de conformidad con el artículo 141 del Código Civil Peruano y, de esta forma, otorgan su consentimiento libre, previo, expreso, informado e inequívoco para el tratamiento de todos los Datos Personales que libre y voluntariamente proveerá a AZTRO X en el marco de su relación comercial, así como los que le sean requeridos para el acceso e interacción con los servicios ofrecidos y las comunicaciones derivadas de los mismos.
<br/>
3.2. Aquella persona natural que no se encuentre de acuerdo con la Política de Privacidad de forma parcial o total, no podrá realizar transacciones y/u operaciones Sitio Web.
<br/>
3.3. Se informa al Usuario que, cualquier tratamiento de sus datos personales, queda regulado bajo lo establecido en la Ley y el Reglamento, ya sean aquellos datos personales otorgados de forma digital o física por el usuario, incluyendo los datos personales proporcionados a futuro por el usuario, o los que sean generados por AZTRO X bajo la entrega o prestación de los servicios ofrecidos al Usuario en el Sitio Web.
<br/>
3.4. El usuario se compromete a mantener permanentemente actualizada su información mientras dure su relación con el Sitio Web. En algunos casos, los datos personales recolectados por AZTRO X son obligatorios; en consecuencia, la negativa a suministrarlos podría suponer la imposibilidad de concretar los fines previstos en la presente Política.
</div>

<div className="modalPrivacy-title ">4. Finalidades de la recopilación y tratamiento de los Datos Personales </div>
<div className="modalPrivacy-paragraph">
4.1. Finalidades Obligatorias
<br/>
De manera obligatoria, los datos personales de los Usuarios podrán ser utilizados por AZTRO X para operar, proporcionar, desarrollar y mejorar el Sitio Web, incluyendo las siguientes finalidades:
<br/>
Proporcionar y administrar el Sitio Web permitiéndole hacer uso de sus funcionalidades, incluyendo pero no limitándose a la creación y uso de la cuenta de Usuario y/o Suscriptor, realizar compras, participar en las actividades promocionadas en el Sitio Web como los Premios AZTRO X.
Coordinar y entregar los bienes a los que fuera acreedor del Usuario y/o Suscriptor como consecuencia de su participación en los Premios AZTRO X.
Proporcionar funciones de compra en el Sitio Web, así como compartir su información con proveedores de pago y tramitación de transacciones y otros proveedores de servicios para procesar sus solicitudes.
Procesar cualquier solicitud o reclamo presentado a través de los instrumentos de soporte incluidos en el Sitio Web.
Gestión, administración, prestación, ampliación y mejora del Sitio Web (por ejemplo, a través del diseño de nuevas funcionalidades o add-ons).
Mantener y mejorar la seguridad, la protección y la estabilidad del Sitio Web identificando y solucionando cuestiones o problemas técnicos o de seguridad (errores técnicos, detección de abusos, fraudes y actividades ilegales).
Envío de actualizaciones de nuestros Términos y Condiciones, Reglamento y Política de Privacidad.
Facilitar la realización de encuestas o sondeos que cumplan determinados criterios realizados por auditores independientes.
Desarrollo comercial de  AZTRO X.
Estudio de la utilización del Sitio Web por parte de los Usuarios y/o Suscriptores.
<br/>
4.2. Finalidades Opcionales
De manera opcional, por aceptación previa y voluntaria de los Usuarios, los datos personales de los Usuarios podrán ser utilizados por AZTRO X para las siguientes finalidades:
<br/>
Promociones y ofertas en productos y servicios.
Descuentos en productos y servicios.
Envío de correos electrónicos de promociones y ofertas en productos y servicios.
Envío de correos electrónicos de descuentos en productos y servicios.
Envío de correos electrónicos de anuncios informativos (“Newsletter”).
<br/>
Para las finalidades opcionales, AZTRO X podría compartir su información con las empresas con las cuales se entablen vínculos comerciales y asociaciones estratégicas, todo en beneficio del Usuario.
</div>

<div className="modalPrivacy-title ">5. Datos Personales recopilados</div>
<div className="modalPrivacy-paragraph">
AZTRO X recopila dos tipos de datos personales: (i) los que el Usuario y/o Suscriptor nos envía directamente cuando utiliza el Sitio Web y (ii) los que se recopilan automáticamente a través de herramientas informáticas sobre cómo el Usuario y/o Suscriptor accede y utiliza el Sitio Web.
<br/>
5.1. Información proporcionada por el Usuario y/o Suscriptor
<br/>
AZTRO X recopila los Datos Personales que el Usuario y/o Suscriptor nos envía directamente cuando (i) realiza una transacción en el Sitio Web, (ii) se registra como Suscriptor, (iii) envía un correo electrónico a nuestra área de atención al cliente, (iv) se registra a nuestro newsletter para recibir noticias de nuevas actividades y/o promociones de Premios AZTRO X. Los Datos Personales que se le solicitarán son:
<br/>
En el proceso de transacción en el Sitio Web se le solicitarán los siguientes Datos Personales: nombre completo, documento de identidad, dirección de correo electrónico, dirección de facturación, número de teléfono, detalles de la tarjeta de pago (crédito o débito).
En el proceso de registro como Suscriptor se le solicitarán los siguientes datos personales: nombre completo, fecha de nacimiento, dirección de correo electrónico, número de teléfono, dirección del domicilio de residencia, documento de identidad, edad, país de residencia, género, apodo.
Comunicación con nuestra área de atención al cliente: correo electrónico, número de teléfono, y aquella requerida en el formulario que aparecerá en esta sección del Sitio web.
En el proceso de atención al cliente o registro a nuestro newsletter se le solicitarán los siguientes datos personales: nombre completo y correo electrónico.
<br/>
Si elige no proporcionar dichos Datos Personales, AZTRO X no podrá completar las transacciones que usted realice, darle acceso al Sitio Web, ni responder a sus solicitudes.
<br/>
5.1. Información recopilada automáticamente
<br/>
AZTRO X también recopila automáticamente información sobre cómo usted accede y utiliza el Sitio Web, tales como:
<br/>
Información técnica:  Recopilamos cierta información sobre el dispositivo y la conexión a la red cuando usted accede al Sitio Web. Esta información incluye el modelo de su dispositivo, el sistema operativo, la dirección IP y el idioma del sistema. También recopilamos información relacionada con el servicio, el diagnóstico y el rendimiento, incluidos los informes de colisiones y los registros de rendimiento.
Información sobre la ubicación: Recopilamos automáticamente información sobre su ubicación aproximada (por ejemplo, país, estado o ciudad) basándonos en su información técnica (p. ej. La tarjeta SIM o su dirección IP).
Información de uso:  Recopilamos información sobre la forma en la que usted utiliza el Sitio Web, incluida la información sobre las instrucciones y solicitudes que formula, frecuencia de uso y configuración.
Información inferida: Podemos vincular o combinar la información personal proporcionada por usted y la información que recopilamos automáticamente. Esto nos permite brindarle una experiencia personalizada independientemente de cómo interactúa con nosotros, así como también para mantener la seguridad del Sitio Web.
Cookies: Utilizamos cookies y tecnologías de seguimiento similares para operar y proporcionar el Sitio Web. Por ejemplo, utilizamos cookies para recordar sus preferencias de idioma, frecuencia de uso de la Plataforma, y por motivos de seguridad. También utilizamos estas tecnologías con fines de marketing. A continuación detallamos las categorías de Cookies que utilizamos en el Sitio Web. Las cookies que se categorizan como "Necesarias" se almacenan en tu navegador ya que son esenciales para habilitar las funcionalidades básicas del sitio. También utilizamos cookies de terceros que nos ayudan a analizar cómo utilizas este Sitio Web, almacenar tus preferencias y proporcionarte contenido y anuncios relevantes para ti. Estas cookies solo se almacenarán en tu navegador con tu consentimiento previo.
<br/>
1. Cookie: XSRF-TOKEN
Duración: 1 año
Descripción: Esta cookie se utiliza por seguridad.
Categoría: Necesaria
<br/>
2. Cookie: laravel_session
Duración: 1 año
Descripción: Laravel utiliza "laravel_session" para identificar una instancia de sesión de usuario.
Categoría: Necesaria
<br/>
3. Cookie: _ga_*
Duración: 1 año, 1 mes y 4 días
Descripción: Google Analytics establece esta cookie para almacenar y contar las vistas de páginas.
Categoría: Analytics
<br/>
4. Cookie: _ga
Duración: 1 año, 1 mes y 4 días
Descripción: Google Analytics establece esta cookie para calcular los datos de visitantes, sesiones y campañas, y para rastrear el uso del sitio para el informe de análisis del sitio. La cookie almacena información de manera anónima y asigna un número generado aleatoriamente para reconocer a los visitantes únicos.
Categoría: Analytics
<br/>
5. Cookie: _fbp
Duración: 3 meses
Descripción: Facebook establece esta cookie para mostrar anuncios cuando estás en Facebook o en una plataforma digital impulsada por la publicidad de Facebook después de visitar el sitio web.
Categoría: Analytics
<br/>
7. Registro del banco de datos
<br/>
Los Datos Personales recogidos serán objeto de tratamiento automatizado e incorporados al correspondiente Banco de Datos Personales de los que AZTRO X es titular y responsable (en adelante, el “Banco”). El Banco tiene como nombre “USUARIOS" y se encontrará registrado ante la Autoridad Nacional de Datos Personales.
AZTRO X proporciona a los Usuarios los recursos técnicos adecuados para que, con carácter previo, puedan acceder a este aviso sobre la Política de Privacidad de Datos Personales o a cualquier otra información relevante y puedan prestar su consentimiento a fin de que la Empresa proceda al tratamiento automatizado de sus Datos Personales. 
<br/>
8. Plazo de conservación de los datos y medidas de seguridad
AZTRO X garantiza la privacidad, confidencialidad y seguridad de los Datos Personales recopilados, de acuerdo con las exigencias de la Ley y su Reglamento. En tal sentido, AZTRO X ha adoptado los niveles de seguridad de protección de los Datos Personales legalmente requeridos.
Para ello, AZTRO X mantiene medidas de seguridad técnicas, administrativas (organizativas y legales) y físicas apropiadas diseñadas para proteger su información del acceso no autorizado, robo, divulgación, modificación o pérdida. Asimismo, revisamos periódicamente nuestras medidas de seguridad para tener en cuenta las nuevas tecnologías y métodos disponibles. No obstante, el Usuario y/o Suscriptor debe ser consciente de que las medidas de seguridad en Internet no son inexpugnables y deberá resguardar sus credenciales de acceso de manera confidencial.
 Conservamos la información durante el tiempo necesario para ofrecer el Sitio Web y para los demás fines establecidos en la presente Política de privacidad. También conservamos la información cuando es necesario para cumplir con las obligaciones contractuales y legales, cuando existe un interés comercial legítimo para hacerlo (como la mejora y el desarrollo del Sitio Web, y la mejora de su seguridad y estabilidad), y para el ejercicio o la defensa de reclamaciones legales.
Conservaremos los Datos Personales que recopilamos sobre el Usuario y/o Suscriptor hasta un (1) año, después de que nos solicite la cancelación de su cuenta en el Sitio Web.
<br/>
9. Ejercicio de los derechos de información, acceso, rectificación, cancelación y oposición de los datos
<br/>
9.1. Como titular de sus datos personales, el Usuario tiene el derecho de acceder a sus datos en posesión de AZTRO X; actualizarlos, conocer las características de su tratamiento, rectificarlos en caso de ser inexactos o incompletos; solicitar sean suprimidos o cancelados al considerarlos innecesarios para las finalidades previamente expuestas en la Política de Privacidad o bien oponerse a su tratamiento para fines específicos. El usuario podrá en todo momento revocar el consentimiento otorgado expresamente, tanto como limitar el uso o divulgación de sus datos personales, para lo cual podrá escribir su solicitud de revocación a la página de soporte del Sitio Web.
<br/>
9.2. El Usuario podrá dirigir su solicitud de ejercicio de los derechos en los términos que establece la Ley y el Reglamento a través de la página de soporte del Sitio Web, teniendo a salvo además el ejercicio de la tutela de sus derechos ante la Autoridad Nacional de Protección de Datos Personales en vía de reclamación o ante el Poder Judicial mediante la acción de hábeas data.
<br/>
10. Transferencia de Datos Personales
Podemos compartir sus Datos Personales con las siguientes personas o entidades para las siguientes finalidades:
<br/>
10.1. Proveedores de Servicios.
Contratamos a proveedores de servicios que nos ayudan a proporcionar, apoyar y desarrollar la Plataforma y a entender cómo se utiliza. Prestan servicios como:
 Alojamiento en la nube y entrega de contenidos: A través de los servicios “Google Firebase” y “Google Tag Manager”, ambos de titularidad de la empresa Google, Inc., domiciliada en los Estados Unidos de América
Asistencia técnica y al cliente.
Moderación de contenidos.
Marketing: servicio de “Meta Pixel” de titularidad de la empresa Meta Platforms, Inc., domiciliada en los Estados Unidos de América.
Análisis y pagos en línea: a través de la plataforma “CULQUI” brindada por la empresa peruana  Compañía Incubadora de Soluciones Móviles S.A. - CISMO S.A., identificada con Registro Único de Contribuyente Nro. 20555530090. Asimismo, se utiliza la plataforma “NUBEFACT” brindada por la empresa peruana NUBEFACT S.A., identificada con Registro Único de Contribuyente Nro. 20600695771.
<br/>
10.2. Terceros
Es posible que compartamos su información con terceros no relacionados a AZTRO X en un número limitado de circunstancias, tales como:
Auditoras independientes: Compartimos su información con auditores independientes para facilitar la realización de sondeos, estudios o encuestas que cumplan determinados criterios como el conocimiento colectivo de los Usuarios y/o Suscriptores, uso de información y tendencias sociales.
Operaciones corporativas: Su información puede ser revelada a terceros en relación con una operación corporativa, como una fusión, venta de activos o acciones, reorganización, financiación, cambio de control o adquisición de todo o parte de nuestro negocio.
Obligaciones legales: Podemos compartir la información proporcionada por el Usuario a solicitud de entidades públicas que invoquen la ejecución de una competencia legalmente asignada, conforme el artículo 14 de la Ley, como por ejemplo: la Policía Nacional del Perú, Ministerio Público, Autoridades Administrativas y Judiciales, etc.
<br/>
11. Modificaciones y actualizaciones de la Política de Privacidad
La presente Política de Privacidad ha sido actualizada al 9 de abril de 2024 y podrá ser modificada por AZTRO X. Cualquier cambio o modificación será publicada oportunamente en el Sitio Web.
<br/>
AZTRO X podrá modificar de forma parcial o total la Política de Privacidad. De acontecer lo anterior, se le informará al Usuario, a través de la publicación en el Sitio Web, de las modificaciones correspondientes para su aceptación. De no encontrarse conforme con las modificaciones AZTRO X suspenderá el uso de la cuenta.
<br/>
La efectividad de las modificaciones de la Política de Privacidad se dará en el momento en que se publiquen en el Sitio Web, siendo el Usuario el único responsable de su lectura, entendimiento y aceptación.
<br/>
12. Contacto
 Puede ponerse en contacto con nosotros en relación con esta Política de Privacidad a través del siguiente correo electrónico: admin@santazinos.com
</div>

                    </div>
                }
                onClose = {() => setShowPrivacy(false)}
            />
    )
}