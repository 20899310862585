import React from "react";
import { loaderText } from "../../Const/LoaderTextConst";

import './Loader.css'

export const Loader: React.FC<{}> = ({...props}) => {
    return (
    <div {...props} className="loader">
    </div>
    )
} 