import React from "react";

import "./TextField.css";
import { ErrorInterface } from "../../Interfaces/ErrorInterface";

interface TextFieldProps {
    label: string;   
    placeholder: string;
    value: string | number | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    type?: string;
    error?: ErrorInterface;
    role?: string;
    id?: string;
    name?: string;
    maxLength?: number;
    disabled?: boolean;
}

export const TextField: React.FC<TextFieldProps> = ({
    id="",
    name="",
    label,
    placeholder,
    value,
    onChange,
    type = "text", 
    error={
        status: false,
        message: ""
    },
    role = "",
    maxLength = 10000,
    disabled = false,
   ...props
}) => {

    return (
        <div className="textField-container">
            <label className="tinytext-header textField-textColor">{label}</label>
            <input
                id={id}
                name={name}
                className="textField-input"
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                role={role}
                maxLength={maxLength}
                disabled={disabled}
                {...props}
            />
            {error.status && <div className="tinytext-error">{error.message}</div>}
        </div>
    )
}