 import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
 import { DB } from "../FirebaseConfig/FirebaseConfig";
 import { ReceiptInterface } from "../Interfaces/ReceiptInterface";

 export const GetReceiptsId = async (id: string): Promise<ReceiptInterface[]> => {
    const r = query(collection(DB, "Receipt"), where("userId", "==", id));
    const response = await getDocs(r);
    let data: ReceiptInterface[] = [];
    response.forEach((doc) => {
        data.push(doc.data() as ReceiptInterface);
    });
    return data;
}