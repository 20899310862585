import React, { useEffect } from "react";

import "./CarouselMonth.css";
import { ArrowLeft } from "../../Icons/solid/ArrowLeft";
import { ArrowRigth } from "../../Icons/solid/ArrowRigth";
import { GetMonthName } from "../../Utils/GetMonthName";
import { CardGradient } from "../CardGradient/CardGradient";

import { subMonths } from "date-fns";


interface CarouselMonthProps {
  currentDate: Date;
  hadleClickLeft: () => void;
  handleClickRigth: () => void;
};


export const CarouselMonth: React.FC<CarouselMonthProps> = ({
  currentDate,
  hadleClickLeft,
  handleClickRigth
}) => {

  const giveCarousel = React.useRef<HTMLDivElement>(null);


  return (
    <CardGradient className="carousel-month-gradient">
      <div className="carousel-month">
        <ArrowLeft id="carousel-leftIcon" style={{display: "flex"}} onClick={() => hadleClickLeft()} /> 
        <div className="paragraph carousel-list" ref={giveCarousel}>
          {GetMonthName(currentDate.getMonth())} {currentDate.getFullYear()}
        </div>
        <ArrowRigth id="carousel-rightIcon" style={{display: "flex"}} onClick={()=> handleClickRigth()} />
      </div>
    </CardGradient>
  );
};