import React, { useCallback, useEffect, useState } from "react";

import './Horoscope.css';
import { CarouselHoroscopeMin } from "../../../Components/CarouselHoroscopeMin/CarouselHoroscopeMin";
import { Carousel } from "../../../Components/Carousel/Carousel";
import { FireIcon } from "../../../Icons/solid/FireIcon";
import { HeaderSection } from "../../../Components/HeaderSection/HeaderSection";
import { GetAllZodiacalSign } from "../../../Service/ZodiacSign.service";
import { ZodiacSignInterface } from "../../../Interfaces/ZodiacSignInterface";
import { GetHoroscopeReaingByZodiacSignReading } from "../../../Service/HoroscopeReading.service";
import { HoroscopeInterface } from "../../../Interfaces/HoroscopeInterface";
import { FormatDateMonth } from "../../../Utils/FormartDateMonth";
import { setZodiacSign } from "../../../Slice/zodiacSign";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Store/store";

interface HoroscopeProps {
    onClickShowMore?: () => void;
}

export const Horoscope: React.FC<HoroscopeProps> = ({
    onClickShowMore = ()=>{}
}) => {

    const dispatch = useDispatch<AppDispatch>();


    // useEffect to controll loading
    const [loading, setLoading] = useState<boolean>(false);

    // useEffect to controll the active horoscope
    const [activeHoroscope, setActiveHoroscope] = useState<number>(-1);
    
    const [zodiacSigns, setZodiacSigns] = useState<ZodiacSignInterface[]>([]) // ZodiacSignInterface[]
    const [horoscopeReading, setHoroscopeReading] = useState<HoroscopeInterface[]>([]);

    const handleItemZodiacSign = (index: number) => {
        setActiveHoroscope(index);
        dispatch(setZodiacSign({
            zodiacSign: zodiacSigns[index].name
        }))
        getHoroscopeReadingByZodiacSign(zodiacSigns[index].id || "");
    }

    const getZodiacSigns = useCallback(async () => {
        if(loading) return;

        try {
           setLoading(true);
           const response = await GetAllZodiacalSign();
           if(response.length > 0) {
               setZodiacSigns(response);
               setActiveHoroscope(0);
               setLoading(false);
               dispatch(setZodiacSign({
                zodiacSign: response[0].name
            }));
            getHoroscopeReadingByZodiacSign(response[0].id || "");  
           }
           setLoading(false);
       } catch (error) {
           setLoading(false);    
           console.log(error);
       }      
    }, [])

    const getHoroscopeReadingByZodiacSign = useCallback(async (zodiacSignId: string) => {
        if(!loading && zodiacSignId.length > 0) {
            setLoading(true);
            try {
                const response = await GetHoroscopeReaingByZodiacSignReading(zodiacSignId);
                // console.log("🚀 ~ getHoroscopeReadingByZodiacSign ~ response:", response)
                setHoroscopeReading(response);
                setLoading(false);    
            } catch (error) {
                setLoading(false);    
                console.log(error);
            }
        }
    }, [zodiacSigns]);
    
    useEffect(() => {
        getZodiacSigns();
    }, []);


    return (
        <section id="horoscopeSection" className="horoscope-container">
            <HeaderSection 
                title="Ten una probadita de tóh lo que hay aquí mi pana"
                subTitle="HoróZcopo + contenido exclusivo más seguido. TODO-EL-TIEMPO"
            />
            <div className="horoscope-content">
                <CarouselHoroscopeMin 
                    listHoroscope={zodiacSigns.map((c, idx) => 
                        ({iconWhiteUrl: c.iconWhiteUrl, iconBlackUrl: c.iconBlackUrl, 
                            name: c.name}))} 
                    activeHoroscope={activeHoroscope}
                    setActiveHoroscope={(index: any) => handleItemZodiacSign(index)} 
                    loading={loading}
                />
                <Carousel 
                    AstrologicalSignImg={zodiacSigns.length>0 && horoscopeReading.length>0 ? zodiacSigns[activeHoroscope].iconWhiteUrl : ""}
                    AstrologicalSign={ zodiacSigns.length>0 && horoscopeReading.length>0 ? zodiacSigns[activeHoroscope].name : ""}
                    lastHoroscopes={zodiacSigns.length>0 && horoscopeReading.length>0 ? horoscopeReading.slice(0,3).map((c, idx) => ({
                        id: c.id || "",
                        icon: idx === 0 ? <FireIcon /> : "",
                        title: idx === 0 ? 'Lo más nuevo' : "Horóscopo anterior",
                        description: c.description,
                        typeReading: c.typeReading,
                        numLikes: c.like.length,
                        isLiked: false,
                        createdString: FormatDateMonth(c.createdAt.toDate())+" / "+c.hourCreated
                    })) : []}  
                    loading={loading}
                    showMinDescription
                    onClickShowMore={onClickShowMore}
                />
                <div className="tinytext horoscope-text">
                    *Solo Zeta escribe y crea el horóZcopo, por lo que dicho trabajo es de su propiedad intelectual. El resto de sus súbditos tienen prohibido manipular cualquier detalle, contenido, etc., del horóZcopo que ella realice.
                </div>
              
            </div>
        </section>
    )
}