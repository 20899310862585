import React, { useEffect, useRef } from "react";

import './Modal.css';
import { CardGradient } from "../CardGradient/CardGradient";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

interface ModalProps {
    title?: string;
    subTitle?: string;
    icon?: React.ReactNode;
    show: boolean; 
    setShow: (e:boolean) => void;
    children?: React.ReactNode;
    role?:string
    disableToast?: boolean;
    className?: string;
    onClose?: ()=>void;
}

export const Modal: React.FC<ModalProps> = ({
    title,
    subTitle,
    icon,
    show,
    setShow,
    children,
    disableToast=false,
    role,
    className='',
    onClose = ()=>{},
}) => {
    
    const refModalAnimation = useRef<HTMLDivElement>(null);


    // useEffect(()=>{
    //     // show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    //    window.document.body.style.overflow =  show ?  'hidden' : 'unset';
    //    const handleClickOutside = (event: any) => {
    //     if (ref.current && !ref.current.contains(event.target)) {
    //         // console.log("click")
    //         setShow(false);
    //     }
    //   };
    //   document.addEventListener('click', handleClickOutside, true);
    //   return () => {
    //     document.removeEventListener('click', handleClickOutside, true);
    //   };
    // },[show]);


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (refModalAnimation.current && !refModalAnimation.current.contains(event.target)) {
                // console.log("click")
                setShow(false);
            }
          };
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
          };
    }, []);

    return (
        <>
        {/* {!disableToast&& <ToastContainer hideProgressBar limit={5} position='top-right'  autoClose={3500} pauseOnFocusLoss={false} pauseOnHover={false} />} */}
            {show &&
            <div className={"modal-main " + className} role={role}>
                <div ref={refModalAnimation} className="modal-container">
                    <div className="modal__header">
                            <div className="modal__heade-left">
                                <div className="modal-header__text">
                                    <div className={subTitle?'paragraph-header modal-title':"small-bold modal-title"}>{title}</div>
                                    {subTitle && <div className="paragraph modal-subtitle">{subTitle}</div>}
                                </div>
                            </div>
                        <img src={STORAGE_ASSETS+'/images/svg/close.svg'} style={{cursor:'pointer'}} alt="" onClick={()=>{onClose(); setShow(false)}} role='modal-btn'/>
                    </div>
                    <div className="smalltext modal-text">
                        {children}
                    </div>
                </div>   
            </div>}  
        </>
    )
}