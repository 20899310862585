import React, { useEffect, useRef } from "react";

import './ModalTerms.css';
import { Modal } from "../Modal/Modal";
import { Close } from "../../Icons/outline/Close";

interface ModalTermsProps {
    showTerms: boolean;
    setShowTerms: (e:boolean) => void;
}

export const ModalTerms: React.FC<ModalTermsProps> = ({
    showTerms,
    setShowTerms
}) => {

    return (
        <Modal 
            title = {
                    "Términos y condiciones"
                }
                icon = {<Close/>}
                show = {showTerms}
                setShow = {setShowTerms}
                children = {
                    <div className="modalTyc-container">
                        <div className="modalTyc-title">1. Introducción</div>
                        <div className="modalTyc-paragraph">1.1. AZTRO X S.A.C. (“AZTRO X”, en adelante), una sociedad creada bajo las leyes de la República del Perú, identificada con Registro Único de Contribuyente Nro. 20612211338, crea la página web “www.santazinos.com” (el “Sitio Web”, en adelante), con la finalidad de ofrecer sus productos y/o servicios, detallados a lo largo del presente documento y en el objeto social de AZTRO X, a los Usuarios y Suscriptores.
                            <br/>
                            1.2. Los Términos y Condiciones descritos en el presente documento (los “Términos y Condiciones”, en adelante) regulan todo acceso, ingreso, operación, reserva, compra, transacción, suscripción y/o cualquier otra acción que realicen los Usuarios y los Suscriptores en el Sitio Web.
                            <br/>
                            1.3. La lectura y revisión detenida de los Términos y Condiciones es necesaria a fin de conocer los productos, servicios, campañas de premios, restricciones, entre otra información de relevancia, que se ofrecen en el Sitio Web.
                        </div>
                        <div className="modalTyc-title">2. Definiciones</div>
                        <div className="modalTyc-paragraph">
2.1. Para efectos de los Términos y Condiciones, se enumeran los conceptos más importantes que deben ser tomados en consideración:
<br/>
a. Usuario: persona natural mayor de edad, de 18 años o mayor de 18 años, residente en el Perú o personas naturales de otros países, consideradas con mayoría de edad en su respectiva jurisdicción, que acceda, utilice, compre o realice transacciones en la tienda virtual del Sitio Web, aceptando los Términos y Condiciones al momento de realizar sus operaciones y/o transacciones.
<br/>
b. Suscriptor: persona natural, de 18 años o mayor de 18 años residentes en el Perú o personas naturales de otros países, consideradas con mayoría de edad en su respectiva jurisdicción, que se registre en el Sitio Web bajo los planes y/o programas ofrecidos en el mismo, aceptando previamente los Términos y Condiciones, y cuente con una suscripción mensual activa en el Sitio Web.
<br/>
c. Cuenta: es una cuenta en el Sitio Web, creada por un Suscriptor del Sitio Web.
<br/>
d. Sitio Web: es nuestro sitio web www.santazinos.com.
<br/>
e. Tienda virtual: sección del Sitio Web focalizada en la venta a los Usuarios y Suscriptores de merchandising de AZTRO X (los “productos o servicios”, en adelante).
<br/>
f. Reglamento: es el reglamento de las campañas de premios de agradecimiento de AZTRO X, disponibles en el Sitio Web.
<br/>
g. Premio de AZTRO X: Consiste en un procedimiento en el que aleatoriamente se escoge a un Suscriptor para hacerle entrega de un premio especificado en el Sitio Web. La elegibilidad de los Suscriptores y la mecánica de realización del premio se describen en el Reglamento.
</div>
<div className="modalTyc-title">3. Ámbito de aplicación</div>
<div className="modalTyc-paragraph">3.1. Los Términos y Condiciones son un acuerdo vinculante entre el Usuario y AZTRO X. Al momento de realizar transacciones en el Sitio Web y al hacer clic en “ACEPTO los  Términos y Condiciones”, el Usuario reconoce, la revisión, lectura, entendimiento y aceptación expresa a los Términos y Condiciones, en consecuencia, se obliga a su estricto y entero cumplimiento. De no encontrarse de acuerdo con los Términos y Condiciones de forma parcial o total, el Usuario no podrá realizar transacciones en el Sitio Web.
<br/>
3.2. Al momento de la creación de la cuenta de Suscriptor en el Sitio Web y al hacer clic en “ACEPTO los Términos y Condiciones”, el Suscriptor reconoce, la revisión, lectura, entendimiento y aceptación expresa a los Términos y Condiciones, en consecuencia, se obliga a su estricto y entero cumplimiento. Asimismo, con la aceptación de los Términos y Condiciones, el Suscriptor declara que reconoce, la revisión, lectura, entendimiento y aceptación al Reglamento. De no encontrarse de acuerdo con los Términos y Condiciones de forma parcial o total, el Suscriptor no podrá comprar en la Tienda Virtual, crearse una Cuenta ni, en consecuencia, participar en las campañas de Premios de AZTRO X.
<br/>
3.3. AZTRO X podrá modificar de forma parcial o total los Términos y Condiciones. De acontecer lo anterior, se le informará al Suscriptor, a través de la publicación en el Sitio Web, de las modificaciones correspondientes para su aceptación. De no encontrarse conforme con las modificaciones, AZTRO X suspenderá el uso de la cuenta.
<br/>
3.4. La efectividad de las modificaciones de los Términos y Condiciones se dará en el momento en que se publiquen en el Sitio Web, siendo el Usuario el único responsable de su lectura, entendimiento y aceptación.
<br/>
3.5. Estos Términos y Condiciones se entenderán como parte integral de todos los actos y contratos que se ejecuten o celebren en el marco de las relaciones comerciales entre los Usuarios, Suscriptores  y AZTRO X, incluyendo también la política de privacidad y tratamiento de datos personales y el Reglamento, así como también cualquier otra información que AZTRO X considere de importancia que se publique en el Sitio Web.
<br/>
3.6. El Sitio Web sólo está disponible para personas que tengan capacidad legal para contratar de acuerdo a la legislación de sus países de origen. No podrán utilizar ni contratar los productos y servicios las personas que no tengan esa capacidad. En ese sentido, los Usuarios y Suscriptores del Sitio Web actúan en representación de sí mismos.
</div>

<div className="modalTyc-title">4. Registro del Suscriptor</div>
<div className="modalTyc-paragraph">
<br/>
4.1. Para el registro es necesario el llenado veraz y correcto del formulario de registro, en el cual se solicitará la siguiente información:
<br/>
a. Nombres y apellidos completos
<br/>
b. DNI o documento de identidad
<br/>
c. Dirección de correo electrónico
<br/>
d. Edad
<br/>
e. País de residencia
<br/>
f. Número telefónico
<br/>
g. Contraseña
<br/>
4.2. Una vez llenado el formulario de registro, se solicitará la aceptación a los Términos y Condiciones, se deberá hacer clic en “ACEPTO los Términos y Condiciones” para la finalización del registro.
<br/>
4.3. Una vez finalizado el registro y dentro de la nueva cuenta el Suscriptor ya contará con todos los accesos y podrá participar en los Premios de AZTRO X.
<br/>
4.4. Sin desmedro de lo anterior, el Suscriptor recibirá una notificación, la cual le señalará la información adicional que deberá completar para la configuración completa de su perfil, solicitando para ello la siguiente información:
<br/>
a. Género
<br/>
b. Apodo
<br/>
c. Dirección de residencia
<br/>
Adicionalmente, recibirá otra notificación mediante la cual se le requerirá al Suscriptor la verificación de su correo electrónico registrado en la cuenta. Finalizado tal control, el Suscriptor podrá iniciar sesión y realizar operaciones y transacciones en el Sitio Web.
Tanto la información señalada en el numeral 4.1. como en el presente numeral son necesarios para la aceptación del eventual premio en caso resulte ganador de los premios ofrecidos en las campañas de Premios de AZTRO X, especificados en el Reglamento.
<br/>
4.5. El Suscriptor se compromete a registrar su cuenta con datos veraces, válidos, correctos y completos, de no cumplirse con estos Términos y Condiciones, AZTRO X se reserva el derecho de bloquear, suspender o cerrar la cuenta, así como la anulación de los premios y beneficios que se hayan podido generar.
<br/>
4.6. Solo se permite una Cuenta por persona natural, si AZTRO X detecta casos de creaciones múltiples de cuenta o suplantaciones de identidad, AZTRO X se reserva el derecho de bloquear, suspender o cerrar la cuenta, así como la anulación de los premios y beneficios que se hayan podido generar.
<br/>
4.7. AZTRO X está autorizado a realizar procesos de verificación por sí mismo o a través de terceros, en caso tenga indicios de que la información ingresada es mal utilizada, incorrecta o falsa, para lo cual podrá bloquear, suspender o cerrar las cuentas del Suscriptor infractor.
<br/>
4.8. El Suscriptor es el único responsable de su cuenta y contraseña, siendo de uso estrictamente personal. En ese sentido, AZTRO X no se hace responsable de cualquier abuso o mal uso de la cuenta de Suscriptor por parte de terceros. En caso el Suscriptor tome conocimiento de cualquier uso no autorizado de su cuenta por parte de terceros, deberá notificarlo inmediatamente a AZTRO X escribiendo a través de la página de soporte con el asunto “Uso no autorizado de mi cuenta”, con la finalidad de suspender la cuenta. Sin embargo, AZTRO X en ningún caso será responsable de las repercusiones que ocasione dicho evento.
<br/>
4.9. Asimismo, cualquier operación o transacción que se realice en la cuenta del Suscriptor, utilizando la contraseña y nombre de Suscriptor correctos será considerada por AZTRO X como un ingreso válido por parte del Suscriptor.
<br/>
4.10. Sin perjuicio a lo antes mencionado, el Suscriptor podrá dar de baja a su suscripción en el momento que éste lo crea conveniente, siempre y cuando se realice antes de la fecha de pago o facturación. Asimismo, cualquier operación o transacción que se realice en la cuenta del Suscriptor, utilizando la contraseña y nombre de Suscriptor correctos será considerada por AZTRO X como un ingreso válido por parte del Suscriptor.
</div>
<div className="modalTyc-title">5. Obligaciones como Usuario y Suscriptor</div>
<div className="modalTyc-paragraph">
5.1. Al momento de aceptación de los Términos y Condiciones, el Usuario garantiza y reconoce lo siguiente:
<br/>
a. Que cuenta con capacidad para contratar de acuerdo a la legislación de su país de origen (persona de 18 años o mayor de 18 años residente en el Perú u otra persona natural considerada con mayoría de edad en su respectiva jurisdicción) y que no tiene impedimento legal alguno para generar operaciones en el Sitio Web.
<br/>
b. Que utiliza el Sitio Web exclusivamente con la intención de comprar productos o servicios ofrecidos por AZTRO X y no por cualquier otra finalidad.
<br/>
c. Que compra productos o servicios de AZTRO X, en nombre propio y no a favor de terceros.
<br/>
d. Que toda la información que facilita a AZTRO X durante la validez del presente contrato es verdadera, válida, correcta y completa, en caso existiese la necesidad, por cualquier motivo, de realizar algún cambio de información, el Usuario deberá modificarla inmediatamente. El Usuario es consciente que la información ingresada servirá para hacer efectiva la entrega de los productos o servicios comprados, por lo tanto, recae en el Usuario la responsabilidad plena de informar a AZTRO X la información correcta.
<br/>
e. Que todo pago que realice el Usuario en favor de AZTRO X, no proviene de fuente prohibida y/o ilegal. Asimismo, AZTRO X podrá iniciar las acciones legales y comerciales pertinentes.
<br/>
f. Que no está implicado en ninguna actividad fraudulenta, abusiva, manipuladora o ilegal y no utilizará métodos asistidos de software, técnicas y/o dispositivos de hardware para cualquier fin ajeno a la compra de los productos o servicios ofrecidos por AZTRO X, de detectarse indicios de ello, AZTRO X se reserva el derecho de bloquear el acceso del Usuario infractor, así como invalidar y dejar sin efecto sus procesos de compra.
<br/>
g. Que no utilizará el Sitio Web para transmitir, distribuir, almacenar o destruir material (i) en violación de la normativa vigente, (ii) de forma que se infrinjan derechos de terceros o se viole la confidencialidad, sensibilidad, honor, privacidad, imagen u otros derechos personales de otras personas, y (iii) que infrinja o viole estos Términos y Condiciones.
<br/>
h. Que tiene prohibido violar o intentar violar la seguridad del Sitio Web, incluyendo pero no limitándose a: (i) el acceso a datos que no estén destinados a tal Usuario o entrar en un servidor o cuenta o portal cuyo acceso no está expresamente autorizado al Usuario, (ii) evaluar o probar la vulnerabilidad de un sistema o red, o violar las medidas de seguridad, (iii) impedir o intentar impedir (total o parcialmente) la prestación de los servicios del Sitio Web a cualquier Usuario, Suscriptor, anfitrión o red, incluyendo, pero sin limitación, mediante el envío de virus al Sitio Web, o mediante saturación o ataques de denegación de los servicios, o de cualquier otra manera (iv) enviar correos no pedidos (spam), incluyendo pero sin limitarse promociones y/o publicidad de productos o servicios, o (v) falsificar cualquier cabecera de paquete TCP/IP o cualquier parte de la información de la cabecera de cualquier correo electrónico o en mensajes de foros de debate, y (vi) cualquier otra acción, omisión o maniobra tendiente a afectar el normal y correcto funcionamiento del Sitio Web y la prestación de los servicios, y/o que tenga por finalidad o resultado causar algún daño a cualquier tercero.
<br/>
5.2. Al momento de aceptación de los Términos y Condiciones, el Suscriptor garantiza y reconoce lo siguiente:
<br/>
a. Que es de 18 años o mayor de 18 años residente en el Perú u otra persona natural considerada con mayoría de edad en su respectiva jurisdicción, y que no tiene impedimento legal alguno para generar operaciones en el Sitio Web.
<br/>
b. Que utiliza el Sitio Web y su Cuenta de Suscriptor exclusivamente con la intención de participar en las campañas de Premios de AZTRO X y comprar productos o servicios ofrecidos por AZTRO X y no por cualquier otra finalidad.
<br/>
c. Que participa en las campañas de Premios de AZTRO X, en nombre propio y no a favor de terceros.
<br/>
d. Que compra productos o servicios de AZTRO X, en nombre propio y no a favor de terceros.
<br/>
e. Que toda la información que facilita a AZTRO X durante la validez del presente contrato es verdadera, válida, correcta y completa, en caso existiese la necesidad, por cualquier motivo, de realizar algún cambio de información, el Suscriptor deberá modificarla inmediatamente. El Suscriptor es consciente que la información ingresada servirá para hacer efectiva la entrega de los premios en caso resulte ser ganador de algún evento especificado en el Reglamento, por lo tanto, recae en el Suscriptor la responsabilidad plena de informar a AZTRO X la información correcta. Adicionalmente a ello, el Suscriptor es consciente que la información ingresada servirá para hacer efectiva la entrega de los productos o servicios comprados, por lo tanto, recae en el Suscriptor la responsabilidad plena de informar a AZTRO X la información correcta.
<br/>
f. Que todo pago a transacción que realice el Suscriptor en favor de AZTRO X, no proviene de fuente prohibida y/o ilegal. Asimismo, AZTRO X podrá iniciar las acciones legales y comerciales pertinentes.
<br/>
h. Que no está implicado en ninguna actividad fraudulenta, abusiva, manipuladora o ilegal y no utilizará métodos asistidos de software, técnicas y/o dispositivos de hardware para participar en ninguna de las campañas de Premios de AZTRO X, de detectarse indicios de ello, AZTRO X se reserva el derecho de bloquear, suspender o cerrar la cuenta del Suscriptor infractor, así como invalidar su participación en el/las campañas de Premios de AZTRO X en los cuales participe.
<br/>
i. Se compromete a guardar el decoro y respeto con los demás asistentes, sea que se trate del proceso de participación en las campañas de Premios de AZTRO X o los eventos presenciales/virtuales que AZTRO X pueda realizar, comprometiéndose a no realizar ningún tipo de comportamiento indebido que intimide a los demás participantes.
Que no utilizará el Sitio Web para transmitir, distribuir, almacenar o destruir material (i) en violación de la normativa vigente, (ii) de forma que se infrinjan derechos de terceros o se viole la confidencialidad, sensibilidad, honor, privacidad, imagen u otros derechos personales de otras personas, y (iii) que infrinja o viole estos Términos y Condiciones.
Que tiene prohibido violar o intentar violar la seguridad del Sitio Web, incluyendo pero no limitándose a: (i) el acceso a datos que no estén destinados a tal Suscriptor o entrar en un servidor o cuenta o portal cuyo acceso no está expresamente autorizado al Usuario, (ii) evaluar o probar la vulnerabilidad de un sistema o red, o violar las medidas de seguridad, (iii) impedir o intentar impedir (total o parcialmente) la prestación de los servicios del Sitio Web a cualquier Usuario, Suscriptor , anfitrión o red, incluyendo, pero sin limitación, mediante el envío de virus al Sitio Web, o mediante saturación o ataques de denegación de los servicios, o de cualquier otra manera (iv) enviar correos no pedidos (spam), incluyendo pero sin limitarse promociones y/o publicidad de productos o servicios, o (v) falsificar cualquier cabecera de paquete TCP/IP o cualquier parte de la información de la cabecera de cualquier correo electrónico o en mensajes de foros de debate, y (vi) cualquier otra acción, omisión o maniobra tendiente a afectar el normal y correcto funcionamiento del Sitio Web y la prestación de los servicios, y/o que tenga por finalidad o resultado causar algún daño a cualquier tercero.
<br/>
5.3. Asimismo, el Suscriptor al asistir a los eventos presenciales/virtuales que AZTRO X realice, se compromete a abstenerse de:
<br/>
1. Realizar comportamientos de acoso, intimidación o discriminación de cualquier forma hacia participantes, conferencistas, trabajadores, invitados especiales, voluntarios, expositores, influencers, de manera general sobre cualquier persona que se encuentre participando en el evento.
<br/>
2. Actos de abuso verbal hacia cualquier participante, conferencista, invitados especiales, trabajador, voluntarios, expositor, influencer, de manera general sobre cualquier persona que se encuentre participando en el evento. Ejemplos de abuso verbal incluyen, sin limitarse a, comentarios relacionados con el género, la orientación sexual, discapacidades, aspecto físico, contextura, raza, religión, nacionalidad, el uso inapropiado de desnudez y/o imágenes sexuales en espacios públicos o en presentaciones, o amenazar o acosar a cualquier persona que asista a los eventos.
<br/>
3. Interrumpir las presentaciones, conferencias, así como cualquier actividad que se realice. Los participantes deben seguir las instrucciones del moderador y del personal del evento.
<br/>
4. En caso algún Suscriptor, participante, conferencista, invitado especial, trabajador, voluntario, expositor, influencer, y de manera general cualquier persona que se encuentre participando en los eventos presenciales/virtuales sea agredido podrá presentar el reclamo contra el agresor a través de los siguientes medios: (i) Página de soporte del Sitio Web, (ii) Página de Instagram oficial de Santa Zinos o (iii) Personal de AZTRO X.
Una vez presentado el reclamo AZTRO X procederá a evaluarlo y en caso detecte que se trate de un caso de agresión, conforme a lo señalado en el presente inciso, AZTRO X tendrá la potestad de bloquear, suspender y/o cerrar la cuenta del Suscriptor. Asimismo, AZTRO X tendrá la potestad de excluir al Suscriptor de participar en los concursos de los eventos presenciales/virtuales en el que se realizó el acto de agresión, así como de concursos futuros.
<br/>
5.4. El Usuario se compromete a indemnizar de manera total a AZTRO X por cualquier, daño, perjuicio, pérdida, y/o gasto que genere a AZTRO X, por causas imputables al Usuario, que puedan surgir en relación con el uso del Sitio Web y de sus procesos de compra.
<br/>
5.4. El Suscriptor, se compromete a indemnizar de manera total a AZTRO X por cualquier, daño, perjuicio, pérdida, y/o gasto que genere a AZTRO X, por causas imputables al Suscriptor, que puedan surgir en relación con el uso del Sitio Web y de su participación en las campañas de Premios de AZTRO X ofrecidos o en sus procesos de compra.
</div>

<div className="modalTyc-title">6. Cierre de la Cuenta de Suscriptor</div>
<div className="modalTyc-paragraph">
6.1. AZTRO X se reserva el derecho de bloquear, suspender o cerrar cualquier cuenta del Suscriptor que incumpla los Términos y Condiciones. Asimismo, AZTRO X tendrá este derecho en caso el Suscriptor viole o vaya en contra de las leyes, la buena costumbre y/o el orden público. Asimismo, AZTRO X podrá iniciar las acciones legales que considere pertinentes.
</div>

<div className="modalTyc-title">7. Tienda virtual</div>
<div className="modalTyc-paragraph">
7.1. A través del Sitio Web, el Usuario o el Suscriptor iniciará el proceso de solicitud de compra de merchandising de AZTRO X, mediante la emisión de una orden de compra. Toda orden de compra quedará condicionada a la validación de AZTRO X para consolidar la aceptación de la compra.
<br/>
7.2. En caso se verifique la orden de compra por parte de AZTRO X, se informará al Usuario o al Suscriptor a través de correo electrónico el estado del pedido. AZTRO X se reserva el derecho de poder cambiar y/o cancelar el estado de la orden de compra en cualquier momento cuando evidencie algún tipo de fraude o infracción a los Términos y Condiciones. Esta cancelación se podría dar en cualquier momento, hasta antes de la entrega del producto.
<br/>
7.3. La preventa de productos o servicios estará condicionada a recibir una cantidad determinada de órdenes de pedidos, el proceso de recolección de órdenes durará entre 2 a 3 meses, antes que AZTRO X confirme el inicio de la producción. Por cuestiones logísticas y de cuidado del medio ambiente, AZTRO X se reserva el derecho de determinar las órdenes de pedido necesarias para iniciar la producción, de recibir órdenes de pedidos, y a cambiar la duración del plazo de recolección de órdenes y producción, en caso resulte necesario. En este último caso, AZTRO X informará oportunamente a los Usuarios y/o Suscriptores que hayan preordenado el merchandising.
<br/>
7.4. De iniciarse la producción, AZTRO X se comunicará a través de correo electrónico con los compradores informando la fecha de finalización de la producción y todas las actualizaciones referentes al estado de los pedidos.  De no llegarse al mínimo de pedidos para iniciar la producción, AZTRO X se comunicará con los compradores para extenderles la posibilidad de (i) solicitar la devolución del monto desembolsado, o (ii) mantener dicho monto como crédito para ser utilizado en otros productos o servicios de la Tienda Virtual.
<br/>
7.4. AZTRO X ofrecerá productos o servicios a los Usuarios y se reserva el derecho a ofrecer productos o servicios exclusivos a los Suscriptores.
<br/>
7.5. Los productos y/o servicios ofrecidos en el Sitio Web son pagados en Dólares de los Estados Unidos de América, y solo podrán ser cancelados utilizando la pasarela de pago “Culqi” (“Culqi”, en adelante), administrada por Compañía Incubadora de Soluciones Móviles S.A. - CISMO S.A., identificada con Registro Único de Contribuyente Nro. 20555530090. En ese sentido, el Usuario y/o Suscriptor acepta y autoriza a AZTRO X a realizar el cargo de las tarifas ofrecidas en el Sitio Web a través de Culqui. Si Culqui no puede completar el pago, cursará un aviso al Usuario y/o Suscriptor por escrito, suspendiéndose el acceso a los productos o servicios del Sitio Web hasta que se reciba el pago. El tipo de cambio que podría aplicarse para Usuarios no residentes en el Perú se basa en el tipo de cambio fijado por Culqi.
<br/>
7.6. AZTRO X no se responsabiliza por las variaciones en el tipo de cambio, comisiones, demora en procesamiento de pagos, cobros en exceso de Culqi.
<br/>
7.7. La correspondiente generación de boletas y facturas se realizará a través de la plataforma Nubefact, administrada por NUBEFACT S.A., identificada con Registro Único de Contribuyente Nro. 20600695771.
<br/>
7.8. Las  fotografías y vídeos relativos a los productos o servicios ofertados en el Sitio Web, tienen por objeto aportar la mayor información posible y tienen únicamente fines referenciales e ilustrativos.
<br/>
7.9. Al momento de la entrega del producto, el Usuario o el Suscriptor deberá firmar un acta de entrega. Con ello se entenderá que el Usuario o el Suscriptor se encuentra conforme con el estado y las características del producto entregado.
<br/>
7.10. El estado de los productos será de entera responsabilidad del Usuario o del Suscriptor una vez hayan sido entregados o puestos a su disposición. La entrega de los productos o servicios vendidos en el Sitio Web se realizará en la dirección de residencia previamente consignada por el Usuario o el Suscriptor, sin desmedro de ello, AZTRO X se reserva el derecho a reconfirmar la dirección de entrega.
<br/>
7.11. El costo de envío será cargado al Usuario o al Suscriptor al momento de la compra y la entrega de los productos se encuentra circunscrita al territorio peruano.
</div>

<div className="modalTyc-title">8. Despachos, reembolsos y devoluciones</div>
<div className="modalTyc-paragraph">
8.1. En caso el Usuario o el Suscriptor consideren que los productos comprados han sido entregados de forma errónea o en mal estado, podrán solicitar un pedido de reembolso devolviendo el Producto que consideran erróneo o en mal estado. El plazo para solicitar el reembolso es de 7 días calendario desde la firma del acta de entrega. Para solicitar el pedido de reembolso el producto debe estar nuevo, en buen estado, sin señales de uso ni deterioro y tal como fue entregado. Para solicitar el pedido de reembolso deberán notificarlo, en el plazo de 7 días calendario desde la firma del acta de entrega, a AZTRO X a través de la casilla de mensaje en la página de soporte del Sitio Web. Para acceder a esta deben dirigirse al botón “Soporte” en la parte inferior del Sitio Web, llenar la información solicitada e indicar el tipo de soporte que desean (opción “Mis Compras”). Una vez recibido el pedido, AZTRO X revisará el pedido de reembolso y dará la respuesta que corresponda en un plazo máximo de 3 días hábiles.
<br/>
8.2. En caso AZTRO X verifique que los productos o servicios han sido entregados de forma errónea o en mal estado, ante el requerimiento expreso por parte del Usuario o el Suscriptor, comunicará la aprobación del pedido de reembolso por correo electrónico a la dirección consignada por el Usuario o el Suscriptor que compró los productos o servicios. Se adjuntará la correspondiente nota de crédito y AZTRO X se comunicará con Culqi para la solicitud de extorno.
</div>

<div className="modalTyc-title">9. Suscriptores</div>
<div className="modalTyc-paragraph">
9.1. Para ser Suscriptor es necesario contar con una suscripción activa que deberá ser pagada mensualmente (suscripción mensual) a través del Sitio Web. El precio de la suscripción se encuentra en Dólares de los Estados Unidos de América, y el pago deberá realizarse a través de Culqi. En ese sentido, el  Suscriptor acepta y autoriza a AZTRO X a realizar el cargo de las tarifas ofrecidas en el Sitio Web a través de Culqui. Si Culqui no puede completar el pago, cursará un aviso al Suscriptor por escrito, suspendiéndose el acceso a los productos o servicios del Sitio Web hasta que se reciba el pago. El tipo de cambio que podría aplicarse para el pago en moneda extranjera se basa en el tipo de cambio fijado por Culqi. 
<br/>
9.2. Como Suscriptor, se te cobrará automáticamente una vez cada 30 días, al ser un pago recurrente, desde el día de tu primer pago si es que elegiste el medio de pago con tarjeta de débito o crédito. El cobro de la suscripción se hace al comienzo de tu ciclo de facturación y puede tardar varios días en aparecer en tu cuenta, recae en el Suscriptor la responsabilidad plena de mantener sus pagos al día. Tu fecha de facturación podría adelantarse o atrasarse un día debido a las diferencias de huso horario.
<br/>
Al respecto, el suscriptor deberá tener en cuenta y ser consciente que, cuando el pago de la suscripción sea a través del débito automático de sus tarjetas bancarias, se deberá tener en cuenta que el cobro inicia desde las 07:00 horas. En el supuesto caso que, la renovación de la suscripción tenga lugar en el día en que se realizará la campaña de Premios de AZTRO X y si por motivos ajenos a nosotros el pago no se procesa con éxito, la suscripción de la cuenta quedará en “pausa” y no podrá participar del evento del día.
Asimismo, y recalcando en caso no se pueda procesar el pago por causa no imputable a AZTRO X (falta de fondos, vencimiento/cancelación de tarjeta, etc.), el Suscriptor no podrá participar en los eventos exclusivos para suscriptores y otros beneficios exclusivos hasta regularizar el pago. Por ello se recomienda a El Suscriptor, que siempre revise la condición de su suscripción debiendo estar en todo momento “activa” para gozar de los beneficios.
En caso no se pueda procesar el pago por causa no imputable a AZTRO X (falta de fondos, vencimiento/cancelación de tarjeta, etc.), la cuenta seguirá activa, sin embargo, el Suscriptor no podrá participar en las campañas de premios exclusivos de Suscriptores y otros beneficios exclusivos hasta regularizar el pago.
<br/>
9.3. Con el objetivo de regularizar el cobro de la suscripción, AZTRO X enviará un primer aviso, vía correo electrónico, al Suscriptor indicando que no ha podido efectuar el cobro de la suscripción y que el sistema volverá a intentarlo en 24 horas. En el supuesto que AZTRO X detecte que el cobro de la suscripción aún no se ha sido procesado en el plazo otorgado, AZTRO X enviará un segundo aviso, vía correo electrónico, al Suscriptor indicando que todavía no se ha podido concretar el cobro de la suscripción, que deberá actualizar el medio de pago en la configuración de su perfil y que el sistema volverá a intentarlo, por tercera y última vez, en 24 horas.
En el supuesto que aún no se pueda procesar el cobro de la suscripción y cumplidas las 24 horas otorgadas en el segundo aviso, AZTRO X enviará un aviso, vía correo electrónico, al Suscriptor mediante el cual se indica que la cuenta se encuentra inactiva. El Suscriptor acepta y conoce que AZTRO X tiene la potestad de cerrar la cuenta y eliminar toda la información guardada en la base de datos, incluyendo su actividad dentro de la plataforma, luego de 12 meses de inactividad de la cuenta.
<br/>
9.4. AZTRO X no se responsabiliza por las variaciones en el tipo de cambio, comisiones, demora en procesamiento de pagos de Culqi. Asimismo de existir cualquier tipo de reclamo o solicitud de devolución, el suscriptor no tendrá beneficios de promociones, ni participación en las campañas de Premios de AZTRO X hasta que Culqui emita un pronunciamiento respecto al reclamo presentado, esto siempre y cuando esté el reclamo sea por devolución de dinero por cancelación de suscripción, no obstante esto no procederá y el suscriptor seguirá gozando de sus beneficios siempre y cuando el reclamo se deba por una duplicidad del cobro por suscripción mensual, en ambos casos Culqi emitirá su pronunciamiento en un plazo no mayor de 15 días.
<br/>
9.5. La correspondiente generación de boletas y facturas se realizará a través de la plataforma Nubefact.
<br/>
9.6. El ser Suscriptor trae beneficios exclusivos como la participación en las campañas de Premios de AZTRO X exclusivos de suscriptores y venta exclusiva de productos o servicios. AZTRO X se reserva el derecho de ampliar los beneficios exclusivos de los suscriptores. El suscriptor no podrá transferir su membresía.
<br/>
9.7. AZTRO X está facultado a utilizar procedimientos y/o medios adicionales con la finalidad de verificar la identidad del Suscriptor, como medida de seguridad al momento de que éste se registre en el Sitio Web y al momento de la realización de pagos para la renovación de la suscripción.
</div>

<div className="modalTyc-title">10. Eventos presenciales</div>
<div className="modalTyc-paragraph">
Se entiende por eventos presenciales la participación física de nuestros suscriptores y sus invitados, mayores o menores de edad, en una actividad organizada por AZTRO X en locales comerciales o de entretenimiento. La cantidad de invitados para cada evento presencial varía dependiendo de la organización del evento por parte de AZTRO X.

La lista se creará en base a los suscriptores que pongan “Asistir” al evento presencial y acepten los Términos y Condiciones descritos en esta cláusula. La lista de asistentes tiene un cierre en una fecha y hora específica y una vez ésta finalice, se realiza la selección de suscriptores asistentes mediante la página web www.random.org. Los suscriptores podrán asistir con la cantidad de acompañantes que el evento permita, si un evento solo permite un (1) invitado por suscriptor, el suscriptor no podrá llevar a más de una (1) persona al evento, si un suscriptor asiste con más de un acompañante, se limitará el acceso a tan solo uno (1) de sus acompañantes. La cantidad de invitados que el suscriptor puede invitar se podrá visualizar en el detalle del evento al momento de indicar la cantidad de invitados que el suscriptor desea llevar. Si el módulo no permite sumar más invitados significa que el suscriptor ha llegado al tope máximo de personas que puede invitar. Una vez que se llegue a la hora de cierre del evento ya no se podrán enviar más solicitudes de asistencia. Se le enviará un correo de confirmación a los suscriptores seleccionados. Solo los suscriptores que puedan asistir a la fecha, hora y lugar descrito en el detalle del evento deberían presionar el botón “asistir”. Si un usuario presiona “asistir” y no acude, se procederá a limitar su acceso a futuros eventos presenciales. Una vez el suscriptor acepta los siguientes Términos y Condiciones del evento presencial, este se vuelve un usuario aceptante:
<br/>
A. Certificación de asistencia y autorización de uso de imagen del suscriptor

El aceptante confirma su asistencia tras aceptar los Términos y Condiciones del evento presencial de la página web www.santazinos.com organizado por AZTRO X S.A.C.

Por medio de la presente, declaro que soy físicamente capaz de participar en el evento y que no tengo restricciones de salud conocidas que puedan poner en peligro mi seguridad o salud, o la de otros, durante el evento. En caso de ser necesario, doy permiso para que se brindan atenciones de emergencia de manera inmediata y razonable. Aceptó ser el único responsable del pago completo de todos los costos de atención médica que pueda recibir.

Además, de asistir con un invitado menor de edad (ver punto C), me comprometo a:
Instruir al menor a guardar un comportamiento moderado y seguir las instrucciones que puedan ser impartidas por las personas encargadas de dirigir el evento.

Sin perjuicio de guardar un comportamiento moderado, haré de conocimiento del menor de las reglas de conducta del evento, las mismas que se encuentran mencionadas en el numeral 5 del presente documento, declarando su aceptación y obligatorio cumplimiento.

Asumir responsabilidad por cualquier daño que pudiera generar el menor. Coordinar personalmente el traslado del menor de edad (“ida y vuelta del evento”), sea realizado por cuenta propia o por medio de la Institución de la que forma parte (colegio, entidad u organización privada o del estado); siendo enteramente de mi responsabilidad que esto se realice de manera puntual a la hora de inicio (arribo) y a la hora final (recojo) del evento.

Encontrarme pendiente y atender cualquier comunicación que pudiera ser realizada a mi persona durante la realización del evento.

Asimismo, autorizo que se puedan tomar fotografías o grabar videos que puedan contener mi imagen y/o voz; así como tomar, grabar, publicar u obtener testimonios u otras declaraciones mías; y utilizar, copiar, modificar, adaptar, distribuir, publicar, exhibir, presentar y usar de otra manera, en parte o en su totalidad, las mismas con fines publicitarios en todos los medios publicitarios que AZTRO X considere pertinentes, tales como Internet, redes sociales, anuncios en las páginas web, en prensa televisiva, escrita, etc; no generando ninguna retribución económica. Las fotografías o videos podrán ser modificados, editados, reproducidos, exhibidos o distribuidos de otra manera, en parte o en su totalidad, siempre que no cambien o tergiversen de manera considerable el contenido original.
Renuncio, libero y exonero a AZTRO X, los organizadores del evento y a sus patrocinadores, así como a cualquier otra entidad relacionada con la organización del evento, de toda reclamación, demanda, acción, causa de acción, responsabilidad, daño de cualquier tipo, que surja del evento o de las actividades de ese día, y prometo no presentar ninguna reclamación o causa por lesiones o pérdidas que pueda sufrir debido a mi visita o relacionadas con ella.

Declaro que soy mayor de dieciocho años (18) y que he leído y comprendido lo anterior.

Por otro lado, si el suscriptor aceptante decide acudir con un invitado(a) mayor de edad, este debe enviarle la invitación únicamente a su invitado, si es que la información de otra persona es ingresada en el formulario de invitación, esta no puede ser cambiada posteriormente. El suscriptor se compromete a comunicarle a su invitado(a) la existencia de estos Términos y Condiciones que debe aceptar para poder participar en el evento presencial:
<br/>
B. Certificación de asistencia de invitado(a) o invitados(as) mayores de edad del suscriptor y autorización de uso de imagen

El aceptante, de haber incluido acompañante(s) al momento de participar por su entrada para el evento presencial, declara que dicho(s) acompañante(s) atenderá al evento organizado por AZTRO X S.A.C.

La autorización permite al invitado participar en las actividades programadas en el evento. En tal sentido, El invitado(s) y el suscriptor se comprometen a:
Guardar un comportamiento moderado y seguir las instrucciones que puedan ser impartidas por las personas encargadas de dirigir el evento.

Sin perjuicio de guardar un comportamiento moderado, el suscriptor hará del conocimiento del invitado de las reglas de conducta del evento, así como de su cumplimiento obligatorio.

Asumir responsabilidad por cualquier daño que pudiera generar el invitado.

Asimismo, el invitado o invitados autorizan que se puedan tomar fotografías o grabar videos que puedan contener la imagen y/o voz del invitado; así como tomar, grabar, publicar u obtener testimonios u otras declaraciones nuestras o del invitado; y utilizar, copiar, modificar, adaptar, distribuir, publicar, exhibir, presentar y usar de otra manera, en parte o en su totalidad, las mismas con fines publicitarios en todos los medios publicitarios que AZTRO X considere pertinentes, tales como Internet, redes sociales, anuncios en las páginas web, en prensa televisiva, escrita, etc; no generando ninguna retribución económica. Las fotografías o videos podrán ser modificados, editados, reproducidos, exhibidos o distribuidos de otra manera, en parte o en su totalidad, siempre que no cambien o tergiversen de manera considerable el contenido original.

Por otro lado, el invitado o los invitados declaran que son físicamente capaces de participar en el evento y no tienen restricciones de salud conocida que puedan poner en peligro su seguridad o salud, o la de otros, durante el evento. En caso pudiera requerirse, el invitado da permiso para que se brinden atenciones de emergencia de manera inmediata y razonable. El invitado acepta ser el único responsable del pago completo de todos los costos de la atención médica que pueda recibir.

En consecuencia, en nombre del invitado, sus herederos, representantes, o cualquier otro interesado, el invitado renuncia, libera y exonera a los organizadores del evento, así como a AZTRO X S.A.C., de toda reclamación, demanda, acción, causa de acción, responsabilidad, daño de cualquier tipo, que surja del evento o de las actividades de ese día, y promete no presentar ninguna reclamación o causa por lesiones o pérdidas que los invitados mencionados puedan sufrir debido a su visita o relacionadas con ella.

El invitado declara que es mayor de dieciocho años (18) y que ha leído y comprendido lo anterior.
Finalmente, si el suscriptor aceptante decide acudir con un invitado(a) menor de edad, este debe enviarle la invitación únicamente a su apoderado(a), si es que la información de otro menor de edad es ingresada en el formulario de invitación, esta no puede ser cambiada posteriormente. El suscriptor se compromete a comunicarle a su apoderado(a) la existencia de estos Términos y Condiciones que debe aceptar para que autorice al menor de participar en el evento presencial:
<br/>
C. Autorización en caso se asista con un menor de edad

El apoderado(a) aceptante, como padre, madre, tutor o representante legal del menor de edad, autoriza al referido menor a asistir al evento presencial organizado por AZTRO X S.A.C.
La autorización permite al menor participar en las actividades programadas en el evento, la autorización no permite al apoderado/a asistir al evento a menos que este sea el mismo suscriptor(a) que está invitando y asistiendo al evento.

Asimismo, el apoderado(a) autoriza que se puedan tomar fotografías o grabar videos que puedan contener la imagen y/o voz del menor; así como tomar, grabar, publicar u obtener testimonios u otras declaraciones del menor; y utilizar, copiar, modificar, adaptar, distribuir, publicar, exhibir, presentar y usar de otra manera, en parte o en su totalidad, las mismas con fines publicitarios en todos los medios publicitarios que AZTRO X considere pertinentes, tales como Internet, redes sociales, anuncios en las páginas web, en prensa televisiva, escrita, etc; no generando ninguna retribución económica. Las fotografías o videos podrán ser modificados, editados, reproducidos, exhibidos o distribuidos de otra manera, en parte o en su totalidad, siempre que no cambien o tergiversen de manera considerable el contenido original.

Por otro lado, el apoderado(a) declara que el menor es físicamente capaz de participar en el evento y no tiene restricciones de salud conocida que puedan poner en peligro su seguridad o salud, o la de otros, durante el evento. En caso pudiera requerirse, el apoderado(a) da permiso para que se brindan atenciones de emergencia de manera inmediata y razonable. El apoderado(a) acepta ser el único responsable del pago completo de todos los costos de la atención médica que el menor pueda recibir.
En consecuencia, en nombre del apoderado(a) y en nombre del menor, sus herederos, representantes, o cualquier otro interesado, el apoderado y el menor renuncian, liberan y exoneran a los organizadores del evento, así como al local en donde se desarrolla el evento, de toda reclamación, demanda, acción, causa de acción, responsabilidad, daño de cualquier tipo, que surja del evento o de las actividades de ese día, y prometen no presentar ninguna reclamación o causa por lesiones o pérdidas que los menores mencionados puedan sufrir debido a su visita o relacionadas con esta.

El apoderado declara que es mayor de dieciocho años (18) y que ha leído y comprendido lo anterior.

</div>

<div className="modalTyc-title">11. Premios y entrega de premios</div>
<div className="modalTyc-paragraph">
11.1. La mecánica, restricciones, condiciones y requisitos de las campañas de Premios de AZTRO X para los Suscriptores se encuentran especificada y detallada en el Reglamento, con la aceptación de los Términos y Condiciones, el Usuario declara que reconoce, la revisión, lectura, entendimiento y aceptación expresa al Reglamento.
<br/>
11.2. Con relación a la entrega de los premios, el Suscriptor se compromete a seguir el correspondiente procedimiento legal de entrega de los mismos especificado en el Reglamento.
</div>

<div className="modalTyc-title">12. Política de privacidad</div>
<div className="modalTyc-paragraph">
12.1. El Usuario o el Suscriptor no podrá hacer uso del Sitio Web de forma ilegal o en cualquier otra manera que pudiera dañarla, incapacitarla o perjudicarla.
<br/>
12.2. El Usuario o el Suscriptor autoriza a AZTRO X a usar su información personal para los fines comerciales que considere conveniente de conformidad con el acápite 4 de los Términos y Condiciones y, de manera complementaria, con la Política de Privacidad del Sitio Web.
<br/>
12.3. AZTRO X estará facultado a proporcionar los datos personales ingresados por los Usuarios o los Suscriptores, en caso sea exigido por ley o mandato judicial.
<br/>
12.4. AZTRO X a través de su Sitio Web recopila una pequeña pieza de información enviada desde su navegador, llamada cookie. En caso el Usuario o el Suscriptor desee desactivar el cookie deberá verificar las instrucciones de su navegador.
</div>

<div className="modalTyc-title">13. Reclamaciones, quejas y/o sugerencias</div>
<div className="modalTyc-paragraph">
En caso el Usuario o el Suscriptor tenga una queja, reclamo y/o sugerencia correspondiente a los productos y servicios ofrecidos por la Página Web, podrá realizarlo a través de la sección de contacto y/o ingresando una hoja de reclamaciones a través del Libro de Reclamaciones virtual.
</div>

<div className="modalTyc-title">14. Limitación de responsabilidad</div>
<div className="modalTyc-paragraph">
14.1. El Suscriptor es el único responsable de sus actos y de las consecuencias que estos generen, por lo tanto, participa en las campañas de Premios de AZTRO X bajo su propio riesgo. AZTRO X no ofrece garantías de ningún tipo.
<br/>
14.2. AZTRO X, no garantiza que el software de la Página web sea compatible con todas las versiones de navegador.
<br/>
14.3. AZTRO X no garantiza que el software y la página web estén libres de errores.
<br/>
14.4. AZTRO X no garantiza que el Sitio Web esté accesible sin interrupciones.
<br/>
14.5. AZTRO X no será responsable de ninguna pérdida, costas y/o costos, gastos o daños, tanto directos como indirectos, especiales, consecuentes, accidentales o de cualquier otro tipo, que surjan en relación del uso individual del Sitio Web.
<br/>
14.6 AZTRO X no será responsable respecto de cualquier daño que pudiera generarse como consecuencia de defectos o errores en la operación del Sitio Web, salvo que en la generación de dicho daño haya mediado una conducta dolosa o con culpa inexcusable por parte de AZTRO X.  Sin perjuicio de ello, AZTRO X desplegará sus mejores esfuerzos por resolver cualquier observación relacionada al funcionamiento del Sitio Web.
</div>

<div className="modalTyc-title">15. Incumplimiento</div>
<div className="modalTyc-paragraph">
15.1. En caso el Usuario incumpla cualquier disposición de los Términos y Condiciones o se vea implicado en actividades ilegales, sospechosas y/o fraudulentas, AZTRO X podrá bloquear el acceso del Usuario, así como invalidar sus procesos de compra.
<br/>
15.2. En caso el Suscriptor incumpla cualquier disposición de los Términos y Condiciones o se vea implicado en actividades ilegales, sospechosas y/o fraudulentas, AZTRO X podrá bloquear, suspender o cerrar la cuenta del Colaborador infractor.
</div>

<div className="modalTyc-title">16. Propiedad intelectual</div>
<div className="modalTyc-paragraph">
16.1. El Usuario no podrá atentar contra los derechos de propiedad intelectual del software que AZTRO X pone a su disposición. En ese sentido, el Usuario y/o Suscriptor sólo podrá utilizar el software para su uso personal de acuerdo con todas las normas, Términos y Condiciones que se establecen en el presente instrumento. En ese sentido, el Usuario y/o Suscriptor queda prohibido de copiar, traducir, desensamblar o descompilar, ni crear o tratar de crear por medio de ingeniería reversiva, o de cualquier otra forma, el código fuente a partir del código objeto del Sitio Web.
<br/>
16.2. Todo el contenido disponible en el Sitio Web de AZTRO X, incluyendo diseños, texto, gráficos, cuadros, videos, información y otros, es de propiedad exclusiva de AZTRO X y de sus licenciantes, de ser el caso (la “Información Propietaria”). Ningún contenido puede ser modificado, copiado, distribuido, enmarcado, reproducido, descargado, mostrado, fijado, transmitido, o vendido en cualquier forma o por cualquier medio, en todo o en parte, sin el permiso previo por escrito de AZTRO X.
<br/>
16.3. Asimismo, las marcas, denominativas o figurativas, marcas de servicio, diseños industriales y cualquier otro elemento de propiedad intelectual que haga parte de la Información Propietaria (la "Propiedad Industrial"), son de propiedad de AZTRO X o de terceros que han licenciado su uso a AZTRO X y, por tal razón, están protegidas por las leyes y los tratados internacionales de derecho de marcas, patentes, modelos y diseños industriales.
<br/>
16.3. El registro en el Sitio Web, la compra de productos y la participación en las campañas de Premios de AZTRO X no confiere ningún derecho de propiedad intelectual a favor del Usuario y/o Suscriptor.
<br/>
16.4. El uso indebido y la reproducción total o parcial de la Información Propietaria, la Propiedad Industrial y el Sitio Web quedan prohibidos.
<br/>
16.5. El incumplimiento de las obligaciones contenidas en la presente cláusula, producirá la resolución automática de la relación entre la AZTRO X y el Usuario y/o Suscriptor; ello sin perjuicio de las acciones legales que puedan iniciarse por los daños y perjuicios derivados de dicho incumplimiento.
</div>

<div className="modalTyc-title">17. Legislación aplicable</div>
<div className="modalTyc-paragraph">
17.1. Cualquier otra implicancia no prevista en los Términos y Condiciones, se regirán bajo la legislación vigente aplicable en el Perú.
<br/>
17.2. El Usuario está de acuerdo en que cualquier conflicto o litigio que surja con AZTRO X será sometido a la competencia y jurisdicción de los Jueces y Tribunales del Distrito Judicial de Lima, renunciando de manera anticipada a cualquier otro fuero que pudiera corresponderle.
</div>

<div className="modalTyc-title">18. Cesión de posición contractual</div>
<div className="modalTyc-paragraph">
AZTRO X está facultado para ceder su posición contractual y/o derechos que se deriven del presente acuerdo sin que se requiere la aceptación previa de los Usuarios o terceros, de ser el caso.
</div>

<div className="modalTyc-title">19. Acuerdo</div>
<div className="modalTyc-paragraph">
Los Términos y Condiciones aquí descritos y previamente aceptados por el Usuario, así como cualquier otra comunicación electrónica entablada con el Usuario, el Suscriptor o con terceros, podrán ser utilizadas como prueba en procesos judiciales, administrativos, arbitrales o de cualquier otra índole.
</div>


                    </div>
                }
                onClose = {() => setShowTerms(false)}
            />
    )
}