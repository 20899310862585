import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { ReadingPriceInterface } from "../Interfaces/ReadingPriceInterface";
import { DB } from "../FirebaseConfig/FirebaseConfig";

export const GetReadingPriceActive = async (): Promise<ReadingPriceInterface[]> => {
    const q = query(collection(DB, "ReadingPrice"), where("status", "==", true), orderBy("price", "asc"));

    const response = await getDocs(q);
    let data: ReadingPriceInterface[] = [];
    response.forEach((doc) => {
        data.push(doc.data() as ReadingPriceInterface);
    });
    return data;
};