import React, { useState } from "react";
import "./EmailSection.css";
import { CardGradient } from "../CardGradient/CardGradient";
import { PhoneCountry } from "../PhoneCountry/PhoneCountry";
import { userErrorInterface } from "../../Interfaces/UserError.interface";
import { ErrorInterface } from "../../Interfaces/ErrorInterface";
import { Validatefield } from "../../Utils/ValidateFields";

interface EmailSectionProps {
  onClick: () => void;
  phone?: string;
  phoneCode?: string;
  phoneFlag?: string;
  handleInputchange: (value: string, key: "phone" | "phoneCode" | "phoneFlag") => void;
  loading: boolean;
}

export const EmailSection: React.FC<EmailSectionProps> = ({
  onClick,
  phone = "",
  phoneCode = "",
  phoneFlag = "",
  handleInputchange,
  loading,
}) => { 


  const [errorPhone, setErrorPhone] = useState<ErrorInterface>({
    message: "",
    status: false,
  });
  
  const [errorCode, setErrorCode] = useState<ErrorInterface>({
    message: "",
    status: false,
  });

  const validateFields = (): boolean => {
    let error: boolean = false;
    error = Validatefield("required", phone) || Validatefield("required", phoneCode);

    setErrorPhone({
      message: "Ingrese un número de teléfono",
      status: Validatefield("required", phone)
    });

    setErrorCode({
      message: "Ingrese un código de país",
      status: Validatefield("required", phoneCode)
    });

    return error;
  }

  const clearFields = () => {
    setErrorPhone({
      message: "",
      status: false,
    });

    setErrorCode({
      message: "",
      status: false,
    });
  }

  const handleClick = () => {
   
    if(!validateFields()) {
      onClick();
      clearFields();
    }

  }


  return (
    <div className="emailSection-container ">
      <CardGradient className="emailSection">
        <div className="emailSection-content">
          <PhoneCountry
            handleCountryChange={(value)=> handleInputchange(value, "phoneFlag")}
            handlePhoneCodeChange={(value)=>handleInputchange(value, "phoneCode")}
            handlePhoneChange={(value)=>handleInputchange(value.target.value, "phone")}
            phone={phone}
            phoneCode={phoneCode}
            phoneFlag={phoneFlag}
            error={{
              phone: errorPhone,
              phoneCode: errorCode
            }}
          />
          <div className="tinytext-header-1 emailSection-button" onClick={()=>!loading ? handleClick() : {}}>
            {!loading ? "Sorpréndeme" : "Cargando..." }
          </div>
        </div>       
      </CardGradient>
    </div>
  );
};
