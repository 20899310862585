import React from "react";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

type CloseProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Close: React.FC<CloseProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/close.svg"} alt="" />
    </div>
  );
};