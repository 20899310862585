import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { ScheduleOperation } from "../Interfaces/ScheduleOperation";
import { DB } from "../FirebaseConfig/FirebaseConfig";

export const CreateScheduleOperation = async (scheduleOperation: ScheduleOperation) => {
    try {
        const response:any = await addDoc(collection(DB, "ScheduleOperation"), scheduleOperation);
        console.log("🚀 ~ createScheduleOperation ~ response:", response)
        return response;
    } catch (error) {
        console.log("🚀 ~ createScheduleOperation ~ error:", error);
        throw error;        
    }
};

// method to get the schedule operation by id
export const GetScheduleOperation = async (id: string): Promise<ScheduleOperation> => {
    try {
        const response = await getDoc(doc(DB, "ScheduleOperation", id));
        if(response.exists()) {
            return {id: response.id, ...response.data()} as ScheduleOperation;
        }
        throw new Error("Schedule Operation not found");
        ;
    } catch (error) {
        console.log("🚀 ~ getScheduleOperation ~ error:", error);
        throw error;
    }
}