const { format } = require("date-fns");

export const FormatDateMonth = (value: Date) => {
    let date_string = format(new Date(value), 'dd MMM');
    let day_string = format(new Date(value), 'E');
    
    return translateDayToSpanish(day_string)+" "+translateMonthToSpanish(date_string);
}

const translateDayToSpanish = (value: string): string => {
    let day: string = "";
    switch (value) {
        case "Mon":
            day = "Lun.";
            break;
        case "Tue":
            day = "Mar.";
            break;
        case "Wed":
            day = "Mie";
            break;
        case "Thu":
            day = "Jue.";
            break;
        case "Fri":
            day = "Vie.";
            break;
        case "Sat":
            day = "Sab.";
            break;
        case "Sun":
            day = "Dom.";
            break;
        default:
            break;
    }
    return day;

}

// translate to spanish
const translateMonthToSpanish = (value: string): string => {
    let month: string = "";    
    switch (value.split(" ")[1]) {
        case "Jan":
            month = "Ene";
            break;
        case "Feb":
            month = "Feb";
            break;
        case "Mar":
            month = "Mar";
            break;
        case "Apr":
            month = "Abr";
            break;
        case "May":
            month = "May";
            break;
        case "Jun":
            month = "Jun";
            break;
        case "Jul":
            month = "Jul";
            break;
        case "Aug":
            month = "Ago";
            break;
        case "Sep":
            month = "Sep";
            break;
        case "Oct":
            month = "Oct";
            break;
        case "Nov":
            month = "Nov";
            break;
        case "Dec":
            month = "Dic";
            break;
        default:
            break;
    }
    return value.split(" ")[0] + " " + month;
}