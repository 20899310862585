import React, { useEffect, useState } from "react";

// Importando estilo
import "./ProductAward.css";
import { WinnerRaffleInterface } from "../../Interfaces/WinnerRaffleInterface";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

interface ProductAwardProps {
    award: WinnerRaffleInterface;
}

export const ProductAward: React.FC<ProductAwardProps> = ({
    award
}) => {

  
    return (
        <div className="productAward">
            <div className="productAward-left">
                <img 
                    className="productAward-img-row" 
                    src={STORAGE_ASSETS+"/images/png/product3.png"}
                    alt="imagen" />
                <div className="productAward-detail">
                    <div className="tinytext productAward-text productAward-anuncie">¡Haz ganado!</div>
                    <div className="small-bold productAward-text">Audífonos Bose QuietComfort 35</div>
                    <div className="tinytext productAward-text">Jue. 28 DIC / 10:00 A.M. </div>
                    <div className="tinytext productAward-text-red">Vence el 28/DIC/2024</div>
                </div>
            </div>

            <div className="productAward-right">
                <div className="paragraph productAward-text">Tu código es A4312NK5 </div>
                <div className="tinytext productAward-text">Reclama tu premio en www.shopstar.pe</div>
                <div className="tinytext productAward-text">Comunícate por Whatsapp al +51 976616514</div>
            </div>
        </div>
    );
};
