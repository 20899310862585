import React, { useEffect, useState } from "react";

import "./MyAwards.css";
import { Awards } from "../../../Components/Awards/Awards";
import { WinnerRaffleInterface } from "../../../Interfaces/WinnerRaffleInterface";
import { Loader } from "../../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { GetWinnerRaffleByUser } from "../../../Service/WinnerRaffle.service";
import { useSelector } from "react-redux";

interface MyAwardsProps {

}

export const MyAwards: React.FC<MyAwardsProps> = () => {

    const {userDetail} = useSelector((state: any) => state.user);

    const [loading, setLoading] = useState<boolean>(false);
    const [awards, setAwards] = useState<WinnerRaffleInterface[]>([]);

    const getAwards = async () => {
        if(loading && !userDetail) return;

        setLoading(true);
        try {
            const response = await GetWinnerRaffleByUser(userDetail.id);
            console.log("🚀 ~ getAwards ~ response:", response)
            if(response) {
                setAwards(response);
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Ocurrio un error al obtener tus premios");
            }
        } catch (error) {
            console.log("🚀 ~ getAwards ~ error:", error);
            setLoading(false);
            toast.error("Ocurrio un error al obtener tus premios");
        }
    }

    useEffect(() => {
        getAwards();
    }, []);

    return (
        <div className="awards-container">
            {!loading ? <Awards awards={awards} /> : <div className="profile-container-loader"><Loader /></div>}
        </div>
    )
}