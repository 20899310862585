import React, { useEffect, useState } from "react";

import './RegisterPay.css';
import { SubscriptionConcept } from "../SubscriptionConcept/SubscriptionConcept";
import { CreditCard } from "../CreditCard/CreditCard";
import { PlanInterface } from "../../Interfaces/PlansInterface";
import { CardDataInterface } from "../../Interfaces/CardInterface";
import { CreateNewSuscription } from "../../Service/Subscription.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/store";

interface RegisterPayProps {
    planRegister: PlanInterface;
    email: string;
    updateUserAndGoToHome: () => void;
    userId: string;
}

export const RegisterPay: React.FC<RegisterPayProps> = ({
    planRegister,
    email,
    updateUserAndGoToHome,
    userId
}) => {
    
    const [loading, setLoading] = useState<boolean>(false);

    const [cardData, setCardDate] = useState<CardDataInterface>({
        cardNumber: 0,
        monthExp: 0,
        yearExp: 0,
        cvv: 0,
        name: "",
        dues: "1",
        email: "",
    });

    // method to process payment
    const handleProcessPay = (token: string) => {
        // console.log("🚀 ~ handleProcessPay ~ token:", token)
        if(loading) return;

        setLoading(true);
        CreateNewSuscription({
            toke: token,
            userId: userId,
            planId: planRegister.id || ""
        }).then((response) => { 
            // console.log("🚀 ~ file: RegisterPay.tsx ~ line 64 ~ response", response);
            if(response) {
                toast.success("Pago procesado con éxito");
                updateUserAndGoToHome();
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Error al procesar el pago");
            }          
        }).catch((error) => {
            console.log("🚀 ~ handleProcessPay ~ error:", error);
            setLoading(false);
            toast.error("Error al procesar el pago");
        });
    }

    useEffect(() => {
        setCardDate((prev) => ({
            ...prev,
            email
        }))
    }, [email]);

    return (
        <div className="registerPay">
          {!loading ?  <> <SubscriptionConcept
                title={planRegister.title}
                detail={planRegister.detail}
                period={planRegister.period}
                price={planRegister.price}
            />
            <CreditCard
                price={planRegister.price}
                email={cardData.email}
                currency="USD"
                onClick={handleProcessPay}
            /></> : <Loader />}
        </div>
    )
}