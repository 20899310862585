import React, { useState } from "react";

import "./Carousel.css";
import { CarouselCard } from "../CarouselCard/CarouselCard";
import { CardGradient } from "../CardGradient/CardGradient";
import { horoscopeReadingTypes } from "../../@types/horoscopeReadingTypes";
import { useSelector } from "react-redux";

interface CarouselProps {
    AstrologicalSign: string;
    AstrologicalSignImg: string;
    lastHoroscopes: {
        icon: React.ReactNode;
        id: string;
        title: string; // title of the horoscope
        description: string; // description of the horoscope
        typeReading: horoscopeReadingTypes;
        numLikes: number; // number of likes of the horoscope
        isLiked: boolean;
        createdString: string; // date of creation of the horoscope
    }[];
    showMinDescription?: boolean;
    position?: string; // position of the carousel
    loading?: boolean;
    onClickShowMore?: () => void;
}

export const Carousel: React.FC<CarouselProps> = ({
    AstrologicalSign,
    AstrologicalSignImg,
    lastHoroscopes,
    showMinDescription = false,
    position = "row",
    loading = false,
    onClickShowMore = ()=>{}
}) => {

    const [idxActive, setIdexActive] = useState<number>(1);

   
    return (
       <CardGradient className="carousel-lecture">
            <div className="carousel-container">
                <div className="carousel-title">
                   {!loading &&  <>
                    <img style={{display: "flex"}} src={AstrologicalSignImg} />
                    <div className="carousel-text paragraph-subheader">Si eres {AstrologicalSign}</div>
                    </>}
                </div>
                <div className={`carousel-content ${position === "row" ? " " : "carousel-column" } `}>
                    {lastHoroscopes.length>0 && lastHoroscopes.map((horoscope, idx) => (
                        <React.Fragment key={idx}>
                            <CarouselCard
                                key={idx}
                                id={horoscope.id || ""}
                                icon={horoscope.icon}
                                title={position === "row" ? horoscope.title : (idx<= idxActive ? horoscope.title : "")}
                                typeReading={horoscope.typeReading}
                                description={showMinDescription ? horoscope.description.slice(0, 75): horoscope.description}
                                numLikes={horoscope.numLikes}
                                createdString={horoscope.createdString}
                                showMore={showMinDescription}
                                isLiked={horoscope.isLiked}
                                onClickShowMore={onClickShowMore}
                            />
                            {position === "column" && idx <= idxActive && 
                            <div className="carousel-contenedor"> 
                                <hr className="carousel-separator"/>
                                {/* <div className="carousel-text paragraph-header">Historial</div> */}
                            </div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {/* <HoroscopeCardLoader /> */}
       </CardGradient>
    )
}