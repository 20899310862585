import React from "react";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

type UpProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Up: React.FC<UpProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/up.svg"} alt="" />
    </div>
  );
}; 