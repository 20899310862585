import React from "react";

import './ContactInformation.css';
import { DetailInformation } from "../DetailInformation/DetailInformation";
import { CardTextBody } from "../CardTextBody/CardTextBody";
import { UserInterface } from "../../Interfaces/UserInterface";

interface ContactInformationProps {
    user: UserInterface;
    updatedUser: () => void;
}

export const ContactInformation: React.FC<ContactInformationProps> = ({
    user,
    updatedUser
}) => {
    return (
        <div className="contactInformation-container">
            <DetailInformation
                user={user}
                updatedUser={updatedUser}
            />
            <CardTextBody
                title="Mi contraseña"
                description="Te llegará un correo electrónico de validación en caso decidas cambiarla."
                textButton="Actualizar mi contraseña"
                isChangePassword
            />
        </div>
    )
}