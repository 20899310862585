import React, { useEffect, useState } from "react";

// Importando estilo
import "./User.css";
import { selectTextColor } from "../../Utils/selectTextColor";
import { TextColorOptions } from "../../@types/textColorOptionsTypes";

interface UserProps {
    photoUrl: string;
    alias?: string;
}

export const User: React.FC<UserProps> = ({
    photoUrl,
    alias = "",
}) => {

  
  return (
    <div className="user">
      {photoUrl && photoUrl.length >0 ? <img className="user-img" src={photoUrl}/> :
      <div className="user-alias paragraph-header ">{alias}</div>}
    </div>
  );
};

