import React, { useState } from "react";

import './RegisterCode.css';
import { LabelText } from "../LabelText/LabelText";
import { Button } from "../Button/Button";
import { CardBackground } from "../CardBackground/CardBackground";
import { TextField } from "../TextField/TextField";
import { ErrorInterface } from "../../Interfaces/ErrorInterface";
import { Validatefield } from "../../Utils/ValidateFields";

interface RegisterCodeProps {
    code: string;
    setCode: React.Dispatch<React.SetStateAction<string>>;
    handleClick: () => void;
}

export const RegisterCode: React.FC<RegisterCodeProps> = ({
    code,
    setCode,
    handleClick
}) => {

    const [error, setError] = useState<ErrorInterface>({
        status: false,
        message: ""
    });

   

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    }

    const handleVallidateCode = () => {
        let codeError = {
            status: false,
            message: ""
        };

        if(Validatefield("required", code)){
            codeError = {
                status: true,
                message: "El campo código es requerido"
            }
        }

        setError(codeError);
        
        if (!codeError.status) {
            handleClick();
        }
    }

    return (
        <div className="registerCode">
            <CardBackground>
                <TextField
                    label="Ingresa código"
                    placeholder="Ingresa el código"
                    value={code}
                    onChange={(e)=>handleCodeChange(e)}
                    error={error}
                />

                <Button
                    text="Verificar"
                    color="orange"
                    onClick={() => handleVallidateCode()}
                />
            </CardBackground>
        </div>
    )
}