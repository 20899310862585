import { configureStore } from '@reduxjs/toolkit'

import logger from 'redux-logger'

import AuthReducer from '../Slice/Auth';
import User from "../Slice/User";
import Plan from "../Slice/Plan";
import ZodiacSignSlice from "../Slice/zodiacSign";

const reducer = {
  auth: AuthReducer,
  user: User,
  plan: Plan,
  zodiacSignSlice: ZodiacSignSlice
}
const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}), //.concat(logger),
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;