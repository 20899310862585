import React from "react";

import { Routes, Route } from 'react-router-dom';
import { LandingPage } from "../Pages/Landing/LandingPage";
import { AdminPage } from "../Pages/Admin/Admin";
import { Home } from "../Pages/Home/Home";
import { Profile } from "../Pages/Profile/Profile";
import { ProtectedRoute } from "../Components/ProtectedRouter/ProtectedRouter";
import { HOME, MY_PROFILE } from "../Const/BaseUrlConst";

export const RoutesMain: React.FC<{}> = () => { 

    return (    
        <Routes> {/** bloque de ruta */}
            <Route path="/" element={<AdminPage/>}>
                <Route path="" element={<LandingPage/>} />
                <Route path={`/${HOME}`} element={
                    <ProtectedRoute>
                        <Home/>
                    </ProtectedRoute>
                } />
                <Route path={`${MY_PROFILE}`} element={
                    <ProtectedRoute>
                        <Profile/>
                    </ProtectedRoute>
                } />
                <Route path={`/${MY_PROFILE}/:section`} element={
                    <ProtectedRoute>
                        <Profile/>
                    </ProtectedRoute>
                } />
                {/* <Route path="/profile/contact" element={<ContactProfile/>} />
                <Route path="/profile/my-subscription" element={<Subscription/>} />
                <Route path="/profile/my-awards" element={<MyAwards/>} />
                <Route path="/profile/my-receipts" element={<MyReceipts/>} /> */}
            </Route>
        </Routes>
    )
}