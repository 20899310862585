import React, { useEffect, useRef, useState } from "react";

import './Dropdown.css';
import { Down } from "../../Icons/solid/Down";
import { Up } from "../../Icons/solid/Up";
import { ErrorInterface } from "../../Interfaces/ErrorInterface";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

interface DropdownProps {
    name: string;
    items: {id: number, label: string}[];
    handleItemClick?: (label: string, idx?: number) => void;
    error?: ErrorInterface;
    disable?: boolean;
    setValue?: React.Dispatch<React.SetStateAction<string>>;
    value?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
    name,
    items,
    handleItemClick = () => {},
    error = {status: false, message: ''},
    disable = false,
    setValue,
    value
}) => {

    const [isOpen, setOpen] = useState(false);
  
    const [selectedItem, setSelectedItem] = useState<{id: number, label: string}>();

    const toggleDropdown = () => setOpen(!isOpen);

    const handleClick = (id:number, label: string) => {
        setSelectedItem(id === selectedItem?.id ? undefined : {id, label});
        setValue && setValue(label);
        handleItemClick(label, id);
        setOpen(false);
    }

    useEffect(()=> {
        if(value && value.length>0) {
            setSelectedItem(items.find(item => item.label === value));
            } else {
            setSelectedItem(undefined);
          setOpen(false);
        }
      }, []);


    return ( 
        <div className='dropdown'>
            <div className="tinytext-header dropdown-text">{name}</div>
            <div className={`${isOpen && "dropdown-content-active" } dropdown-content`} 
                style={{backgroundImage: `url(${STORAGE_ASSETS}/images/png/selectBackground.png`}}>
                <div className={`${isOpen && "dropdown-header-active"} dropdown-header`} onClick={()=> !disable ? toggleDropdown() : {}}>
                    <div className="tinytext dropdown-text">{selectedItem ? selectedItem.label : "Seleccionar"}</div>
                    {!isOpen ? <Down/> : <Up/>}
                </div>
                <div className={`dropdown-body ${isOpen && 'open'}`}>
                    {items.map((item, idx) => (
                    <div key={idx} className="tinytext dropdown-text dropdown-item" onClick={e => handleClick(item.id, item.label)} id={item.id.toString()}>
                        {item.label}
                    </div>
                    ))}
                </div>
            </div>

          {error.status && <div className="tinytext-error">{error.message}</div>}
        </div>
    ) 
}