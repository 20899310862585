import React, { useEffect, useState } from "react";

import './DetailSubscription.css';
import { LabelText } from "../LabelText/LabelText";
import { CardGradient } from "../CardGradient/CardGradient";
import { CreditNumber } from "../CreditNumber/CreditNumber";

interface DetailSubscriptionProps {
    title: string;
    description: string;
    dateSubscription: string;
    dateBilling: string;
    amountBilling: number;
    paymentMethod: string;
    plan: string;
    cardBrand: string;
    lastFourCardDigits: string;
    handleAdPaid: () => void;
    handleChangePlan: () => void;
}
export const DetailSubscription: React.FC<DetailSubscriptionProps> = ({
    title,
    description,
    dateSubscription,
    dateBilling,
    amountBilling,
    paymentMethod,
    plan,
    cardBrand,
    lastFourCardDigits,
    handleAdPaid,
    handleChangePlan
}) => {

  return (
    <CardGradient className="detailSubscription">
        <div className="detailSubscription-header">
            <div className="paragraph-subheader detailSubscription-text-white">{title}</div>
            <div className="paragraph-subheader detailSubscription-text-yellow">{description + " " + dateBilling}</div>
        </div>

        <hr className="detailSubscription-separator"/>

        <div className="detailSubscription-body">
            <LabelText text="Fecha de suscripción" value={dateSubscription} />
            <LabelText text="Fecha de facturación" value={dateBilling} />
            <LabelText text="Monto de facturación" value={(amountBilling.toFixed(2))+" USD"} />
            <LabelText text="Método de pago" value={paymentMethod} />
        </div> 

        <hr className="detailSubscription-separator"/>
        <div className="detailSubscription-creditNumber" >
            <CreditNumber
                brandCard={cardBrand}
                lastDigits={lastFourCardDigits}
            />
            <div className="tinytext-header detailSubscription-text-yellow" onClick={()=>handleAdPaid()}>Administrar forma de pago</div>
        </div>
        <div className="detailSubscription-creditNumber" >
            <LabelText text="Plan" value={plan} />
            <div className="tinytext-header detailSubscription-text-yellow" onClick={()=>handleChangePlan()}>Cambiar de plan</div>
        </div> 
    </CardGradient>
  )
}