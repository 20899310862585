import React, { useEffect, useRef, useState } from "react";

import { ItemCountry } from "../ItemCountry/ItemCountry";
import { TextField } from "../TextField/TextField";

import './PhoneCountry.css';
import { ErrorInterface } from "../../Interfaces/ErrorInterface";
import { GetCountryList } from "../../Service/Country";
import { CountryList } from "../CountryList/CountryList";
import { CountryConst } from "../../Const/CountryConst";


interface PhoneCountryProps {
    phone: string
    phoneCode: string;
    phoneFlag: string;
    handlePhoneCodeChange: (phoneCountry: string) => void;
    handleCountryChange: (country: string, alpha2Code: string) => void;
    handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disable?: boolean;
    error?: {
        phoneCode: ErrorInterface;
        phone: ErrorInterface;
    
    }
}

export const PhoneCountry: React.FC<PhoneCountryProps> = ({
    phone,
    phoneCode,
    phoneFlag,
    handlePhoneCodeChange,
    handleCountryChange,
    handlePhoneChange,
    disable = false,
    error= {
        phone: {
            status: false,
            message: ''
        },
        phoneCode: {
            status: false,
            message: ''
        }
    }
}) => {

    const [open, setOpen] = useState<boolean>(false);

    const [countrySelectedItem, setCountrySelectedItem] = useState<{phoneCode: string, phoneFlag: string}>();

    const handleClickItem = (country: string,imgFlag: string, alpha2Code: string, phoneCode? : string) => {
          handleCountryChange(imgFlag, alpha2Code); 
        handlePhoneCodeChange(phoneCode ? phoneCode : ""); 
        setCountrySelectedItem({
            phoneCode: phoneCode || "",
            phoneFlag: imgFlag  ,
        });
        setOpen(false);
    }

    useEffect(() => {
        if(phoneCode && phoneFlag && phoneFlag.length>0) {
            setCountrySelectedItem({
                phoneCode: phoneCode,
                phoneFlag: phoneFlag
            })
        }
    }, [phoneCode, phoneFlag]);

    return (
        <div className="phoneCountry">
             <div className="phoneCountry-textWhie tinytext-header">Nro de teléfono</div>
             <div className="phoneCountry-container ">
               {countrySelectedItem ? <div className="phoneCountry-country" onClick={()=> !disable && setOpen(true)}>
                    <label className="phoneCountry-country--prefix phoneCountry-text ">{countrySelectedItem?.phoneCode}</label>
                    <img className="phoneCountry-country--flag" src={countrySelectedItem.phoneFlag} alt="santa-zinos" />
                </div> : <div className="phoneCountry-id">
                    <label className="phoneCountry-text" onClick={() => setOpen((prev) => !prev)} >Prefijo </label>
                </div>}
                <input 
                    type="tel" 
                    className="phoneCountry-text phoneCountry-number"
                    placeholder="Teléfono"    
                    value={phone}
                    disabled={disable}
                    onChange={(e) => handlePhoneChange(e)}
                />
                {open && <div className="phoneCountry--list">
                    <CountryList 
                        name=""
                        showPhoneCode
                        onClick={handleClickItem}
                    />
                </div>}
            </div>
            {(error.phone.status || error.phoneCode.status) && <div className="tinytext-error">{error.phone.message || error.phoneCode.message}</div>}
        </div>
       
    )
}