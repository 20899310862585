import React, { useState } from "react";

import './RegisterMail.css';
import { LabelText } from "../LabelText/LabelText";
import { Button } from "../Button/Button";
import { CardBackground } from "../CardBackground/CardBackground";
import { TextField } from "../TextField/TextField";
import { ErrorInterface } from "../../Interfaces/ErrorInterface";
import { Validatefield } from "../../Utils/ValidateFields";

interface RegisterMailProps {
    email: string
    emailChange: (email: React.ChangeEvent<HTMLInputElement>) => void;
    password: string;
    passwordChange: (pass: React.ChangeEvent<HTMLInputElement>) => void;
    handleClick: () => void;
}

export const RegisterMail: React.FC<RegisterMailProps> = ({
    email,
    handleClick,
    password,
    passwordChange,
    emailChange
}) => {

    const [error, setError] = useState<{
        email: ErrorInterface,
        password: ErrorInterface
    }>({
        email: {
            status: false,
            message: ""
        },
        password: {
            status: false,
            message: ""
        }
    });

    const handleVallidateFields = () => {
        let emailError = {
            status: false,
            message: ""
        };
        let passwordError = {
            status: false,
            message: ""
        };

        if(Validatefield("email", email)){
            emailError = {
                status: true,
                message: "El campo correo es requerido"
            }
        }

        if(Validatefield("password", password)){
            passwordError = {
                status: true,
                message: "El campo contraseña es requerido"
            }
        }

        setError({
            email: emailError,
            password: passwordError
        });

        if(!emailError.status && !passwordError.status){
            
            handleClick();
        }
    }
    

    return (
        <div className="registerGmail">
            <CardBackground>
                <TextField
                    label="Correo"
                    type="email"
                    placeholder="Ingresa tu correo electrónico"
                    value={email}
                    onChange={(e)=> emailChange(e)}
                    error={error.email}
                />

                <TextField
                    label="Contraseña"
                    placeholder="Ingresa la contraseña"
                    value={password}
                    onChange={(e)=> passwordChange(e)}
                    type="password"
                    error={error.password}
                />

                <Button
                    text="Verificar"
                    color="orange"
                    onClick={()=>handleVallidateFields()}
                />
            </CardBackground>
        </div>
    )
}