import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { DB } from "../FirebaseConfig/FirebaseConfig";
import { RaffledProductInterface } from "../Interfaces/RaffledProductInterface";

export const GetReffledProducts = async (): Promise<RaffledProductInterface[]> => {
    const q = query(collection(DB, "RaffledProduct"), orderBy("createdAt", "desc"));
    const response = await getDocs(q);
    let data: RaffledProductInterface[] = [];
    response.forEach((doc) => {
        data.push(doc.data() as RaffledProductInterface);
    });
    return data;
}

export const GetLastFourReffledProducts = async (): Promise<RaffledProductInterface[]> => {
    const q = query(collection(DB, "RaffledProduct"), orderBy("createdAt", "desc"), limit(4));
    const response = await getDocs(q);
    let data: RaffledProductInterface[] = [];
    response.forEach((doc) => {
        data.push(doc.data() as RaffledProductInterface);
    });
    return data;
}