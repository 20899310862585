import React from "react";

import './ItemHoroscopeMin.css';

interface ItemHoroscopeMinProps {
    iconWhite: string;
    iconBlack: string;
    name: string;
    isActived?: boolean;
    onClick?: () => void;
}

export const ItemHoroscopeMin: React.FC<ItemHoroscopeMinProps> = ({
    iconWhite,
    iconBlack,
    name,
    isActived = false,
    onClick = () => {},
}) => {
    return (
        <div className={`itemHoroscopeMin-container ${isActived ? "itemHoroscopeMin-active" : ""}`} onClick={()=> onClick()}>
            <img style={{display: isActived ? "flex" : 'none'}} className="horoscopeSection--icon" src={iconBlack} alt={name} />
            <img style={{display: !isActived ? "flex" : 'none'}} className="horoscopeSection--icon" src={iconWhite} alt={name} />
            <div className={`${!isActived ? "itemHoroscopeMin-name tinytext" : "microtext-header"}`}>{name}</div>
        </div>
    )
}