import React from "react";

import './Cover.css';
import { ButtonGradient } from "../../../Components/ButtonGradient/ButtonGradient";
import { Gradient } from "../../../Components/Gradient/Gradient";
import { Animation } from "../../../Components/Animation/Animation";
import { CardGradient } from "../../../Components/CardGradient/CardGradient";

interface CoverProps {
    onClickButton: () => void;
}

export const Cover: React.FC<CoverProps> = ({
    onClickButton
}) => {
    
    return (
        <section id="cover" className="cover">
            <div className="cover-container">
                <div className="cover-img">
                    <img src="./images/gif/ZINOS-WEB-ANIMACIÓN-ZINOS_Y_BILLETES.gif"/>
                </div>
                <div className="cover-title mega-header" >Llegó la hora de darte todo lo que mereceZ</div>
                <div className="cover-buttons" onClick={()=>onClickButton()}>
                    <CardGradient>
                        <div className="small-bold cover-text-white">¿Estás listo para la verdadera experiencia Z?</div>
                        <div className="tinytext cover-text-decription cover-text-white">
                            Aquí estaré dándote tu horózcopo semanal para que puedas guiarte 
                            y tomar mejores decisiones.
                        </div>
                        {/* <div className="cover-btn smalltext-header">
                            ¡Quiero ser suscriptor!
                        </div> */}
                        <ButtonGradient
                            content="¡Quiero ser suscriptor!"
                            handleClick={()=>{}}
                        />
                    </CardGradient>
                </div>
            </div>
        </section>
    )
}