import React, { useEffect, useState } from "react";

// Importando estilo
import "./Menu.css";

interface MenuProps {
    img: string;
    text: string;
    id?: "contact" | "my-subscription" | "my-awards" | "my-receipts";
    active: boolean,
    onClick?: (text: "contact" | "my-subscription" | "my-awards" | "my-receipts") => void;
}

export const Menu: React.FC<MenuProps> = ({
    img,
    text,
    id = "contact",
    active,
    onClick = () => {},
}) => {
  
  return (
    <div className={active ? "menu-active" : "menu"} onClick={()=>onClick(id)}>
        <div className="menu-img-content">
          <img className="menu-img" src={img} />
        </div>
        <div className="smalltext menu-text">{text}</div>
    </div>
  );
};

