import { STORAGE_ASSETS } from "./BaseUrlConst"

export const MenuConst : {
    img: string,
    text: string,
    id: string
}[] = [
    {
        img: STORAGE_ASSETS+'/images/png/id_card.png',
        text: 'Mi perfil',
        id: "contact"
    },
    {
        img: STORAGE_ASSETS+'/images/png/subscription.png',
        text: 'Mi subscripción',
        id: "my-subscription"
    },
    {
        img: STORAGE_ASSETS+'/images/png/win.png',
        text: 'Mis premios',
        id: "my-awards"
    },
    {
        img: STORAGE_ASSETS+'/images/png/credit_card.png',
        text: 'Mis recibos',
        id: "my-receipts"
    },
    {
        img: STORAGE_ASSETS+'/images/svg/logout_white.svg',
        text: 'Cerrar sesión',
        id: "logout"
    }
]