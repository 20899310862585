import React from "react";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

type CheckWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const CheckWhite: React.FC<CheckWhiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/check-white.svg"} alt="" />
    </div>
  );
};