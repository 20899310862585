import { startOfMonth, endOfMonth, eachDayOfInterval, isThursday } from 'date-fns';

// method that returns an array of dates of the Thursday days of month and year are inputted
export const GetThursdaysOfMonth = (numMonth: number, year: number): Date[] => {
  // valitate month number
  if (numMonth < 0 || numMonth > 11) {
    throw new Error('numMonth debe estar entre 0 y 11');
  }

  const start = startOfMonth(new Date(year, numMonth));
  const end = endOfMonth(new Date(year, numMonth));

  // find all days of the month and filter only Thursdays
  const thursdays = eachDayOfInterval({ start, end }).filter(day => isThursday(day));

  return thursdays;
}