import React, { useState } from "react";

import './LoginSocial.css';
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { CardBackground } from "../CardBackground/CardBackground";
import { FacebookBlue } from "../../Icons/solid/FacebookBlue";
import { Gmail } from "../../Icons/solid/Gmail";

interface LoginSocialProps {
    handleClick: () => void;
    handleLoginWithGoogle: () => void;
    handleClickRegister: ()=>void;
}

export const LoginSocial: React.FC<LoginSocialProps> = ({
    handleClick,
    handleLoginWithGoogle,
    handleClickRegister
}) => {

    return (
        <div className="loginSocial">  
            <div className="tinytext loginSocial-text loginSocial-text-color">Elige una de las opciones para iniciar sesión.</div>             
            <div className="loginSocial-buttons">
                <ButtonIcon
                    content="Gmail"
                    color="white"
                    size="extra-small"
                    Icon={<Gmail />}
                    onClick={() => handleLoginWithGoogle()}
                />
                {/* <ButtonIcon
                    content="Facebook"
                    color="red"
                    size="extra-small"
                    Icon={<FacebookBlue />}
                    onClick={() => {}}
                /> */}

                <ButtonIcon
                    content="Correo electrónico"
                    color="white"
                    size="extra-small"
                    onClick={()=>handleClick()}
                />
            </div>
            <div className="loginSocial-footer">
                <div className="tinytext loginSocial-text-color">¿No tienes cuenta? </div>
                <div className="tinytext-header-1 loginSocial-text-orange" onClick={()=>{handleClickRegister()}}>Regístrate</div>
            </div>
        </div>
    )
}