import React, { useEffect, useState } from "react";

import "./Auth.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Register } from "../Register/Register";
import { Login } from "../Login/Login";
import { PlanInterface } from "../../Interfaces/PlansInterface";
import { toast } from "react-toastify";
import { GetPlanById } from "../../Service/Plan.service";
import { AuthSteps, AuthSteps_item } from "../../Const/AuthConst";
import { AUTH_LOGIN, AUTH_MODAL, AUTH_REGISTER } from "../../Const/BaseUrlConst";
import AuthService from "../../Service/Auth.service";


interface AuthProps {

}

export const Auth: React.FC<AuthProps> = ({
 
 }) => {

    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const [show, setShow] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [authStep, setAuthStep] = useState<string>(AUTH_LOGIN || AUTH_REGISTER);
    const [initialRegisterStep, setInitialRegisterStep] = useState<AuthSteps_item>("MAIN_REGISTER_STEP");

    const [planSelected, setPlanSelected] = useState<PlanInterface>();

    const redirectLanding = () => {
        setShow(false);
        navigation("/");
    }

    const handleCloseModal = () => {
        setShow(false);
        navigation("/");
    }

    const getPlanById = async () => {
        const paramPlanId = searchParams.get("planId");
        if(paramPlanId && paramPlanId.length>0){
            try {
                const response = await GetPlanById(paramPlanId);
                // console.log("🚀 ~ getPlanById ~ response:", response);
                setPlanSelected(response);
            } catch (error) {
                console.log("🚀 ~ getPlanById ~ error:", error)
                toast.error("Error al obtener el plan");
                redirectLanding();
            }
        } else {
            toast.warning("Selecciona un plan pana");
            redirectLanding();
        }
    }

    const logout = async () => {
        const response = await AuthService.LogOutUser();
        if(response) {
            window.location.href = window.location.origin+'/';
        }
    }

    useEffect(() => {
        setLoading(true);
        if(searchParams.get(AUTH_MODAL)){
            // gettting register step
            let numSetpRegister: number = searchParams.get("registerStep") ? parseInt(searchParams.get("registerStep") as string) : 1;
            // setting initial register step by register step
            setInitialRegisterStep(AuthSteps.find((item) => item.registerStep === numSetpRegister)?.step || "MAIN_REGISTER_STEP");
            
            // setting auth mode by search params 
            switch (searchParams.get(AUTH_MODAL)) {
                case AUTH_LOGIN:
                    setShow(true);
                    setAuthStep(AUTH_LOGIN);
                    break;
                case AUTH_REGISTER:
                    setShow(true);
                    getPlanById();
                    setAuthStep(AUTH_REGISTER);
                    break;
                default:
                    setShow(false);
                    break;
            }
            setLoading(false);  
        }
    }, [searchParams]);

    useEffect(()=>{
        show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    },[show]);

    return (
        <>{show && <div className="auth">
            {
                authStep === AUTH_REGISTER ? 
                planSelected && 
                <Register 
                    initialStep={initialRegisterStep}
                    closeModal={handleCloseModal}
                    planRegister={planSelected}
                />:
                authStep === AUTH_LOGIN && 
                <Login
                    closeModal={handleCloseModal}
                    logout={logout}
                />
            }
        </div>}</>
    );
}