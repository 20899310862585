
const { REACT_APP_FUNCTIONS_URL } = process.env;

export const LANDING = '/';

export const HOME = 'home';

export const MY_PROFILE = 'profile'

export const HOROSCOPE = 'horoscope';

export const REFFLED = 'reffledProducts';

export const MY_SUBSCRIPTION = 'my-subscription';

export const MY_AWARDS = 'my-awards';

export const MY_RECEIPTS = 'my-receipts';

export const TYC = "Terminos y Condiciones";

export const PRIVACY = "Politicas de Privacidad";

export const FAQ = "Preguntas Frecuentes";

export const REGULATION = "Regulaciones";

export const API_COUNTRY = 'https://restcountries.com/v3.1/';

export const API_CITY = 'https://countriesnow.space/api/v0.1/countries/cities';

export const CORS_PROXY = 'https://cors-proxy4.p.rapidapi.com/'

export const COUNTRY_FLAG_BASE = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/';

export const API_SUNAT_TIPO_CAMBIO = 'https://www.sunat.gob.pe/a/txt/tipoCambio.txt';

export const URL_CULQI_API = 'https://api.culqi.com/v2'

export const URL_CULQI_CLI = 'https://checkout.culqi.com/js/v4'

export const URL_CULQI_CUSTOM_CHECKOUT = 'https://js.culqi.com/checkout-js';

export const AUTH_MODAL = 'auth';

export const AUTH_LOGIN = 'login';

export const AUTH_REGISTER = 'register';

export const STORAGE = 'https://storage.googleapis.com/storage.santazinos.com'; 

export const STORAGE_ASSETS = STORAGE+"/public/assets";

export const FUNCTIONS_CREATE_SUBSCRIBER = REACT_APP_FUNCTIONS_URL+'/createSubscriber';