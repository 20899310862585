import React from "react";

import "./TextArea.css";

interface TextAreaProps {
    label: string; 
    value: string | number | undefined,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};


export const TextArea: React.FC<TextAreaProps> = ({
    label,
    value,
    onChange
}) => {

  return (
    <div className="textArea">
        <label className="tinytext textArea-textColor">{label}</label>
        <textarea 
            value={value} 
            onChange={()=>onChange} 
            cols={40} 
            rows={10} 
            className="textArea-container"
        />
    </div>
  );
};