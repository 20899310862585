import { btnsColorTypes } from "../@types/btnsColorTypes";
import { btnsRadiusTypes } from "../@types/btnsRadiusTypes";
import { TextSizeOptions } from "../@types/textSizeTypes";

export const selectBtnBackgroundColor = (options: btnsColorTypes) => {
  switch (options) {
    // case "black-75":
    //   return "var(--black-75)";
    // case "black-15":
    //   return "var(--black-15)";
    // case "black-05":
    //   return "var(--black-05)";
    // case "black-25":
    //   return "var(--black-25)";
    case "yellow":
      return "btn-yellow";
    case "orange":
      return "btn-orange";
    case "red":
      return "btn-red";
    // case "green-2":
    //   return "var(--green-2)";
    // case "white-50":
    //   return "var(--white-50)"; 
    // case "white-25":
    //   return "var(--white-25)"; 
    case "white":
      return "btn-white"
    // case "disabled":
    //   return "transparent";
    case "transparent":
      return "btn-transparent-blur";
  }
};


export const selectBtnStyleColor = (options: btnsColorTypes) => {
  switch (options) {
    // case "black-75":
    //   return "var(--black-75)";
    // case "black-15":
    //   return "var(--black-15)";
    // case "black-05":
    //   return "var(--black-05)";
    // case "black-25":
    //   return "var(--black-25)";
    case "yellow":
      return "va(--yellow)";
    case "orange":
      return "var(--orange)";
    case "red":
      return "var(--red)";
    // case "green-2":
    //   return "var(--green-2)";
    // case "white-50":
    //   return "var(--white-50)"; 
    // case "white-25":
    //   return "var(--white-25)"; 
    case "white":
      return "white"
    // case "disabled":
    //   return "transparent";
    case "transparent":
      return "btn-transparent-blur";
  }
};

export const selectBtnRadius = (options: btnsRadiusTypes) => {
  switch (options) {
    case "btn-radius-12":
      return "btn-radius-12";
    case "btn-radius-20":
      return "btn-radius-20";
    case "btn-radius-50":
      return "btn-radius-50";
  }
}

export const selectBtnSizeClass = (options: TextSizeOptions) => {
  switch (options) {
    case "large":
      return "btn-large";
    case "normal":
      return "btn-normal";
    case "small":
      return "btn-small";
    case "extra-small":
      return "btn-extra-small";
    default:
      return "btn-normal";
  }
};

export const selectBtnIconSizeClass = (options: TextSizeOptions) => {
  switch (options) {
    case "large":
      return "btnIcon-large";
    case "normal":
      return "btnIcon-normal";
    case "small":
      return "btnIcon-small";
    case "extra-small":
      return "btnIcon-extra-small";
    default:
      return "btnIcon-normal";
  }
};
