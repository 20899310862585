import React, { useEffect, useState } from "react";

import './ChangePlan.css';
import { CardGradient } from "../CardGradient/CardGradient";
import { GoBack } from "../GoBack/GoBack";
import { PlanInterface } from "../../Interfaces/PlansInterface";
import { GetActivePlans, GetPlanById } from "../../Service/Plan.service";
import { Item } from "../Item/Item";
import { Button } from "../Button/Button";
import { toast } from "react-toastify";
import { Loader } from "../Loader/Loader";
import { CreateScheduleOperation, GetScheduleOperation } from "../../Service/ScheduleOperation.service";
import { ScheduleOperation } from "../../Interfaces/ScheduleOperation";
import { useSelector } from "react-redux";
import { BeforeDay } from "../../Utils/BeforeDay";
import { SubscriptionInterface } from "../../Interfaces/SubscriptionInterface";
import { FormatDate } from "../../Utils/formatDate";
import { FormatISODate } from "../../Utils/FormatISODate";

interface ChangePlanProps {
    subscription: SubscriptionInterface;
    scheduleOperation?: ScheduleOperation;
    handleGoBack: () => void;

}
export const ChangePlan: React.FC<ChangePlanProps> = ({
    subscription,
    scheduleOperation,
    handleGoBack = () => {}
}) => {

    const {userDetail} = useSelector((state: any) => state.user);

    const [disabled, setDisabled] = useState<boolean>(false);


    const [currentPlan, setCurrentPlan] = useState<PlanInterface>();
    const [planSelected, setPlanSelected] = useState<PlanInterface>();
    const [loading, setLoading] = useState<boolean>(false);
    const [activeComponent, setActiveComponent] = useState('default');
    const [plans, setPlans] = useState<PlanInterface[]>([]);

    const [newPlanName, setNewPlanName] = useState<string>("");

    const getAllActivePlan = async () => {
        if(!loading) {
            try {
                setLoading(true);
                const response = await GetActivePlans();
                setPlans(response);
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ getAllActivePlan ~ error:", error);
                setLoading(false);
            }
        }
    
    }

    const getPlanId = async (id: string) => {
        if(id.length===0) return;

        try {
            setLoading(true);
            const response = await GetPlanById(id);
            setCurrentPlan(response);
            setPlanSelected(response);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getPlanId ~ error:", error);
            toast.error("Error al obtener el nombre del plan");
        }
      }


    const handleClick = (planSelected: PlanInterface) => {  
        if(disabled) return;

        setPlanSelected(planSelected);
    }

    const handleChangePlanClick = async () => {
        if(disabled) {
            toast.warn("Ya tienes un cambio de plan programado");
        } else {
            if(planSelected?.id === currentPlan?.id) {
                toast.warn("Ya tienes este plan activo");
            } else {
                setLoading(true);
                const scheduleOperationData: ScheduleOperation = {
                    subscriptionId: subscription?.id || "",
                    paymentMethodId: subscription?.paymentMethodId || "",
                    newPlanId: planSelected?.id || "",
                    dateExecution: FormatISODate(BeforeDay(subscription.billingDate.toDate())),
                    userId: userDetail.id,
                    type: "changePlan",
                    status: "pending",
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    executed: false,
                }
                console.log("🚀 ~ handleChangePlanClick ~ scheduleOperationData:", scheduleOperationData)
                try {
                    const response = await CreateScheduleOperation(scheduleOperationData);
                    if(response) {
                        toast.success("se ha programado el cambio de plan para el próximo ciclo de facturación: "+FormatDate(BeforeDay(subscription.billingDate.toDate())), {autoClose: 6500});
                        handleGoBack();
                    }
                    setLoading(false);
                } catch (error) {
                    console.log("🚀 ~ handleChangePlanClick ~ error:", error)
                    setLoading(false);
                    toast.error("Hubo un error al cambiar de plan, intentelo en unos minutos");
                }
            }
        }        
    }



    const getPlanById = async (id: string) => {
        try {
            const response = await GetPlanById(id);
            if(response) {
                setNewPlanName(response.title);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getAllActivePlan();
    }, []);

    useEffect(() => {
        getPlanId(subscription.planId);
        setDisabled(subscription.scheduleOperationId && subscription.scheduleOperationType === "changePlan" ? true : false);
    }, [subscription]);



    return (
        <CardGradient 
            className={"changePlan"}>
            <div className="changePlan-row" onClick={()=>handleGoBack()}>
                <GoBack/>
                <div className="paragraph-header changePlan-text-white">Cambiar de plan</div>
            </div>     
            {(disabled && scheduleOperation)&& <div className="paragraph-header changePlan-text-white">Cambio de plan programado a "{newPlanName}" para el {FormatDate(scheduleOperation.dateExecution)} </div>}       
            {!loading && planSelected ? <div className="changePlan-plan">
                {plans.length>0 && plans.map((p, idx) => {
                    return (
                        <CardGradient 
                            className={planSelected?.id === p.id ? "changePlan-gradient changePlan-active" :"changePlan-gradient" } 
                            key={idx}
                            handleClickCard={()=>handleClick(p)}>
                            <div className="changePlan-header_">
                                {/* {(p.isPopular || p.id === currentPlan?.id) && <CardGradient className="changePlan-popular">
                                    <div className="smalltext-header changePlan-text changePlan-text-white">
                                        {p.isPopular ? "Más popular" : (p.id === currentPlan?.id ? "Tu plan" : "")}
                                    </div>
                                </CardGradient>} */}
                                {p.isPopular ? <CardGradient className="changePlan-popular">
                                    <div className="smalltext-header changePlan-text changePlan-text-white">
                                        Más popular
                                    </div>
                                </CardGradient> : 
                                <div className="smalltext-header changePlan-text changePlan-text-black">
                                    {(p.id === currentPlan?.id ? "Tu plan actual" : "")}
                                </div>
                                }
                                <div className="small-bold changePlan-title plan-text">{p.title}</div>
                            </div>
                            <div className="changePlan-items">
                                {p && p.planItems && p.planItems.length>0 && p.planItems.map((planItem, idx) => {
                                    return (
                                        <Item
                                            key={idx}
                                            itemLabel={planItem}
                                        />
                                    )
                                })}
                            </div>
                            <div className="changePlan-column">
                                <div className="plan-price">
                                    <div className="big-header plan-text">{p.price}</div>
                                    <div className="smalltext plan-text">USD</div>
                                </div>
                                <div className="tinytext-header plan-text plan-period">{p.period}</div>
                            </div>
                        </CardGradient>
                    )
                })}
            </div> : 
            <div className="changePlan-loader">
                <Loader />
            </div>}

            <div className="changePlan-button">
                <Button
                    text="Cambiar de plan"
                    color="orange"
                    onClick={handleChangePlanClick}
                />
            </div>
        </CardGradient>
    )
}