import React from 'react';


/** Importando componentes */
import "./CardBackground.css";

interface CardBackgroundProps {
    children?: React.ReactNode;
    role?: string;
    className?: string;
};


export const CardBackground: React.FC<CardBackgroundProps> = ({
    children,
    className="",
    role
}) => {

  return (
    <div className={`${className}` + " card-background"} role={role}>
        {children}
    </div>
  ); 
};