import React, { useEffect, useRef, useState } from "react";

import './CreditCard.css';
import { CardBackground } from "../CardBackground/CardBackground";
import { CardDataInterface, CardDataInterfaceError } from "../../Interfaces/CardInterface";
import { AddingScript } from "../../Hooks/UseScript";
import { URL_CULQI_CUSTOM_CHECKOUT } from "../../Const/BaseUrlConst";
import { PlanInterface } from "../../Interfaces/PlansInterface";
import { toast } from "react-toastify";
import { Loader } from "../Loader/Loader";

interface CreditCardProps {
    price: number;
    email: string
    currency: string;
    onClick: (token: string) => void;
    buttonText?: string;
}

export const CreditCard: React.FC<CreditCardProps> = ({
    price,
    email,
    currency,
    onClick,
    buttonText = "Pagar"
}) => {

  const { 
      REACT_APP_CULQI_PUBLIC_KEY,
  } = process.env;

  const [loading, setLoading] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  let Culqi: any;

  const handleCulqiAction = () => {
    if (Culqi.token) {
      const tokenCulqi = Culqi.token.id;
      Culqi.close();
      toast.success("Tus datos de la tarjeta han sido validados correctamente");
      onClick(tokenCulqi)
      //En esta linea de codigo debemos enviar el "Culqi.token.id"
      //hacia tu servidor con Ajax
    } else if (Culqi.order) { // ¡Objeto Order creado exitosamente!
      const order = Culqi.order;
      Culqi.close();
      onClick(order);
    } else {
      // Mostramos JSON de objeto error en consola
      console.log('Error : ', Culqi.error);
      toast.error("Hubo un error al validar los datos de la tarjeta, intentelo más tarde");
    }
  }


  const initCulqi = async() => {
    try {
      setLoading(true);
      await AddingScript(URL_CULQI_CUSTOM_CHECKOUT);

      const tempWindow: any = window;

      setTimeout(() => {
        const settings = {
          title: 'Arkabia',
          currency: currency, // Este parámetro es requerido para realizar pagos yape
          amount: price*100, // Este parámetro es requerido para realizar pagos yape(80.00)
          order: '', // Este parámetro es requerido para realizar pagos con pagoEfectivo, billeteras y Cuotéalo
          // xculqirsaid: REACT_APP_CULQI_RSA_ID,
          // rsapublickey: REACT_APP_CULQI_RSA_PUBLIC_KEY,
        }

        const client = {
          email: email,
        }

        const paymentMethods = {// las opciones se ordenan según se configuren
          tarjeta: true,
          yape: false,
          billetera: false,
          bancaMovil: false,
          agente: false,
          cuotealo: false,	
        }

        const options = {
          lang: 'auto',
          installments: false, // Habilitar o deshabilitar el campo de cuotas
          modal: false,
          container: "#creditCard-culqi", // Opcional - Div donde quieres cargar el checkout
          paymentMethods: paymentMethods,
          paymentMethodsSort: Object.keys(paymentMethods), // las opciones se ordenan según se configuren en paymentMethods
        }

        const appearance = {
          theme: "default",
          hiddenCulqiLogo: false,
          hiddenBannerContent: true,
          hiddenBanner: true,
          hiddenToolBarAmount: false,
          menuType: "select", // sidebar / sliderTop / select
          buttonCardPayText: buttonText, // 
          logo: "", // 'http://www.childrensociety.ms/wp-content/uploads/2019/11/MCS-Logo-2019-no-text.jpg',
          defaultStyle: {
            bannerColor: "blue", // hexadecimal
            buttonBackground: "#000000BF", // hexadecimal
            menuColor: "pink", // hexadecimal
            linksColor: "#333333", // hexadecimal
            buttonTextColor: "#FFF", // hexadecimal
            priceColor: "#4F4F4F",
          },
          variables: {
            fontFamily: "'Faktum-Medium'",
            colorPrimaryText: "#1A1B25",
            colorText: "white",
            colorTextSecondary: "white",
            colorTextPlaceholder: "#727F96",
            colorIconTab: "white",
            colorLogo: "dark",
          },
          rules: {
            ".max-w-culqi-mobile": {
              maxWidth: "100%",
            },
            ".Culqi-Toolbar-Price": {
              fontStyle: "normal",
              fontSize: "0.875rem",
              lineHeight: "18px",
              color: "#333333",
            },
            ".Culqi-Main-Container": {
              color: "#333333",
            },
            ".relative input": {
              height: "52.5px",
              borderRadius: "24px",
            },
            "footer .Culqi-Button": {
              borderRadius: "24px",
              color: "FFFFFF",
              background: "#000000bf",
            }
          }
        };

        const config = {
          settings,
          client,
          options,
          appearance,
        };

        const culqiPublicKey= REACT_APP_CULQI_PUBLIC_KEY;

        Culqi = tempWindow.Culqi;

        Culqi = new tempWindow.CulqiCheckout(culqiPublicKey, config);

        setLoading(false);
       
      
        Culqi.culqi = handleCulqiAction;
        
      
        setTimeout(() => {  
          Culqi.open();
          scrollToCardSection();
        }, 500);
    
      }, 2000);
      } catch (error) {
      console.log("🚀 ~ file: PaymentCD.tsx:222 ~ initCulqi ~ error:", error)
      toast.error("Hubo un error con la pasarela de pago, intentelo más tarde")
    }
  }

  // method to scroll to id 
  const scrollToCardSection = () => {
    if(!loading && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  useEffect(() => {
    initCulqi();
  }, []);

  return (
    <form>
      <div className="creditCard">
        <CardBackground>
            {!loading ? <>
            <div ref={ref} id="creditCard-culqi" className="creditCard-body"></div>
            </> : <Loader/>}
        </CardBackground>
      </div>
    </form>
  )
}