import { httpsCallable } from "firebase/functions";
import { ChargeSessionInterface } from "../Interfaces/ChargeSessionInterface";
import { Functions } from "../FirebaseConfig/FirebaseConfig";

export const CreateNewCharge = async (data: ChargeSessionInterface) => {
    const createNewPayment = httpsCallable(Functions, 'createNewPayment');
    return createNewPayment(data)
        .then((response) => {
            console.log("🚀 ~ files: Quotation.service.ts ~ line 69 ~ .then ~ response", response)
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}