import React from "react";

import './Awards.css';
import { ProductAward } from "../ProductAward/ProductAward";
import { WinnerRaffleInterface } from "../../Interfaces/WinnerRaffleInterface";
import { CardGradient } from "../CardGradient/CardGradient";

interface AwardsProps {
    awards: WinnerRaffleInterface[];
}

export const Awards: React.FC<AwardsProps> = ({
    awards
}) => {
    return (
        <div className="awards-container">
            <CardGradient>
                <div className="paragraph-subheader awards-header awards-text">Mis Premios</div>
                {awards.length>0 ?  awards.map((a, idx) => (<ProductAward key={idx} award={a} /> )) : 
                <div className="paragraph-header awards-notFount"> No tienes ningun premio </div>}
                <div className="tinytext-header awards-footer awards-text">*Nos vamos a comunicar contigo. En caso de no respuesta, el premio tiene 
                    un plazo de reclamo de 1 año desde la fecha del sorteo. Posterior a este 
                    tiempo se perderá el derecho a obtener dicho premio.</div>
            </CardGradient>
        </div>
    ) 
}