import { collection, getDocs, query, where } from "firebase/firestore";
import { DB } from "../FirebaseConfig/FirebaseConfig";
import { WinnerRaffleInterface } from "../Interfaces/WinnerRaffleInterface";

export const GetWinnerRaffleByUser = async(userId: string): Promise<WinnerRaffleInterface[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const q = query(collection(DB, "WinnerRaffle"), where("userId", "==", userId));
            const response = await getDocs(q);
            let data:WinnerRaffleInterface[] = [];
            if(!response.empty) {
                data = response.docs.map(doc => ({id: doc.id, ...doc.data()} as WinnerRaffleInterface))
            }
            resolve(data);
            
        } catch (error) {
            console.log("🚀 ~ GetUserByFirebaseId ~ error:", error)
            reject(error);
        }
    });
}