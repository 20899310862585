import React, { useState } from "react";

import "./MyReceipts.css";

import { Receipts } from "../../../Components/Receipts/Receipts";
import { ReceiptMobile } from "../../../Components/ReceiptMobile/ReceiptMobile";
import { useSelector } from "react-redux";
import { ReceiptInterface } from "../../../Interfaces/ReceiptInterface";
import { GetReceiptsId } from "../../../Service/Receipts.service";

interface MyReceiptsProps {
}

export const MyReceipts: React.FC<MyReceiptsProps> = () => {
        
    const {userDetail} = useSelector((state: any) => state.user);

    const [loading, setLoading] = useState<boolean>(false);

    const [receipts, setReceipts] = React.useState<ReceiptInterface[]>([]);

    const getReceipts = async () => {
        if(loading) return;
        try {
            const response = await GetReceiptsId(userDetail.id);
            if(response) {
                console.log(response);
                setReceipts(response);
            }
        } catch (error) {
            console.log("🚀 ~ getReceipts ~ error", error);
        }
    }

    React.useEffect(() => {
        getReceipts();
    }, []);
    
    return (

        <div className="myReceipts-container">
            {/* <div className="paragraph-header myReceipts-text">Mis recibos</div> */}
            <Receipts receipts={receipts}/>
            <div className="myReceipts-content">
                {receipts.map((d, idx) => {
                    return (
                      <ReceiptMobile key={idx} receipt={d}/>
                    )
                })}
            </div>
        </div>
    )
}