import React from "react";

import "./Button.css";
import { selectBtnBackgroundColor, selectBtnRadius } from "../../Utils/selectButtonsStyles";
import { btnsRadiusTypes } from "../../@types/btnsRadiusTypes";
import { btnsColorTypes } from "../../@types/btnsColorTypes";

interface ButtonProps {
    text: string;
    onClick: () => void;
    className?: string;
    color?:  btnsColorTypes;
    radius?: btnsRadiusTypes;
    highlightedText?: string;
    positionHighlightedText?: "left" | "right";
}

export const Button: React.FC<ButtonProps> = ({
    text,
    onClick,
    className="",
    color = "transparent",
    radius = "btn-radius-12",
    highlightedText,
    positionHighlightedText = "left",
}) => {
  return (
    <div className={`button-container ${className}
        ${selectBtnBackgroundColor(color)}
        ${selectBtnRadius(radius)}
        paragraph-subheader
    `} onClick={()=>onClick()}>
      {highlightedText && positionHighlightedText === "left" && <div className="paragraph-subheader button-whiteText">{highlightedText}</div>}
      {text}
      {highlightedText && positionHighlightedText === "right" && <div className="paragraph-subheader button-whiteText">{highlightedText}</div>}
    </div>
  );
};