import React, { useState } from "react";

import "./CarouselCard.css";
import { SignatureWhite } from "../../Icons/solid/SignaturaWhite";
import { HeartWhite } from "../../Icons/solid/HeartWhite";
import { horoscopeReadingTypes } from "../../@types/horoscopeReadingTypes";
import { HeartRed } from "../../Icons/solid/HeartRed";
import { useSelector } from "react-redux";
import { LikeHoroscopeFunctions,} from "../../Interfaces/LikeHoroscopeInterface";
import { LikeHoroscopeReading } from "../../Service/HoroscopeReading.service";
import { toast } from "react-toastify";

interface CarouselCardProps {
    icon: React.ReactNode;
    id: string;
    title: string; // title of the horoscope
    description: string; // description of the horoscope
    typeReading: horoscopeReadingTypes;
    numLikes: number; // number of likes of the horoscope
    createdString: string; // date of creation of the horoscope
    showMore: boolean;
    onClickShowMore?: () => void;
    isLiked: boolean;
}

export const CarouselCard: React.FC<CarouselCardProps> = ({
    icon,
    id,
    title,
    description,
    typeReading,
    numLikes,
    createdString,
    showMore = false,
    isLiked,
    onClickShowMore = ()=>{},
 
}) => {

    const {userDetail} = useSelector((state: any) => state.user);
    const [loading, setLoading] = useState<boolean>(false);

     // method to handle the click of the like button
     const handleLikeClick = async () => {
        if(userDetail && userDetail.id && !loading) { 
          try {
            setLoading(true);
            const likeHoroscope: LikeHoroscopeFunctions = {
                userId: userDetail.id,
                horoscopeReadingId: id,
                like: !isLiked
            };
            const response = await LikeHoroscopeReading(likeHoroscope);
            setLoading(false);
          } catch (error) {
            console.log("🚀 ~ handleLikeClick ~ error:", error)
            toast.error("Error al dar like al horóscopo");
            setLoading(false);
          }
        }
     }
 

    return (
        <div className="carouselCard-container">
            <div className="carouselCard-header">
                {title.length>0 && <div className="carouselCard-title smalltext-header">
                    {icon}
                    <div className="smalltext-header carouselCard-text">{title}</div>
                </div>}
                <div className="tinytext carouselCard-text">{createdString}</div>
            </div>
            {typeReading === "reading" ?  <div className="smalltext carouselCard-content carouselCard-text ">
                {description} {showMore && <span onClick={()=>onClickShowMore()} className="smalltext carouselCard-showMore"> Ver más... </span>}
            </div> :
            <video className="carouselCard-video" width={370} controls>
                <source src={description} type="video/mp4" />
            </video> 
            //   <div className="carouselCard-video">
            //       <iframe src={description}
            //       height={451}
            //         frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
              
            //         />
            //   </div>
            }
            <div className="carouselCard-footer">
                <SignatureWhite />
                <div className="carouselCard-footer--likes paragraph carouselCard-text" onClick={()=>handleLikeClick()}>
                    {numLikes}
                    {isLiked ? <HeartRed /> : <HeartWhite />}
                </div>
            </div>
        </div>
    )
}