import React from "react";

import './TableBody.css';
import { Download } from "../../Icons/solid/Download";
import { ReceiptInterface } from "../../Interfaces/ReceiptInterface";
import { CardGradient } from "../CardGradient/CardGradient";
import { FormatDate } from "../../Utils/formatDate";
import { formatReceiptNumber } from "../../Utils/FormatNumber";

interface TableBodyProps {
    receipt: ReceiptInterface;
}

export const TableBody: React.FC<TableBodyProps> = ({
    receipt,
}) => {

    return (
        <CardGradient className="tableBody-container">
                {/* <div key={idx} className={`tinytext-header ${r.img ? "tableBody-img" : "tableBody-text"}`}>
                 {r.img ? <Download/> : r.item}
                </div> */}
            <div className="tableBody">
                <div className="tableBody-text">{receipt.planName}</div>
                <div className="tableBody-text">{formatReceiptNumber(receipt.documentNumber)}</div>
                <div className="tableBody-text">{receipt.amount}</div>
                <div className="tableBody-text">{receipt.dateRecept}</div>
                <div className="tableBody-text">{receipt.price + " USD"}</div>
                <div className="tableBody-text">Culqi</div>
                <div className="tableBody-text">{receipt.typeReceipt}</div>
                <a className="tableBody-img" href={receipt.documentUrl} target="_blank"><Download/></a>
            </div>
        </CardGradient>
    ) 
}