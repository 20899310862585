import React from "react";

import './ReceiptMobile.css';
import { CardBackground } from "../CardBackground/CardBackground";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Download } from "../../Icons/solid/Download";
import { ReceiptConst } from "../../Const/ReceiptConst";
import { FormatDate } from "../../Utils/formatDate";
import { ReceiptInterface } from "../../Interfaces/ReceiptInterface";
import { formatReceiptNumber } from "../../Utils/FormatNumber";

interface ReceiptMobileProps {
    receipt: ReceiptInterface;
}

export const ReceiptMobile: React.FC<ReceiptMobileProps> = ({
    receipt
}) => {
    return (
        <div className="receipt-mobile">
                <CardBackground className="receipt-mobile-content">
                    <div className="smalltext-header receipt-mobile-text_">{receipt.planName}</div>
                    <div className="receipt-mobile-header">                        
                        <div className="receipt-mobile-header-detail">
                            <div className="tinytext-header receipt-mobile-text">Nº orden:</div>
                            <div className="tinytext-header receipt-mobile-text">{formatReceiptNumber(receipt.documentNumber)}</div>
                        </div>

                        <div className="receipt-mobile-header-detail">
                            <div className="tinytext-header receipt-mobile-text">Fecha:</div>
                            <div className="tinytext-header receipt-mobile-text">{receipt.dateRecept}</div>
                        </div>
                    </div>  

                    <div className="receipt-mobile-body">
                        <div className="receipt-mobile-body-detail-row">
                            <div className="tinytext-header receipt-mobile-text">Cantidad:</div>
                            <div className="tinytext receipt-mobile-text_">{receipt.amount}</div>
                        </div>

                        <div className="receipt-mobile-body-detail-row">
                            <div className="tinytext-header receipt-mobile-text">Monto:</div>
                            <div className="tinytext receipt-mobile-text_">{receipt.price + " USD"}</div>
                        </div>

                        <div className="receipt-mobile-body-detail-row">
                            <div className="tinytext-header receipt-mobile-text">Pago:</div>
                            <div className="tinytext receipt-mobile-text_">Culqi</div>
                        </div>
                        <div className="receipt-mobile-body-detail-row">
                            <div className="tinytext-header receipt-mobile-text">Recibo:</div>
                            <div className="tinytext receipt-mobile-text_">{receipt.typeReceipt}</div>
                        </div>
                    </div>

                    <div className="receipt-mobile-footer">
                        {/* <ButtonIcon
                            content="Descargar"
                            color="yellow"
                            size="extra-small"
                            Icon={<Download />}
                            onClick={() => {}}
                        /> */}
                        <a className="tinytext-header receipt-mobile-a" href={receipt.documentUrl} target="_blank"><Download/> Descargar</a>
                    </div>

                    

                    {/* <div className="receipt-mobile-body">
                        <div className="receipt-mobile-body-concept">
                            <div className="receipt-mobile-body-detail-row">
                                <div className="tinytext-header receipt-mobile-text">Cantidad:</div>
                                <div className="tinytext receipt-mobile-text_">{r.amount}</div>
                            </div>
                            <div className="receipt-mobile-body-detail-row">
                                <div className="tinytext-header receipt-mobile-text">Monto:</div>
                                <div className="tinytext receipt-mobile-text_">{r.paySubscriptionId}</div>
                            </div>
                        </div>

                        <div className="receipt-mobile-body-concept">
                            <div className="receipt-mobile-body-detail-row">
                                <div className="tinytext-header receipt-mobile-text">Pago:</div>
                                <div className="tinytext receipt-mobile-text_">{r.paymentMethodId}</div>
                            </div>
                            <div className="receipt-mobile-body-detail-row">
                                <div className="tinytext-header receipt-mobile-text">Recibo:</div>
                                <div className="tinytext receipt-mobile-text_">{r.typeReceipt}</div>
                            </div>
                        </div>

                       
                    </div> */}
                </CardBackground>
        </div>
    ) 
}