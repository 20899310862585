import React from "react";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

type FireIconProps = React.HTMLAttributes<HTMLDivElement> & {};

export const FireIcon: React.FC<FireIconProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/png/fire_icon.png"} alt="" />
    </div>
  );
};