import React, { useEffect, useState } from "react";

import './Profile.css';

import { useNavigate, useParams } from "react-router-dom";
import { CardUser } from "../../Components/CardUser/CardUser";
import { ContactProfile } from "./Conctact/ContactProfile";
import { Subscription } from "./Subscription/Subscription";
import { MyAwards } from "./MyAwards/MyAwards";
import { MyReceipts } from "./MyReceipts/MyReceipts";
import { useSelector } from "react-redux";
import AuthService from "../../Service/Auth.service";
import { Loader } from "../../Components/Loader/Loader";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

export const Profile: React.FC<{}> = () => {

    const {userDetail} = useSelector((state: any) => state.user);
    const {planDetail} = useSelector((state: any) => state.plan);


    let params = useParams();

    let navigation = useNavigate();
   
    // useState to manage the loading of the page
    const [loading, setLoading] = useState<boolean>(false); 
    // useState to manage the active section
    const [sectionActive, setSectionActive] = useState<"contact" | "my-subscription" | "my-awards" | "my-receipts">("contact");

    // Function to get the section from the url
    const getItemSection = () => {
        if(params.section === sectionActive) return;

        if(params && params.section && (params.section === "contact" || params.section === "my-subscription" || params.section === "my-awards" || params.section === "my-receipts")){
            setSectionActive(params.section);
        }
    }

    // method to handle the click on the menu
    const handleItemClick = async (item: "contact" | "my-subscription" | "my-awards" | "my-receipts" | "logout") => {
        if(item === "logout") {
            setLoading(true);
            try {  
                const response = await AuthService.LogOutUser();
                if(response) {
                    window.location.href = window.location.origin+'/';
                }
            } catch (error) {
                console.log("🚀 ~ handleItemClick ~ error:", error);
                setLoading(false);
            }
        } else {
            navigation(`/profile/${item}`);
            setSectionActive(item);
        }
     
    }


    useEffect(() => {
        getItemSection();
    }, [params.section]);

    return (
        <div className="profile-container" style={{backgroundImage: `url(${STORAGE_ASSETS}/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TA.png`}}>
            {userDetail && userDetail.id && 
            <div className="profile-left">
                <CardUser
                title="Bienvenid@ "
                photoUrl={userDetail.photoUrl}
                nameUser={userDetail.name+" "+userDetail.fathersLastName}
                planName={"Suscriptor "+planDetail.title}
                sign={userDetail.zodiacSignName}
                menu
                item={sectionActive}
                onItemClick={handleItemClick}  />  
            </div>
            }
            <div className="profile-right">
            {!loading ? 
            <div className="profile-content">
                {
                sectionActive === "contact" ? <ContactProfile/> : 
                sectionActive === "my-subscription" ? <Subscription /> :
                sectionActive === "my-awards" ? <MyAwards /> :
                sectionActive === "my-receipts" ? <MyReceipts /> :
                    <ContactProfile/>
                }
            </div> :
            <div className="profile-container-loader">
                <Loader/>
            </div>
            }
            </div>  
            
        </div>
    )
}