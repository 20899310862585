import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { DB, Functions } from "../FirebaseConfig/FirebaseConfig";
import { HoroscopeInterface } from "../Interfaces/HoroscopeInterface";
import { httpsCallable } from "firebase/functions";
import { LikeHoroscopeFunctions } from "../Interfaces/LikeHoroscopeInterface";

export const GetHoroscopeReaingByZodiacSign = async (zodiacSignId: string): Promise<HoroscopeInterface[]> => {
    const q = query(collection(DB, "HoroscopeReading"), where("zodiacSignId", "==", zodiacSignId), orderBy("createdAt", "desc"));
    const response = await getDocs(q);
    let data: HoroscopeInterface[] = [];
    response.forEach((doc) => {
        data.push({id: doc.id, ...doc.data()} as HoroscopeInterface);
    });
    return data;
}

export const GetHoroscopeReaingByZodiacSignReading= async (zodiacSignId: string): Promise<HoroscopeInterface[]> => {
    const q = query(collection(DB, "HoroscopeReading"), where("zodiacSignId", "==", zodiacSignId), where("typeReading", "==", "reading"), orderBy("createdAt", "desc"));
    const response = await getDocs(q);
    let data: HoroscopeInterface[] = [];
    response.forEach((doc) => {
        data.push({id: doc.id, ...doc.data()} as HoroscopeInterface);
    });
    return data;
}

export const LikeHoroscopeReading = async (data: LikeHoroscopeFunctions) => {
    const likeHoroscopeReading = httpsCallable(Functions, 'likeHoroscopeReading');
    return likeHoroscopeReading(data)
        .then((response: any) => {
            if(response.data.object === 'error'){ 
                throw response.data;
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            console.log("🚀 ~ deletePaymentMethod ~ error:", error)
            throw error;
        });
};

// get in real time horoscope reading by zodiac sign
export const GetHoroscopeReadingRealTime = async(zodiacSignId:string, cb: (e: HoroscopeInterface[])=>void = ()=> {}) => {
    const rqc = query(collection(DB, "HoroscopeReading"), where("zodiacSignId", "==", zodiacSignId), orderBy("createdAt", "desc"));
    onSnapshot(rqc, (querySnapshot) => {
        let data: HoroscopeInterface[] = [];
        querySnapshot.docs.filter((doc) => {
            if(doc.data().status ) {
                data.push({id: doc.id, ...doc.data()} as HoroscopeInterface);
            }
        });
        cb(data);
    });
};
