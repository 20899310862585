import React, { useEffect, useState } from "react";

// Importando estilo
import "./HeaderSection.css";

interface HeaderSectionProps {
    title: string;
    subTitle: string;
}

export const HeaderSection: React.FC<HeaderSectionProps> = ({
    title,
    subTitle
}) => {

    return (
        <div className="headerSection">
            <div className="mega-header headerSection-text headerSection-text-title">{title}</div>
            <div className="small-subheader headerSection-text headerSection-text-subTitle">{subTitle}</div>
        </div>
    );
};
