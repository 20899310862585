import React, { useEffect, useState } from "react";

// Importando estilo
import "./Footer.css";
import { Logo } from "../../Icons/solid/Logo";
import { Facebook } from "../../Icons/solid/Facebook";
import { Instagram } from "../../Icons/outline/Instagram";
import { FacebookWhite } from "../../Icons/solid/FacebookWhite";
import { InstagramWhite } from "../../Icons/outline/InstagramWhite";
import { LogoWhite } from "../../Icons/solid/LogoWhite";
import { EmailBlack } from "../../Icons/solid/EmailBlack";
import { EmailWhite } from "../../Icons/solid/EmailWhite";
import { ModalTerms } from "../ModalTerms/ModalTerms";
import { ModalPrivacy } from "../ModalPrivacy/ModalPrivacy";
import { ModalQuestions } from "../ModalQuestions/ModalQuestions";
import { ModalReglament } from "../ModalReglament/ModalReglament";
import { ModalSoporte } from "../ModalSoporte/ModalSoporte";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

interface FooterProps {
    isLogin?: boolean;
    // handleTerms: () => void;
    // handlePrivacy: ()=>void;
    // handleQuestions: ()=>void;
    // handleReglament: ()=>void;
    // handleSoporte: ()=>void;
}

export const Footer: React.FC<FooterProps> = ({
    isLogin = false,
    // handleTerms,
    // handlePrivacy,
    // handleQuestions,
    // handleReglament,
    // handleSoporte
}) => {

    const [showTerms, setShowTerms] = useState<boolean>(false);
    const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
    const [showQuestions, setShowQuestions] = useState<boolean>(false);
    const [showReglament, setShowReglament] = useState<boolean>(false);
    const [showSoporte, setShowSoporte] = useState<boolean>(false);

    const handleInstagram = () => {
        window.open('https://www.instagram.com/santazinos/')
    }

    const handleFacebook = () => {
        window.open('https://www.facebook.com/SantaZinos')
    }

    const handleTiktok = () => {
        window.open('https://www.tiktok.com/@santazinos')
    }

    const handleTerms = () => {
        setShowTerms(true);
    }

    const handlePrivacy = () => {
        setShowPrivacy(true);
    }

    const handleQuestions = () => {
        setShowQuestions(true);
    }

    const handleReglament = () => {
        setShowReglament(true);
    }

    const handleSoporte = () => {
        setShowSoporte(true);
    }
  
  return (
    <>
        <div className={isLogin ? "footer-login" : "footer"} 
            // style={{backgroundImage:`url(${STORAGE_ASSETS}/images/png/fondo2.png`}}
            >
        <div className={"footer-content_"} role='footer'>
            {/* {zinos && 
            <div className="footer-content-zinos">
                <div className="footer-zinos">
                    <div className="medium-header footer-text-zinos">¿Quién es Zinos?</div>
                    <div className="footer-zinos-detail">
                        <ZinosFooter/>
                        <Card 
                            description="Santa Zinos desde que empezó a publicar sus horóscopos, fue haciéndose de una legión de ﬁeles seguidores debido tanto a sus acertadas predicciones como por su lenguaje directo que motiva a sus lectores afrontar sus problemas."
                            description2="Zinos, es la 'bruja' con más de tres millones de seguidores en Instagram."
                        />
                    </div>
                </div>
            </div>} */}

            <hr/>

            <div className="footer-content">
                <div className="footer-first">
                    {isLogin ? <LogoWhite/> : <>{window.innerWidth<769 ? <Logo/> : <LogoWhite/>}</>}
                    <div className="paragraph footer-text">© 2024 LA ZETA S.A.C. <br/>Todos los derechos reservados.</div>
                    <div className="footer-social">
                       {isLogin ? <InstagramWhite className="footer-instagram" onClick={()=>handleInstagram()}/> : 
                       <>{window.innerWidth<769 ?<Instagram className="footer-instagram" onClick={()=>handleInstagram()}/>: <InstagramWhite className="footer-instagram" onClick={()=>handleInstagram()}/>}</>}
                       {isLogin ? <FacebookWhite className="footer-facebook" onClick={()=>handleFacebook()}/> : 
                       <>{window.innerWidth<769 ?<Facebook className="footer-facebook" onClick={()=>handleFacebook()}/> : <FacebookWhite className="footer-facebook" onClick={()=>handleFacebook()}/>}</>}
                       {/* {isLogin ? <TiktokWhite className="footer-tiktok" onClick={()=>handleTiktok()}/> : 
                       <>{window.innerWidth<769 ?<Tiktok className="footer-tiktok" onClick={()=>handleTiktok()}/>: <TiktokWhite className="footer-tiktok" onClick={()=>handleTiktok()}/>}</>} */}
                    </div>
                </div>
                <div className="footer-tablet">
                    <div className="footer-second">
                        <div className="smalltext-header footer-text">Acerca de</div>
                        <div className="smalltext footer-text footer-link" onClick={()=>handleTerms()}>Términos y condiciones</div>
                        <div className="smalltext footer-text footer-link" onClick={()=>handlePrivacy()}>Política de privacidad</div>
                        {/* <div className="smalltext footer-text footer-link" onClick={()=>handleQuestions()}>Preguntas frecuentes</div> */}
                        <div className="smalltext footer-text footer-link" onClick={()=>handleReglament()}>Reglamento</div>
                        <div className="smalltext footer-text footer-link"><a style={{textDecoration: "none", color: "unset"}} href="https://forms.gle/FjzeLo4Z5JtY3Jka9" target="_blank" >Libro de Reclamaciones</a></div>
                    </div>
                    <div className="footer-third">
                        <div className="smalltext-header footer-text">Contáctanos</div>
                        <div className="smalltext footer-text">Envíanos un correo electrónico a:</div>
                        <div className="footer-row">
                            {window.innerWidth<769 ? <EmailBlack/> : <EmailWhite/>}
                            <div className="smalltext footer-text">admin@santazinos.com</div>
                        </div>
                        <div style={{width: '170px'}} className="smalltext footer-text">
                            Horario de atención <br/>
                            Lun a Vie <br/>
                            9:00 A.M. - 6 P.M.
                        </div>
                    </div>
                    <div className="footer-four">
                        <div className="smalltext-header footer-text">Libro de Reclamaciones</div>
                        <div className="smalltext footer-text">20604948398</div>
                        <div className="smalltext footer-text">AZTRO X S.A.C.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ModalTerms 
        showTerms = {showTerms}
        setShowTerms = {setShowTerms}
    />
    <ModalPrivacy
        showPrivacy = {showPrivacy}
        setShowPrivacy = {setShowPrivacy}
    />
    {/* <ModalQuestions
        showQuestions = {showQuestions}
        setShowQuestions = {setShowQuestions}
    /> */}
    <ModalReglament
        showReglament = {showReglament}
        setShowReglament = {setShowReglament}
    />
    <ModalSoporte
        showSoporte = {showSoporte}
        setShowSoporte = {setShowSoporte}
    />
    </>
  );
};