import React, { useEffect, useState } from "react";

// Importando estilo
import "./WhatsappSection.css";
import { EmailSection } from "../EmailSection/EmailSection";
import { UserInterface } from "../../Interfaces/UserInterface";
import { COUNTRY_FLAG_BASE, STORAGE_ASSETS } from "../../Const/BaseUrlConst";
import { toast } from "react-toastify";
import { CreateSubscriberManychat } from "../../Service/Functions.service";
import { useSelector } from "react-redux";

interface WhatsappSectionProps {
}

export const WhatsappSection: React.FC<WhatsappSectionProps> = ({

}) => {

    const {zodiacSign} = useSelector((state: any) => state.zodiacSignSlice);


    const [phoneCode, setPhoneCode] = useState<string>("51");
    const [phoneFlag, setPhoneFlag] = useState<string>(COUNTRY_FLAG_BASE + "pe.svg");
    const [phone, setPhone] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    
    // handle change birthda
    const handleFieldChange = (value: string, key: "phone" | "phoneCode" | "phoneFlag") => {
        if (key === "phone") {
            setPhone(value);
        } else if (key === "phoneCode") {
            setPhoneCode(value);
        } else if (key === "phoneFlag") {
            setPhoneFlag(value);
        }
    };

    const clearFields = () => {
        setPhone("");
        setPhoneCode("51");
        setPhoneFlag(COUNTRY_FLAG_BASE + "pe.svg");
    }


    const handleSendClick = async() => {
        try {
            setLoading(true);
            // console.log("🚀 ~ handleSendClick ~ phone", phone, zodiacSign)
            const response = await CreateSubscriberManychat(phoneCode+phone, zodiacSign);
            console.log("🚀 ~ handleSendClick ~ response:", response);
            clearFields();
            toast.success("Te redirigiremos a Whatsapp para que puedas recibir tu horóscopo");
            // open externar link 
            setLoading(false);
            setTimeout(() => {
                const WhatsappUrl = "https://wa.me/18472782954?text=Dame%20mi%20hor%C3%B3zcopo%20de%20prueba";
                const newWindow = window.open(WhatsappUrl, "_blank", "noopener,noreferrer"); 
                if(newWindow) newWindow.opener = null;
            }, 500);
        } catch (error) {
            console.log("🚀 ~ handleSendClick ~ error:", error);
            toast.error("Ocurrió un error al enviar el mensaje, por favor intenta nuevamente");
            setLoading(false);
        }
    };

    return (
        <section id="whatsappSection" className="whatsappSection">
            <div className="whatsappSection-content">
                <div className="whatsappSection-first">
                    <div className="whatsappSection-first-header">
                        <div className="medium-header whatsappSection-text">Recibe tu horóZcopo exclusivo por Whatsapp</div>
                        <div className="tinytext-header whatsappSection-text">¿No me crees? Déjame enviarte una prueba gratis.</div>
                    </div>
                    <EmailSection
                        onClick={handleSendClick}
                        phone={phone}
                        phoneCode={phoneCode}
                        phoneFlag={phoneFlag}
                        handleInputchange={handleFieldChange}
                        loading={loading}
                    />
                </div>
                <div className="whatsappSection-second">
                    <img className="whatsappSection-second-img" src={STORAGE_ASSETS+"/images/png/celularWhatsapp.png"}/>
                </div>
            </div>
        </section>
    );
};
