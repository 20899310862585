import { DB, Functions } from "../FirebaseConfig/FirebaseConfig";

import { collection, query, where, getDocs, orderBy, getDoc, doc, addDoc, updateDoc } from "firebase/firestore"; 

// importando interfaces

import { httpsCallable } from "firebase/functions";
import { UserInterface } from "../Interfaces/UserInterface";

const CreateUser = async(user: UserInterface) => {
    try {
        const response:any = await addDoc(collection(DB, "User"), user);
        // console.log("create user", response);  
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
    }
}

const UpdateUser = async (id: string, user: any) => {
    try {
        if(user.id) {
            const requestRef = doc(DB, 'User', id);
            const response = await updateDoc(requestRef, {...user});

            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

const GetUserByEmail = async(email: string) => {
    try {
        const q = query(collection(DB, "User"), where("email", "==", email));
        const response = await getDocs(q);
        let data:any[] = [];
        if(!response.empty) {
            data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
        }
        return data.length>0? data[0]: false;
        
    } catch (error) {
        
    }
}

const GetUserByFirebaseId = async(userUID: string) => {
    try {
        const q = query(collection(DB, "User"), where("uid", "==", userUID));
        const response = await getDocs(q);
        let data:any[] = [];
        if(!response.empty) {
            data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
        }
        return data.length>0? data[0]: false;
        
    } catch (error) {
        console.log("🚀 ~ GetUserByFirebaseId ~ error:", error)
        return error;
    }
}

const GetUserById = async(userId: string) => {
    const response = await getDoc(doc(DB, "User", userId)); // trae el documento segun "id" de la coleccion de firestore
    // console.log(response)
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()})
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

const GetUserByEmailOrName = async(value: string) => {
    try {
        const q = query(collection(DB, "User"), where('userType', 'array-contains', 'user'));
        const response = await getDocs(q);
        // console.log("🚀 ~ file: User.service.ts:80 ~ GetUserByEmailOrName ~ response:", response)
        let data:any[] = [];
        let filter: any[] = []
        if(!response.empty) {
            data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
        }

        filter = data.filter((d) => d.email.toLowerCase().includes(value.toLowerCase()) || d.name.toLowerCase().includes(value.toLowerCase()) || d.lastName.toLowerCase().includes(value.toLowerCase()) )

        return filter;
        
    } catch (error) {
        console.log("🚀 ~ file: User.service.ts:86 ~ GetUserByEmailOrName ~ error:", error)
        return error;
    }
}

const GetUserList = async () => {
    const response = await getDocs(collection(DB, "User"));

    let data:any[] = [];

    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data
}



export {
    CreateUser,
    GetUserByFirebaseId,
    GetUserById,
    GetUserByEmail,
    UpdateUser,
    GetUserByEmailOrName,
    GetUserList
}