import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import { DB } from "../FirebaseConfig/FirebaseConfig";

import { ZodiacSignInterface } from "../Interfaces/ZodiacSignInterface";

export const GetAllZodiacalSign = async (): Promise<ZodiacSignInterface[]> => {
    const q = query(collection(DB, "ZodiacSign"), orderBy("numOrder", "asc"))
    const response = await getDocs(q);
    let data: ZodiacSignInterface[] = [];
    response.forEach((doc) => {
        data.push(doc.data() as ZodiacSignInterface);
    });
    return data;
}