import React, { useEffect, useState } from "react";

// Importando estilo
import "./CardUser.css";
import { User } from "../User/User";
import { MenuConst } from "../../Const/MenuConst";
import { Menu } from "../Menu/Menu";
import { CardGradient } from "../CardGradient/CardGradient";

interface CardUserProps {
    photoUrl: string;
    nameUser: string;
    title: string;
    planName: string;
    sign: string;
    menu?: boolean; //  activa los menus del perfil del usuario
    item?: string;
    onItemClick?: (item: "contact" | "my-subscription" | "my-awards" | "my-receipts") => void;
}

export const CardUser: React.FC<CardUserProps> = ({
    photoUrl,
    nameUser,
    title,
    planName,
    sign,
    menu = false,
    item = "contact",
    onItemClick = () => { },
}) => {
  
  return (
        <CardGradient className="cardUser-container">
            <div className="cardUser">
                <User
                    photoUrl={photoUrl}
                    alias={nameUser.split(" ")[0][0]+""+nameUser.split(" ")[1][0]}
                />
                <div className="cardUser-column">
                    <div className="tinytext-header-2 cardUser-text">{title}</div>
                    <div className="paragraph cardUser-text">{nameUser}</div>
                    <div className="tinytext cardUser-text">{planName}</div>
                    <div className="tinytext cardUser-text">{sign}</div>
                </div>
            </div>

            {menu && <hr className="cardUser-hr"/>}

            {menu && <div className="cardUser-menu">
                <div className="tinytext-header-2 cardUser-text">Menú</div>
                <div className="cardUser-menu-body">
                    {MenuConst.map((r: any, idx: any) => (
                        <Menu 
                            key={idx} 
                            id={r.id}
                            img={r.img} 
                            text={r.text}
                            active={item === r.id}
                            onClick={onItemClick}
                        />
                    ))} 
                </div>
            </div>}
        </CardGradient>
  );
};

