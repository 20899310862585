import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

// importando servicios
import AuthService from "../Service/Auth.service";

// importando interfaces
import { UserInterface } from "../Interfaces/UserInterface";
import { CreateUser } from "../Service/User.service";


// metodo para registrar un usuario mediante correo
export const RegisterWithEmail = createAsyncThunk( 
    "auth/registerWithEmail",
    async (data: {user: UserInterface, password: string}, thunkAPI: any)=> {
      let user = data.user;
      try {
        const response:any = await AuthService.RegisterWithEmail(data.user, data.password); 
        if(response) {
          // console.log("slice/auth/register", response);
          let u = {...data.user, uid: response.uid, }

          const responseUser = await CreateUser({...u, createdAt: new Date(), updatedAt: new Date()});
          // console.log("slice/auth/create user", responseUser);

          // const responseUpdate = await AuthService.UpdateProfileUser({...user, updatedAt: new Date()});
          // console.log("🚀 ~ file: Auth.ts ~ line 28 ~ responseUpdate", responseUpdate)
          
          thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
          return {...response, displayName: user.name+" "+user.fathersLastName, userId: responseUser.id};
        }
        
      } catch (error:any) {
        console.log("slice/auth/register => error:", error.message);
        thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
        // console.log("slice/auth/register => error:", error.message);
        return thunkAPI.rejectWithValue();
      }
    }
);

// metodo para registrar un usuario mediante google
export const RegisterWithGoogle = createAsyncThunk(
  'auth/registerWithGoogle',
  async (_,thunkAPI: any) => {
    try {
      const response = await AuthService.RegisterWithGoogle();
      if(response) {
        // console.log("slice/auth/register", response);
        thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
        return response;
      }
    } catch (error:any) {
        console.log("slice/auth/register => error:", error.message);
        thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
        return thunkAPI.rejectWithValue();
    }
  }
);

// export const RegisterWithFacebook = createAsyncThunk(
//   'auth/registerWithFacebook',
//   async (_, thunkAPI: any) => {
//     try {
//       const response = await AuthService.RegisterWithFacebook();
//       if(response) {
//         // console.log("slice/auth/register", response);
//         thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
//         return response;
//       }
//     } catch (error:any) {
//         console.log("slice/auth/register => error:", error.message);
//         thunkAPI.dispatch(setError({ status: true, message: error.message, code: error.code }));
//         return thunkAPI.rejectWithValue();
//     }
//   }
// );

const initialState = {
  isLoggedIn: false,
  userId: '',
  uid: '',
  displayName: '',
  imgProfile: '',
  isProtectedRoute: false,
  resumReg: false
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.displayName = action.payload.displayName;
      state.userId = action.payload.userId;
      state.imgProfile = action.payload.imgProfile;
      state.isProtectedRoute = action.payload.isProtectedRoute;
      state.resumReg = action.payload.resumReg;
    }
  },
  extraReducers: {
    [RegisterWithEmail.fulfilled.toString()]: (state, action) => {
      state.isLoggedIn = true;
      state.displayName = action.payload.displayName;
      state.imgProfile = action.payload.imgProfile;
    },
    [RegisterWithEmail.rejected.toString()]: (state, action) => {
      state.isLoggedIn = false;
    },
    // [RegisterWithGoogle.fulfilled.toString()]: (state, action) => {
    //   state.isLoggedIn = true;
    //   state.displayName = action.payload.displayName;
    //   state.imgProfile = action.payload.photoURL;
    // },
    // [RegisterWithGoogle.rejected.toString()]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.displayName = '';
    //   state.imgProfile = '';
    // },
    // [RegisterWithFacebook.fulfilled.toString()]: (state, action) => {
    //   state.isLoggedIn = true;
    //   state.displayName = action.payload.displayName;
    //   state.imgProfile = action.payload.photoURL;
    // },
    // [RegisterWithFacebook.rejected.toString()]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.displayName = '';
    //   state.imgProfile = '';
    // },
    // [LoginWithEmailandPassword.fulfilled.toString()]: (state, action) => {
    //   state.isLoggedIn = true;
    //   // state.displayName = action.payload.displayName;
    //   // state.imgProfile = action.payload.photoUrl;
    //   // state.userId = action.payload.userId;
    // },
    // [LoginWithEmailandPassword.rejected.toString()]: (state, action) => {
    //   state.isLoggedIn = false;
    // },
  }
});

 const { reducer, actions } = authSlice;
 
 export const {setAuth} = actions;

 export default reducer;