export const TranslateDayName = (day: string) => {
    switch(day) {
        case "mon":
            return "LUN";
        case "tue":
            return "MAR";
        case "wed":
            return "MIE";
        case "thu":
            return "JUE";
        case "fri":
            return "VIE";
        case "sat":
            return "SAB";
        case "sun":
            return "DOM";
        default:
            return "";
    }
}