import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";

import { CodeValidationInterface } from "../Interfaces/EmailValidationInterface";
import { DB } from "../FirebaseConfig/FirebaseConfig";

export const CreateCodeValidation = async (codeValidation: CodeValidationInterface) => {
    try {
        const response = await addDoc(collection(DB, "codeValidation"), codeValidation);  
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
        return error;
    }
}

export const GetCodeValidationActive = async (type: "email" | "phone", valueType: string) => {
    const q = query(collection(DB, "codeValidation"), where("status", "==", true), 
        where("type", "==", type), where("valueType", "==", valueType));
    const response = await getDocs(q);
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

export const UpdateCodeValidation = async (id: string, emailValidation: any) => {
    try {
        if(id.length>0) {
            const requestRef = doc(DB, 'codeValidation', id);
            const response =  await updateDoc(requestRef, {...emailValidation});

            // console.log("UpdateRequestQuote", response)
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const GetCodeValidationByCode = async (type: "email" | "phone", valueType: string, code: string) => {
    const q = query(collection(DB, "codeValidation"), where("status", "==", true), where(type, "==", valueType), where("code", "==", code));
    const response = await getDocs(q);
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}