import React from "react";

// Importando estilo
import "./StoreComponent.css";
import { CardGradient } from "../CardGradient/CardGradient";
import { StoreConst } from "../../Const/StoreConst";

interface StoreComponentProps {
}

export const StoreComponent: React.FC<StoreComponentProps> = ({
}) => {

  
  return (
    <CardGradient className="store-gradient">
        <div className="store">
            <div className="paragraph-header store-text">{StoreConst.title}</div>
            <div className="smalltext store-text">{StoreConst.description}</div>
            { StoreConst.session.length>0 && StoreConst.session.map((item, idx) => {
                return(
                    <CardGradient key={idx} className="store-row-gradient">
                        <img src={item.img} className="store-img"/>
                        <div className="store-row">
                            <div className="tinytext store-text">Lo más nuevo</div>
                            <div className="tinytext store-text store-text_">{item.productName}</div>
                            <div className="tinytext store-text">{item.price + " USD"}</div>
                        </div>
                    </CardGradient>
                )
            })}
            
        </div>
    </CardGradient>
  );
};