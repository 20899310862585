import React, { useEffect, useState } from "react";

import './CardTextBody.css';
import { Button } from "../Button/Button";
import { TextField } from "../TextField/TextField";
import { ErrorInterface } from "../../Interfaces/ErrorInterface";
import { Validatefield } from "../../Utils/ValidateFields";
import { getAuth } from "firebase/auth";
import AuthService from "../../Service/Auth.service";
import { toast } from "react-toastify";
import { Loader } from "../Loader/Loader";
import { CardGradient } from "../CardGradient/CardGradient";

interface CardTextBodyProps {
    title: string;
    description: string;
    textButton: string;
    isChangePassword?: boolean;
    onClick?: () => void;

}
export const CardTextBody: React.FC<CardTextBodyProps> = ({
    title,
    description,
    textButton,
    onClick = () => {},
    isChangePassword = false,
}) => {

    const [loading, setLoading] = useState<boolean>(false);

    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
   
    const [passwordError, setPasswordError] = useState<{
        newPassword: ErrorInterface,
        confirmPassword: ErrorInterface
    }>();

    const handleClick = () => {
        if(isChangePassword) {
            setShowChangePassword(true);
        } else {
            onClick();
        }   
    }

    const validatePassword = () => {
      let error = false;
      error = Validatefield("password", newPassword) || Validatefield("password", confirmPassword) || (newPassword === confirmPassword) ? false : true;
      setPasswordError({
        confirmPassword: {
            status: error,
            message: "La contraseña no coincide"
        },
        newPassword: {
            status: error,
            message: "La contraseña no es válida"
        }    
      });
      return error;
    }

    const handleUpdatePassword = async() => { 
        if(validatePassword() || loading) return;
        
        setLoading(true);
        const auth = getAuth();
        const user = auth.currentUser;
        if(user) {
          try {
            const response = await AuthService.UpdatePassword(newPassword);
            if(response) {
                setShowChangePassword(false);
                setNewPassword("");
                setConfirmPassword("");
                toast.success("Contraseña actualizada correctamente");
                setLoading(false);
            } else {
                console.log("Error al cambiar la contraseña");
                toast.error("Hubo un error al cambiar la contraseña, por favor intenta de nuevo");
                 setLoading(false);
            }
          } catch (error) {
            console.log("🚀 ~ handleUpdatePassword ~ error:", error);
            toast.error("Hubo un error al cambiar la contraseña, por favor intenta de nuevo");
          }
        }
    }

  return (
    <CardGradient className="deletedSubscription">
        <div className="deletedSubscription-header">
            <div className="paragraph-subheader deletedSubscription-text">{title}</div>
           {!showChangePassword && <Button 
                text={textButton} 
                onClick={()=>handleClick()}
                color="red"
                radius="btn-radius-50"
            />}
        </div>

        <div className="smalltext deletedSubscription-text">{description}</div>

        {!showChangePassword && <Button 
            text={textButton} 
            onClick={()=>handleClick()}
            color="red"
            radius="btn-radius-50"
        />}
        {!loading ? <>{showChangePassword &&  <div className="deletedSubscription-changePassword">
            <TextField 
                label="Nueva contraseña"
                placeholder="Ingresa tu nueva contraseña"
                value={newPassword}
                type="password"
                onChange={(e)=>setNewPassword(e.target.value)} 
                error={passwordError?.newPassword}    
            />
            <TextField
                label="Confirma contraseña"
                placeholder="Ingresa tu nueva contraseña"
                value={confirmPassword}
                type="password"
                onChange={(e)=>setConfirmPassword(e.target.value)}
                error={passwordError?.confirmPassword}
            />
        </div>}
        {showChangePassword && <Button 
            className="deletedSubscription-changePassword-btn"
            text="Cambiar contraseña" 
            onClick={()=>handleUpdatePassword()}
            color="red"
            radius="btn-radius-50"
            />}</>: <Loader />
        }
    </CardGradient>
  )
}