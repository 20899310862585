import React, { useEffect, useState } from "react";

import './Subscription.css';
import { DetailSubscription } from "../../../Components/DetailSubscription/DetailSubscription";
import { CardTextBody } from "../../../Components/CardTextBody/CardTextBody";
import { useSelector } from "react-redux";
import { GetSubscriptionById } from "../../../Service/Subscription.service";
import { SubscriptionInterface } from "../../../Interfaces/SubscriptionInterface";
import { toast } from "react-toastify";
import { Loader } from "../../../Components/Loader/Loader";
import { FormatDate } from "../../../Utils/formatDate";
import { GetPlanById } from "../../../Service/Plan.service";
import { AdminPaid } from "../../../Components/AdminPaid/AdminPaid";
import { ChangePlan } from "../../../Components/ChangePlan/ChangePlan";
import { getPaymentMethodByUserActive } from "../../../Service/PaymentMethod";
import { ScheduleOperation } from "../../../Interfaces/ScheduleOperation";
import { FormatISODate } from "../../../Utils/FormatISODate";
import { BeforeDay } from "../../../Utils/BeforeDay";
import { CreateScheduleOperation, GetScheduleOperation } from "../../../Service/ScheduleOperation.service";

interface SubscriptionProps {
}
export const Subscription: React.FC<SubscriptionProps> = () => {

  const {userDetail} = useSelector((state: any) => state.user);

  const [loading, setLoading] = useState<boolean>(false);
  const [activeComponent, setActiveComponent] = useState('default');

  const [subscription, setSubscription] = useState<SubscriptionInterface>();
  const [planName, setPlanName] = useState<string>('');
  const [lastFourCardDigits, setLastFourCardDigits] = useState<string>('');
  const [scheduleOperation, setScheduleOperation] = useState<ScheduleOperation>();



  // const getSchetuleOperationById= async (id: string) => {
  //   try {
  //     const response = await GetScheduleOperation(id);
  //     setScheduleOperation(response);
  //   } catch (error) {
  //     console.log("🚀 ~ getSchetuleOperationById= ~ error:", error);
  //   }
  // }

  const handleDeleteSubscription = async () => {
    console.log("delete subscription");
    if(scheduleOperation && scheduleOperation.type === "cancelSubscription") {
      toast.error("Ya tienes una cancelación de suscripción programada");
      return;
    }
    if(subscription) {
      setLoading(true);
      try {
        const scheduleOperationData: ScheduleOperation = {
          subscriptionId: subscription?.id || "",
          paymentMethodId: subscription?.paymentMethodId || "",
          newPlanId: "",
          dateExecution: FormatISODate(BeforeDay(subscription.billingDate.toDate())),
          userId: userDetail.id,
          type: "cancelSubscription",
          status: "pending",
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          executed: false,
        }
        const response = await CreateScheduleOperation(scheduleOperationData);
        console.log("🚀 ~ handleDeleteSubscription ~ response:", response)
        if(response) {
            setTimeout(() => {
              toast.success("se ha programado la cancelación de la suscripción para: "+FormatDate(BeforeDay(subscription.billingDate.toDate())), {autoClose: 6500});
              getBackAndReload(); 
            }, 1500);
        }
        setLoading(false);
      } catch (error) {
        console.log("🚀 ~ handleDeleteSubscription ~ error:", error);
        setLoading(false);
        toast.error("Error al cancelar la suscripción");
      }
    }   
  }

  const getSubscription = async () => {
    if(loading) return;

    try {
      const response = await GetSubscriptionById(userDetail.subscriptionId);
      if(response) {
        setSubscription(response);
        await getPlanId(response.planId);
        await getPaymentMethod();

        if(response.scheduleOperationId){
          await getSchetuleOperationById(response.scheduleOperationId || "");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ getSubscription ~ error:", error);
      setLoading(false);
      toast.error("Error al obtener la suscripción");
    }
  }


  const getPaymentMethod = async () => {
    try {
      const respose = await getPaymentMethodByUserActive(userDetail.id);
      // console.log("🚀 ~ getPaymentMethod ~ respose:", respose)
      if(respose) {
        setLastFourCardDigits(respose.lastFour);   
      }
      
    } catch (error) {
      console.log("🚀 ~ getPaymentMethod ~ error:", error);
    }
}

  const getPlanId = async (id: string) => {
    try {
      const response = await GetPlanById(id);
      if(response) {
        setPlanName(response.title);
      }
    } catch (error) {
      console.log("🚀 ~ getPlanId ~ error:", error);
      toast.error("Error al obtener el nombre del plan");
    }
  }

  const getSchetuleOperationById = async (id: string) => {
    setLoading(true);
    try {
      const response = await GetScheduleOperation(id);
      if(response) {
        setScheduleOperation(response);
      }
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ getSchetuleOperationById= ~ error:", error);
      setLoading(false);
    }
}

  const handleAdPaid = () => {
    // if(scheduleOperation && scheduleOperation.type === "cancelSubscription") {
    //   toast.error("No puedes administrar la forma de pago, ya que tienes una cancelación de suscripción programada");
    // } else {
      setActiveComponent('adminPaid');
    // }
  }

  const handleChangePlan = () => {
    if(scheduleOperation && scheduleOperation.type === "cancelSubscription") {
      toast.error("No puedes cambiar de plan, ya que tienes una cancelación de suscripción programada");
    } else {
      setActiveComponent('changePlan');
    }
  }

  const getBackAndReload = async () => {
    setLoading(false);
    setActiveComponent('default');
    await getSubscription();
  }

  useEffect(() => {
    getSubscription();
  }, []);


  return (
    <div className="subscription">
      {subscription && !loading ? 
        (
          activeComponent === 'default' ? (
            <>
              <DetailSubscription
                title="Detalles de tu subscripción"
                description={scheduleOperation && scheduleOperation.type === "cancelSubscription" ? "La suscripción se cancelará el" : "Se renueva automáticamente el"}
                dateBilling={FormatDate(subscription.billingDate.toDate())}
                amountBilling={subscription.billingAmount}
                dateSubscription={FormatDate(subscription.subscriptionDate.toDate())}
                paymentMethod={"CULQI"}
                plan={planName+(subscription.scheduleOperationId && subscription.scheduleOperationType === "changePlan" ? ", cambio de plan programado": "") }
                cardBrand={subscription.paymentMethodName}
                lastFourCardDigits={lastFourCardDigits}
                handleAdPaid={handleAdPaid}
                handleChangePlan={handleChangePlan}
                
              />
              <CardTextBody
                title="Eliminar mi suscripción"
                description="Al cancelar tu suscripción perderás acceso a todos los beneficios y servicios exclusivos que nuestra plataforma ofrece."
                textButton="Cancelar suscripción"
                onClick={handleDeleteSubscription}
              />
            </>
          ) : activeComponent === 'adminPaid' ? (
            <AdminPaid
              subscriptionId={subscription.id || ''}
              handleGoBack={getBackAndReload}
            /> 
          ) : (
            <ChangePlan
              subscription={subscription}
              scheduleOperation={scheduleOperation}
              handleGoBack={()=>getBackAndReload()}
            />
          )
        ) : <div className="profile-container-loader"><Loader/></div>
      }
    </div>
  )
}