import React, { useState, useEffect } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// importando firebase 
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// importando componentes
import { Loader } from '../Loader/Loader';

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from '../../Store/store';
import { GetUserByUID } from '../../Slice/User';
import { setAuth } from '../../Slice/Auth';
import { AUTH_LOGIN, AUTH_MODAL, AUTH_REGISTER, HOME } from '../../Const/BaseUrlConst';
import { toast } from 'react-toastify';
import AuthService from '../../Service/Auth.service';

// store

interface ProtectedRouteProps {
    permissions?: string[]; // array de permisos
    userType?: string[]; // user type (user or provider)
    redirectPath?: string; // path a redireccionar
    children: React.ReactNode; 
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    permissions,
    userType='',
    redirectPath = `/?${AUTH_MODAL}=${AUTH_LOGIN}`,
    children,
    ...props
}) => {

    const dispatch = useDispatch<AppDispatch>();
    
    const { userDetail } = useSelector((state: any) => state.user);

    const navigation = useNavigate();
    const location = useLocation();

    const [searchParams] = useSearchParams();    
    const [loading, setLoading] = useState<boolean>(true);
    const [isLoggin, setIsLoggin] = useState<boolean>(false);

    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });

    const logout = async () => {
        const response = await AuthService.LogOutUser();
        if(response) {
            window.location.href = window.location.origin+'/';
        }
    }

    const getUserDetail = (UID: string) => {
        dispatch(GetUserByUID(UID))
        .unwrap()
        .then((response) => {
            // setUserDetail(response);
            if(response && response.status === true) {
                dispatch(setAuth({
                    isLoggedIn: true,
                    userId: response.id,
                    uid: response.uid,
                    displayName: response.name+" "+response.lastName,
                    imgProfile: response.photoUrl,
                }));
                setIsLoggin(true);
                setLoading(false);
            } else {
                toast.error("Usuario desactivado, por favor contacta a soporte");
                setLoading(false);
               setTimeout(() => {
                    logout();
               }, 1000);
            }
            
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }


    // permite redireccionar al home segun el tipo de usuario
    const goToUserHome = () => {
        navigation(`/${HOME}`, {replace: true});
    }

    const getAccessUrl = () => {
        if(userDetail){
            searchParams.delete(AUTH_MODAL);
            return children;
        } else {
            
            goToUserHome()
        }
    }

    const rediretToLogin = () => {
        // const response = await authService.LogOutUser();
        if(isLoggin) return;

        // dispatch(setHistoryLocation(location.pathname));
        if(!searchParams.get(AUTH_MODAL) && (!searchParams.get(AUTH_MODAL)?.includes(AUTH_LOGIN) ||
        !searchParams.get(AUTH_MODAL)?.includes(AUTH_REGISTER) )) {
            dispatch(setAuth({
                isLoggedIn: false,
                displayName: "",
                imgProfile: "",
                userId: "",
                uid: "",
                isProtectedRoute: true
            }));
            return navigation(redirectPath, {replace: true});
        }
        
    }
    
    useEffect(() => {
        const auth = getAuth();
        setLoading(true);
        onAuthStateChanged(auth, (userCurrent) => {
            if(userCurrent) {
                setUser(userCurrent);
                getUserDetail(userCurrent.uid);
            } else {
                setLoading(false);
            }
        });
    }, []);

    return (
        <>
            {!loading ?  (
                (user && isLoggin) ? 
                getAccessUrl(): 
                rediretToLogin()
            ) : <div className='loader-style' style={{width: '100%', height: '100vh', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader />
                </div>}
        </>
    )
}