import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";
import { GetPlanById } from "../Service/Plan.service";

export const GetPlanDetail = createAsyncThunk(
    "plan/getPlanDetail",
    async (id: string, thunkAPI: any) => {
        try {
            const response = await GetPlanById(id);
            if(response) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response;
            } else {
                return thunkAPI.rejectWithValue();
            }
        } catch (error) {
            thunkAPI.dispatch(setError(error));
            return thunkAPI.rejectWithValue();
        }
    }
    );

    const initialState = {
        planDetail: {} || undefined,
    }

    const userSlice = createSlice({
        name: "plan",
        initialState,
        reducers: {},
        extraReducers: {
            [GetPlanDetail.fulfilled.toString()]: (state, action) => {
                state.planDetail = action.payload;
            },
            [GetPlanDetail.rejected.toString()]: (state, action) => {
                state.planDetail = undefined;
            },
        }
    });

    const { reducer } = userSlice;
    export default reducer;