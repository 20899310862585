export type AuthSteps_item = 
    "MAIN_LOGIN_STEP" | 
    "MAIN_LOGIN_EMAIL" |
    "MAIN_REGISTER_STEP" | 
    "RESTORE_STEP" | 
    "REGISTER_EMAIL_STEP" | 
    "REGISTER_CODE_STEP" |
    "REGISTER_DETAIL_STEP"|
    "CHECKOUT_STEP";

export type AuthTitle_Step = 
    'Iniciar sesión' 
    | "Regístrate" 
    | "Recuperar constrasena" 
    | "Regístrate con tu correo"
    | "Crea tu cuenta "
    | "Realiza el pago";

export const AuthSteps : {
    step: AuthSteps_item,
    title: AuthTitle_Step,
    registerStep?: number,
}[] = [
    {
        step: "MAIN_LOGIN_STEP",
        title: "Iniciar sesión",
        
    },

    {
        step: "MAIN_REGISTER_STEP",
        title: "Regístrate",
        registerStep: 1
    },
   
    {
        step: "RESTORE_STEP",
        title: "Recuperar constrasena",
      
    },

    {
        step: "REGISTER_EMAIL_STEP",
        title: "Regístrate",
        registerStep: 1
    },

    {
        step: "REGISTER_CODE_STEP",
        title: "Regístrate",
        registerStep: 1
    },

    {
        step: "REGISTER_DETAIL_STEP",
        title: "Crea tu cuenta ",
        registerStep: 2
    },

    {
        step: "CHECKOUT_STEP",
        title: "Realiza el pago",
        registerStep: 3
    }
]