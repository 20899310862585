import React, { useRef } from "react";

/** importando estilos */
import "./Tabs.css";
import { CardGradient } from "../CardGradient/CardGradient";

/**declaracion de variables de interface */
interface TabsProps {
  active?: boolean;
  content: any;
  index?: number;
  className?: string;
  handleClick: (e: string)=> void;
  setIndex?: (e: number) => void;
  whiteMode?: boolean; // active el modo white de los items
}

/**clase SubHeader 
 * renderiza un sub menu
 * propiedades:
 * - handleClick => onClick el el sub menu
*/
export const Tabs: React.FC<TabsProps> = ({ 
  active, 
  content, 
  handleClick,
  index=0,
  setIndex = ()=>{},
  className='',
  whiteMode = false, 
  ...props
}) => {
  const subheaderRef = useRef<HTMLSpanElement>(null);

  if (active) {
    subheaderRef.current?.classList.add("tabs-clicked");
  } else {
    subheaderRef.current?.classList.remove("tabs-clicked");
  }

  const handleItemClick = () => {
    handleClick(content);
    setIndex(index)
  }

  return (
    <CardGradient className={`tabsGradient ${active && "tabs-clicked"}`}>
      <span {...props} 
        role='tabs' 
        onClick={()=>handleItemClick()} 
        className={`${whiteMode ? 'tabs_white' : 'tabs_text'} ${className ? className: 'smalltext-header'}`} 
        ref={subheaderRef}>
          {content}
      </span>
    </CardGradient>
    
  );
};
