import React from "react";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

type InstagramWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const InstagramWhite: React.FC<InstagramWhiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/instagram-white.svg"} alt="" />
    </div>
  );
};