import React from "react";
import { btnsColorTypes } from "../../@types/btnsColorTypes";
import { TextSizeOptions } from "../../@types/textSizeTypes";
import {
  selectBtnIconSizeClass,
  selectBtnStyleColor,
} from "../../Utils/selectButtonsStyles";
import "./ButtonIcon.css";

type ButtonIconProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  content: string;
  place?: IconPlace;
  Icon?: React.ReactElement<any, any>;
  size?: TextSizeOptions;
  color?: btnsColorTypes;
  disabled?: boolean;
  className?: string;
};

type IconPlace = "left" | "right";

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  content,
  place = "left",
  Icon,
  size = "normal",
  color = "red",
  disabled = false,
  className = "",
  ...props
}) => {
  let IconLeft = null;
  let IconRight = null;
  if (place === "left") {
    IconLeft = Icon;
  } else {
    IconRight = Icon;
  }

  return (
    <button
      className={`custom-button-icon ${selectBtnIconSizeClass(size)} ${disabled && "btnIcon-disabled"} ${className}`}
      style={{
        backgroundColor: selectBtnStyleColor(color),
        color: color === "white" ? "black" : "white",
      }}
      disabled={disabled}
      {...props}
    >
      {IconLeft}
      <div className="tinytext custom-button-icon-text">{content}</div>
      {IconRight}
    </button>
  );
};
