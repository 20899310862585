import React, { useEffect, useRef } from "react";

import './ModalReadFuture.css';
import { Modal } from "../Modal/Modal";
import { Close } from "../../Icons/outline/Close";
import { CreditCard } from "../CreditCard/CreditCard";
import { ChargeSessionInterface } from "../../Interfaces/ChargeSessionInterface";
import { CreateNewCharge } from "../../Service/ChargeSession";
import { toast } from "react-toastify";
import { Loader } from "../Loader/Loader";

interface ModalReadFutureProps {
    showReadFuture: boolean;
    setShowReadFuture: (e:boolean) => void;
    readingPriceId: string;
    price: number;
    currency: string;
    email: string;
    userId: string;
}

export const ModalReadFuture: React.FC<ModalReadFutureProps> = ({
    showReadFuture,
    setShowReadFuture,
    readingPriceId,
    price,
    currency,
    email,
    userId
}) => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const handlePayClick = (toke: string) => {
        setLoading(true);
        const newCharge: ChargeSessionInterface = {
            token: toke,
            readingPriceId: readingPriceId,
            currency: currency,
            userId: userId,
        }
        CreateNewCharge(newCharge).then((response) => {
            console.log("🚀 ~ CreateNewCharge ~ response:", response)
            toast.success("Tu pago ha sido procesado correctamente, en breves momento recibirás un correo electrónico con la información", {autoClose: 6000});
            setLoading(false);
            setShowReadFuture(false);
        }).catch((error) => {
            console.log("🚀 ~ handlePayClick ~ error:", error);
            setLoading(false);
            toast.error("Error al procesar el pago, intentelo más tarde");
        });
        //setLoading(false);
    }


    return (
        <Modal 
            className="modalReadFuture"
            title = "Estás a un paso de saber más sobre tu futuro"
            icon = {<Close/>}
            show = {showReadFuture}
            setShow = {setShowReadFuture}
            children = {
                !loading ? <div>
                <CreditCard
                    price={price}
                    email={email}
                    onClick={handlePayClick}
                    buttonText="Guardar"
                    currency={currency}
                />
            </div> : <div className="modalReadFuture-loader"> <Loader/></div>
            }
            onClose = {() => setShowReadFuture(false)}
        />
    )
}