import React from "react";

import "./LabelText.css";

interface LabelTextProps {
    text: string;
    value: string;
}

export const LabelText: React.FC<LabelTextProps> = ({text, value}) => {
    return (
        <div className="labelText-container">
            <label className="tinytext-header labelText-textColor">{text}</label>
            <div className="paragraph-subheader labelText-textColor">{value}</div>
        </div>
    )
}