export const StoreConst : {
    title: string;
    description: string;
    session: {
        img: string;
        productName: string;
        price: number;
    }[]
} = 
    {
        title: 'Visita nuestra tienda',
        description:'Último producto en tendencia',
        session: [
            {   img: 'https://images.pexels.com/photos/27221171/pexels-photo-27221171/free-photo-of-comida-gente-mujer-cena.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
                productName: 'Vela aromática para manifestar en año nuevo',
                price: 30
            }
        ]
    }
