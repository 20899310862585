import React, { useEffect, useState } from "react";

// Importando estilo
import "./CardSection.css";
import { SessionConst } from "../../Const/SessionConst";
import { CardGradient } from "../CardGradient/CardGradient";
import { GetReadingPriceActive } from "../../Service/ReadingPrice.service";
import { toast } from "react-toastify";
import { ReadingPriceInterface } from "../../Interfaces/ReadingPriceInterface";
import { ModalReadFuture } from "../ModalReadFuture/ModalReadFuture";
import { useSelector } from "react-redux";

interface CardSectionProps {

}

export const CardSection: React.FC<CardSectionProps> = ({

}) => {

    const {userDetail} = useSelector((state: any) => state.user);

    const [readingPrice, setReadingPrice] = useState<ReadingPriceInterface[]>([]);
    const [readingPriceActive, setReadingPriceActive] = useState<ReadingPriceInterface>();
    const [showReadFutureModal, setShowReadFutureModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleItemClick = (item: ReadingPriceInterface) => {
        setReadingPriceActive(item);
        setShowReadFutureModal(true);
    }

    const getReadingPrice = async () => {
        try {
            const response = await GetReadingPriceActive();
            setReadingPrice(response);
        } catch (error) {
            console.log("🚀 ~ getReadingPrice ~ error:", error)
            toast.error("Error al obtener el precio de la lectura");
        }
    }

    useEffect(() => {
        getReadingPrice();
    }, []);

  return (
    <>
        <CardGradient className="cardSection-gradient">
        <div className="cardSection">
            <div className="paragraph-header cardSection-text">{SessionConst.title}</div>
            <div className="smalltext cardSection-text">{SessionConst.description}</div>
            { readingPrice.length>0 && readingPrice.map((item, idx) => {
                return(
                    <CardGradient key={idx} className="cardSection-row-gradient" handleClickCard={()=>handleItemClick(item)}>
                        <div className="cardSection-row">
                            <div className="tinytext cardSection-text cardSection-text_">{item.description}</div>
                            <div className="tinytext cardSection-text cardSection-text_">{item.price + " "+item.currency}</div>
                        </div>
                    </CardGradient>
                )
            })}
        </div>
        </CardGradient>
        {showReadFutureModal && readingPriceActive && userDetail && 
        <ModalReadFuture
            showReadFuture = {showReadFutureModal}
            setShowReadFuture = {setShowReadFutureModal}
            currency={readingPriceActive?.currency}
            email={userDetail.email}
            price={readingPriceActive?.price}
            readingPriceId={readingPriceActive.id || ""}
            userId={userDetail.id}
        />}
    </>
  );
};