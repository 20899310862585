import React, { useEffect, useRef } from "react";

import './ModalReglament.css';
import { Modal } from "../Modal/Modal";
import { Close } from "../../Icons/outline/Close";

interface ModalReglamentProps {
    showReglament: boolean;
    setShowReglament: (e:boolean) => void;
}

export const ModalReglament: React.FC<ModalReglamentProps> = ({
    showReglament,
    setShowReglament
}) => {

    return (
        <Modal 
            title = "Reglamento"
                icon = {<Close/>}
                show = {showReglament}
                setShow = {setShowReglament}
                children = {
                    <div className="modalReglament">
<div className="modalReglament-title">Definición de términos</div>
                       
<div className="modalReglament-paragraph">
Artículo 1°.- Definición de términos
<br/>
Para efectos del Reglamento de Premios que se ofrecen en el Sitio Web www.santazinos.com (el “Reglamento”, en adelante), se enumeran los conceptos más importantes que deben ser tomados en consideración:
<br/>
Sitio Web: La web, cuyo dominio es: www.santazinos.com, es la página web oficial de AZTRO X creada con la finalidad de ofrecer productos de merchandise y servicios para suscriptores.
Suscriptor: Es la persona natural, mayor de 18 años residentes en el Perú o personas naturales de otros países, consideradas con mayoría de edad en su respectiva jurisdicción, que se registre en el Sitio Web, que voluntariamente y en pleno conocimiento del contenido del presente Reglamento, decide participar en el Premio de  AZTRO X en el Sitio Web, y que tiene la condición de Usuario Suscriptor, de conformidad y aceptación previa de los Términos y Condiciones del Sitio Web al momento de su registro, al contar con una suscripción activa mensual.
Suscriptor Beneficiado: Es la persona natural, mayor de 18 años residentes en el Perú o personas naturales de otros países, consideradas con mayoría de edad en su respectiva jurisdicción, quien se ha hecho acreedor de un Premio de AZTRO X.
Premio de AZTRO X: Consiste en un procedimiento en el que aleatoriamente se escoge a un Usuario Suscriptor, el cual debe tener la suscripción mensual activa. En caso éste haya cumplido estrictamente con el contenido del presente Reglamento, el Usuario Suscriptor recibirá el premio objeto de AZTRO X. Para participar del premio de AZTRO X, se requiere ser un Usuario Suscriptor con una suscripción activa mensual.
Reglamento: El presente documento, que tiene por objeto establecer las condiciones por las que se rige el Premio de AZTRO X.
AZTRO X: Es AZTRO X S.A.C., sociedad creada bajo las leyes de la República del Perú, identificada con Registro Único de Contribuyente Nro. 20612211338.
Términos y Condiciones: son los términos y condiciones del Sitio Web. 
<br/>
</div>
<div className="modalReglament-title">Elegibilidad y derechos de participantes</div>
<div className="modalReglament-paragraph">
Artículo 2.- Sólo podrán participar de los Premios d de AZTRO X las personas naturales con capacidad para contratar (mayores de 18 años residentes en el Perú o personas naturales de otros países, consideradas con mayoría de edad en su respectiva jurisdicción), y que sean Usuarios Suscriptores y cuenten con una suscripción activa mensual en el Sitio Web. La condición de participante en el Premio de AZTRO X implica, por parte del participante, el conocimiento del Reglamento y de las instrucciones del Premio de AZTRO X, así como su adhesión a lo establecido en los mismos y a los Términos y Condiciones, siendo inaceptable cualquier tipo de petición o reclamación fundada en ignorancia o desconocimiento.
<br/>
Artículo 3.- Para participar en el Premio de AZTRO X y ser elegible para ganar los artículos ofrecidos, el Usuario Suscriptor debe contar con una suscripción activa mensual en el Sitio Web.
<br/>
Artículo 4.- En reconocimiento al apoyo de los Usuarios Suscriptores al Sitio Web, AZTRO X realizará un (1) Premio mensual como mínimo. Sin desmedro de lo anterior, el número de Premios de AZTRO X podrá irse incrementando de forma progresiva, AZTRO X se reserva este derecho. 

Celebración del premio de AZTRO X y elección de suscriptor beneficiario

Artículo 5.- La realización del Premio de AZTRO X y el anuncio de los beneficiados se realizará en el Sitio Web en la fecha y hora especificadas en el calendario interactivo de Artículos del Sitio Web. La realización del Premio de AZTRO X y la elección de los Suscriptores Beneficiados será realizada a través de medios electrónicos a través del sistema de selección aleatorio denominado “Random.org.”, administrado por Randomness and Integrity Services Limited, una sociedad constituida bajo las leyes de la República de Irlanda inscrita con el Número de Compañía 484434. Para el caso de artículos con valor mayor a [USD 5,000.00 (cinco mil y 00/100 Dólares de los Estados Unidos de América], la realización del Premio AZTRO X estará supervisada y monitoreada por Notario Público de Lima - Perú, quien dará conformidad de los resultados.
    <br/>
Artículo 6.- Resultarán beneficiarios de los artículos ofrecidos los suscriptores que, habiendo cumplido todos los requisitos establecidos en este Reglamento, es decir ser Usuarios Suscriptores y contar con una suscripción activa mensual y sean elegibles de acuerdo a lo indicado en este Reglamento (ver artículo 10) y en el Sitio Web (sobre la territorialidad del premio), hayan sido escogidos por el sistema de selección aleatorio denominado “Random.org.”. Adicionalmente a ello, de conformidad con lo establecido en los Términos y Condiciones y la Política de Privacidad del Sitio Web, de existir modificaciones parciales o totales de los referidos documentos, se le informará al Usuario Suscriptor a través de la publicación en el Sitio Web, para su aceptación. De no encontrarse conforme con las modificaciones AZTRO X suspenderá el uso de la cuenta. Sin desmedro de lo anterior, de no haber ingresado a su Cuenta hasta el día en el que se convierte en beneficiado será necesario aceptar las modificaciones correspondientes en ese momento para hacerse acreedor a los artículos ofrecidos. De lo contrario, no podrá hacerse acreedor a los artículos ofrecidos.
<br/>
Artículo 7.- En caso el Usuario Suscriptor elegido aleatoriamente por el sistema no cuente con una suscripción activa mensual, se procederá a escoger al segundo de la lista aleatoria. 

En caso este segundo participante escogido tampoco cuente con una suscripción activa mensual, se procederá con el tercero en la lista.

En caso el tercero tampoco cuente con una suscripción activa mensual, se procederá a generar una nueva lista aleatoria, en la que se le aplicarán las reglas descritas en este Reglamento.

Artículo 8.- La lista de Usuarios Suscriptores, que se usará para la realización del evento de Premio de AZTRO X, se realizará en razón de la base de datos de Usuarios Suscriptores activos, en la fecha y hora del día indicado.
<br/>
Al respecto, el aparecer en dicha base de datos no significa necesariamente que el Usuario Suscriptor cuenta con una suscripción activa mensual.
</div>

<div className="modalReglament-title">Entrega de artículos</div>
<div className="modalReglament-paragraph">
Artículo 9.- Para la entrega de los artículos, se contactará con el Suscriptor Beneficiado a través del correo electrónico y número telefónico de contacto consignados al momento de su registro como Usuario Suscriptor en el Sitio Web.
<br/>
El Suscriptor Beneficiado, bajo su plena responsabilidad, deberá responder a la comunicación de AZTRO X en un plazo máximo de siete (07) días calendario desde enviada esta comunicación. En caso no responda a la misma, AZTRO X se reserva el derecho a entregar el artículo a un segundo Suscriptor Beneficiado o a almacenarlo para un nuevo Premio de AZTRO X.
<br/>
En el día y momento de la entrega, el ganador deberá firmar un acta de entrega y será fotografiado con el premio. Con ello se entenderá que el ganador se encuentra conforme con el estado y las características del premio entregado.En el día y momento de la entrega, el Suscriptor Beneficiado deberá firmar un acta de entrega y será fotografiado con el artículo. Con ello se entenderá que el Suscriptor Beneficiado se encuentra conforme con el estado y las características del artículo entregado.
<br/>
Artículo 10.- Si el Suscriptor Beneficiado de un Premio de AZTRO X es un Usuario Suscriptor que no reside en el Perú, AZTRO X se compromete a hacer los máximos esfuerzos para la entrega del artículo en el domicilio indicado por el Suscriptor Beneficiado. Sin embargo, si esto no fuera posible, ya sea porque el costo de envío excede lo presupuestado por AZTRO X; el Suscriptor Beneficiado recibirá un monto dinerario equivalente al precio del artículo, el cual será calculado en base al valor de la compra realizada por AZTRO X sea en moneda nacional (sol peruano) o moneda extranjera. El monto final que recibirá el Suscriptor Beneficiado, será aquel resultante después de descontar las comisiones bancarias, interbancarias y/o cualquier otro tipo de comisión/cobro que se generen al momento de ejecutar el envío y/o recepción del efectivo (monto dinerario) para el suscriptor beneficiado. AZTRO X no asume responsabilidad sobre el tipo de cambio o cambio de divisa que pueda realizar el medio de pago utilizado para enviar el efectivo sea al momento de ejecutarlo y/o recepcionar. En cualquiera de los dos casos, la entrega del artículo o el monto dinerario equivalente, será coordinada mediante correo electrónico y número telefónico de contacto consignados al momento de su registro como Usuario Suscriptor en el Sitio Web. Se actualiza quincenalmente sobre el progreso de entrega.

Sin perjuicio a lo antes descrito, se establece que lo mencionado en el presente artículo no será aplicable para el caso  de premios consistentes en artículos tales como bienes perecibles (p. ej. alimentos), y bienes registrables (p. ej. motocicletas, autos, departamentos y/o inmuebles) donde solo podrán participar, aquellos Suscriptores activos que se encuentren en territorio peruano.
<br/>
Artículo 11.- Para la publicación de la relación de ganadores, el Suscriptor Beneficiado podrá decidir si es que se muestra su nombre real o su apodo, consignados al momento de su registro como Usuario Suscriptor. Además, tendrá la opción de elegir si es que quiere que se muestre su rostro en la foto pública del Suscriptor Beneficiado, o si es que prefiere que no se muestre su rostro, en caso elija la segunda opción, se le pondrá una máscara o cualquier otro símbolo que cubra su rostro y proteja su identidad.
<br/>
</div>  

<div className="modalReglament-title">Limitaciones de responsabilidad</div>
<div className="modalReglament-paragraph">
Artículo 12.- Los artículos que forman parte de los Premios de AZTRO X son provistos por terceros, por lo que AZTRO X no tienen ninguna injerencia ni participación en su fabricación y/o comercialización. En ese sentido, AZTRO X no brinda ninguna garantía y, en consecuencia, no es responsable por la falta de idoneidad o vicios ocultos que puedan presentar los bienes entregados a los Suscriptores Beneficiados.

Por otro lado, los Usuarios Suscriptores son los únicos responsables de sus actos y de las consecuencias que estos generen, por lo tanto, AZTRO X no ofrece garantías de ningún tipo respecto a las probabilidades de éxito de cada Usuario Suscriptor, reconociendo éste que los ganadores son escogidos de forma completamente aleatoria por un sistema informático administrado por un tercero, respecto del cual AZTRO X no tiene control.

Finalmente, los Suscriptores Beneficiados son responsables por el uso y disfrute de los bienes que puedan recibir como consecuencia de su participación en los Premios AZTRO X, por lo AZTRO X no se hace responsable y el Suscriptor Beneficiado mantiene indemne a AZTRO X por cualquier daño que pueda generarse a partir del uso de dichos bienes asignados como premio.
<br/>
Artículo 13.- En caso de cancelación o postergación del Premio de AZTRO X por causas no imputables o que escapen al control de AZTRO X (tales como fallas en las plataformas, servidores, operadores de servicios de internet, operadores del servicio de selección aleatoria, o proveedores de los premios), AZTRO X no será responsable por lo que esto genere. De ser el caso, se darán las indicaciones acerca de la nueva fecha y hora de celebración del nuevo Premio de AZTRO X, esta información se podrá visualizar en el calendario interactivo de Eventos del Sitio Web.
</div>
                    </div>
                }
                onClose = {() => setShowReglament(false)}
            />
    )
}