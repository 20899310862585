import React from "react";

import './Receipts.css';
import { TableHeader } from "../TableHeader/TableHeader";
import { TableBody } from "../TableBody/TableBody";
import { CardGradient } from "../CardGradient/CardGradient";
import { ReceiptInterface } from "../../Interfaces/ReceiptInterface";

interface ReceiptsProps {
    receipts: ReceiptInterface[];
}

export const Receipts: React.FC<ReceiptsProps> = ({
    receipts
}) => {

    return (
        <div className="receipts-content">
            <CardGradient>
                <div className="paragraph-header receipts-header receipts-text">Mis recibos</div>
                <TableHeader name={["Producto", `${window.innerWidth < 1150 ? 'Nº Orden' : 'Nro de Orden'}`, "Cantidad", "Fecha", "Monto", "Pago", "Recibo", "Acciones"]} />
                {receipts.length>0 ? receipts.map((d, idx) => {
                    return (
                    <div className="receipts-body">
                        <TableBody key={idx} receipt={d}/>
                    </div>
                    )
                }) : 
                <div className="receipts-nothing">
                    <div className="paragraph-header receipts-text">No tienes ningún recibo</div>
                </div>}
            </CardGradient> 
        </div>
    )  
} 