import React, { useState } from "react";

import './SubscriptionConcept.css';
import { CardBackground } from "../CardBackground/CardBackground";

interface SubscriptionConceptProps {
    title: string;
    detail: string;
    period: string;
    price: number;
}

export const SubscriptionConcept: React.FC<SubscriptionConceptProps> = ({
    title,
    detail,
    period,
    price
}) => {

    return (
        <div className="subscriptionConcept">
            <div className="smalltext-header subscriptionConcept-header">Concepto de suscripción</div>
            <CardBackground className="subscriptionConcept-body">
                <div className="smalltext subscriptionConcept-text">{title} - {period}</div>
                <div></div>
                <ul>
                    <li className="smalltext subscriptionConcept-text">{detail}</li>
                </ul>
            </CardBackground>
            <div className="subscriptionConcept-footer smalltext">
                <div className="smalltext-header subscriptionConcept-text">Total USD</div>
                <div className="smalltext subscriptionConcept-text">{price}</div>
            </div>
        </div>
    )
}