import React, { useEffect } from "react";

import "./CarouselHoroscopeMin.css";
import { ArrowLeft } from "../../Icons/solid/ArrowLeft";
import { ItemHoroscopeMin } from "./ItemHoroscopeMin/ItemHoroscopeMin";
import { ArrowRigth } from "../../Icons/solid/ArrowRigth";
import { CardGradient } from "../CardGradient/CardGradient";

interface CarouselHoroscopeMinProps {
    listHoroscope: {
        iconWhiteUrl: string;
        iconBlackUrl: string;
        name: string;
    }[];
    activeHoroscope: number;
    setActiveHoroscope: React.Dispatch<React.SetStateAction<number>>;
    loading?: boolean;
}

export const CarouselHoroscopeMin: React.FC<CarouselHoroscopeMinProps> = ({
    listHoroscope,
    activeHoroscope,
    setActiveHoroscope,
    loading = false
}) => {

    const refCarousel = React.useRef<HTMLDivElement>(null);

    // Duplicate the list items to create an infinite loop effect
    const duplicatedList = [...listHoroscope];


    const isMobile = () => window.innerWidth <= 768;

    // method to move the carousel to the left
    const handleClickLeft = () => {
        if (isMobile() && refCarousel.current) {
            refCarousel.current.scrollLeft -= 100;
            if (refCarousel.current.scrollLeft <= 0) {
                refCarousel.current.scrollLeft = refCarousel.current.scrollWidth / 2;
            }
        }
    }


    // method to move the carousel to the right
    const handleClickRight = () => {
        if (isMobile() && refCarousel.current) {
            refCarousel.current.scrollLeft += 100;
            if (refCarousel.current.scrollLeft >= refCarousel.current.scrollWidth / 2) {
   
                refCarousel.current.scrollLeft = 0;
            }
        }
    }

    useEffect(() => {
        if (isMobile() && refCarousel.current) {
            const itemWidth = refCarousel.current.scrollWidth / duplicatedList.length;
            const initialScrollPosition = (itemWidth * activeHoroscope)/2;
            refCarousel.current.scrollLeft = initialScrollPosition;
        }
    }, [activeHoroscope, duplicatedList.length]);


    return (
        <CardGradient className="carouselHoroscopeMin">
            <div className="carouselHoroscopeMin-container">
                <ArrowLeft id="carouselHoroscopeMin-leftIcon" style={{display: "flex"}} onClick={() => handleClickLeft()} /> 
                <div className="carouselHoroscopeMin-list" ref={refCarousel}>
                    { duplicatedList.map((item, index) => (
                        <ItemHoroscopeMin
                            key={index}
                            iconWhite={item.iconWhiteUrl}
                            iconBlack={item.iconBlackUrl}
                            name={item.name}
                            isActived={activeHoroscope === index % listHoroscope.length}
                            onClick={() => setActiveHoroscope(index % listHoroscope.length)}
                        />
                    ))}
                </div>
                <ArrowRigth id="carouselHoroscopeMin-rightIcon" style={{display: "flex"}} onClick={()=> handleClickRight()} />
            </div> 
            {/* <HoroscopeMinLoader />  */}
        </CardGradient>
    )
}