import React, { useEffect, useState } from "react";

// Importando estilo
import "./Item.css";
import { CheckWhite } from "../../Icons/solid/CheckWhite";

interface ItemProps {
    itemLabel: string;
}

export const Item: React.FC<ItemProps> = ({
    itemLabel
}) => {

    return (
        <div className="item">
            <CheckWhite/>
            <div className="smalltext item-text plan-text">{itemLabel}</div>
        </div>
    );
};