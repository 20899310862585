import React, { useState } from "react";

import './RegisterSocial.css';
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { CardBackground } from "../CardBackground/CardBackground";
import { FacebookBlue } from "../../Icons/solid/FacebookBlue";
import { Gmail } from "../../Icons/solid/Gmail";
import { useNavigate } from "react-router-dom";
import { AUTH_LOGIN, AUTH_MODAL } from "../../Const/BaseUrlConst";

interface RegisterSocialProps {
    handleClick: (method: "google" | "facebook" | "email") => void;
}

export const RegisterSocial: React.FC<RegisterSocialProps> = ({
    handleClick
}) => {

    const navigation = useNavigate();

    const handleLoginClick = () => {
        navigation(`?${AUTH_MODAL}=${AUTH_LOGIN}`);
      
      }

    return (
        <div className="registerSocial">
            <CardBackground>
                <div className="tinytext-header-1 registerSocial-title registerSocial-text">¿Cómo quieres registrarte?</div>
                
                <div className="registerSocial-buttons">
                    <ButtonIcon
                        content="Gmail"
                        color="white"
                        size="extra-small"
                        Icon={<Gmail />}
                        onClick={()=>handleClick("google")}
                    />
                    {/* <ButtonIcon
                        content="Facebook"
                        color="red"
                        size="extra-small"
                        Icon={<FacebookBlue />}
                        onClick={()=>handleClick("facebook")}
                    /> */}

                    <ButtonIcon
                        content="Correo electrónico"
                        color="white"
                        size="extra-small"
                        onClick={()=>handleClick("email")}
                    />
                    <div className="tinytext registerSocial-login registerSocial-text">¿Ya tienes cuenta? <span onClick={()=>handleLoginClick()}> Inicia sesión</span></div>
                </div>
            </CardBackground>
        </div>
    )
}