import React, { useEffect, useRef, useState } from "react";

import './ModalSoporte.css';
import { Modal } from "../Modal/Modal";
import { Close } from "../../Icons/outline/Close";
import { TextField } from "../TextField/TextField";
import { PhoneCountry } from "../PhoneCountry/PhoneCountry";
import { TextArea } from "../TextArea/TextArea";
import { Button } from "../Button/Button";

interface ModalSoporteProps {
    showSoporte: boolean;
    setShowSoporte: (e:boolean) => void;
}

export const ModalSoporte: React.FC<ModalSoporteProps> = ({
    showSoporte,
    setShowSoporte
}) => {

    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }

    const handleCity= (e: React.ChangeEvent<HTMLInputElement>) => {
        setCity(e.target.value);
    }

    const handleDescription= (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    }

    return (
        <Modal 
            title = {
                    "Soporte"
                }
            icon = {<Close/>}
            show = {showSoporte}
            setShow = {setShowSoporte}
            className="modalsoporte"
            children = {
                <div className="modalsoporte-content">
                    <div className="modalsoporte-row">
                        <TextField
                            label="Nombre"
                            placeholder="Ingresa tu nombre"
                            value={name}
                            onChange={(e)=>handleName(e)}
                        />
                        <TextField
                            label="Correo"
                            placeholder="Ingresa tu correo electrónico"
                            value={email}
                            onChange={(e)=>handleEmail(e)}
                        />
                    </div>
                    <div className="modalsoporte-row">
                        <PhoneCountry
                            handleCountryChange={()=>{}}
                            handlePhoneCodeChange={()=>{}}
                            handlePhoneChange={()=>{}}
                            phone={""}
                            phoneCode={""}
                            phoneFlag={""}
                        />
                        <TextField
                            label="Ciudad"
                            placeholder="Ingresa ciudad"
                            value={city}
                            onChange={(e)=> handleCity(e)}
                        />
                    </div>
                    <TextArea
                        label="Descripción"
                        value={description}
                        onChange={(e)=>handleDescription(e)}
                    />

                    <div className="modalsoporte-footer">
                        <Button
                            text={"Enviar"}
                            color="orange"
                            onClick={()=>{}}
                        />
                    </div>
                </div>
            }
            onClose = {() => setShowSoporte(false)}
        />
    )
}