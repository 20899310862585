import React, { useEffect, useState } from "react";

// Importando estilo
import "./GoBack.css";
import { ArrowLeft } from "../../Icons/solid/ArrowLeft";

interface GoBackProps {
    handleClick?: ()=>void;
}

export const GoBack: React.FC<GoBackProps> = ({
    handleClick = () => {}
}) => {

  
  return (
    <div className="goBack-back" onClick={handleClick}>
        <ArrowLeft />
        <div className="tinytext goBack-text">Atrás</div>
    </div>
  );
};
