import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    zodiacSign: ''
};

const ZodiacSignSlice = createSlice({
    name: "zodiacSignSlice",
    initialState,
    reducers: {
      setZodiacSign: (state, action) => {
        // console.log("slice/error/setZodiacSign", action.payload);
        state.zodiacSign = action.payload.zodiacSign;
      },
      clearZodiacZign: () => {
        return initialState;
      },
    },
  });
  const { reducer, actions } = ZodiacSignSlice;
  
  export const { setZodiacSign, clearZodiacZign} = actions
  
  export default reducer;