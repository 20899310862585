import { collection, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { PaymentMethodInterface } from "../Interfaces/PaymentMethodInterface";
import { DB, Functions } from "../FirebaseConfig/FirebaseConfig";
import { httpsCallable } from "firebase/functions";
import { AddPaymentInterface } from "../Interfaces/AddPaymentInterface";

// method to get the payment method by user id from firestore
export const getPaymentMethodByUserId = async (userId: string): Promise<PaymentMethodInterface[]> => {
    const q = query(collection(DB, "PaymentMethod"), where("userId", "==", userId), where("status", "==", true));
    const response = await getDocs(q);
    let data: PaymentMethodInterface[] = [];
    response.forEach((doc) => {
        data.push({id: doc.id, ...doc.data()} as PaymentMethodInterface);
    });
    return data;
};

// method to get the payment method active by user id from firestore
export const getPaymentMethodByUserActive = async (userId: string): Promise<PaymentMethodInterface | undefined> => {
    const q = query(collection(DB, "PaymentMethod"), where("userId", "==", userId), where("status", "==", true), where("active", "==", true));
    const response = await getDocs(q);
    let data: PaymentMethodInterface[] = [];
    response.forEach((doc) => {
        data.push({id: doc.id, ...doc.data()} as PaymentMethodInterface);
    });
    return data.length > 0 ? data[0] : undefined;
};

// method to add a payment method
export const addPaymentMethod = async (addPaymentData: AddPaymentInterface) => {
    // console.log("🚀 ~ addPaymentMethod ~ data:", addPaymentData);
    const addPaymentMethod = httpsCallable(Functions, 'addPaymentMethod');
    return addPaymentMethod(addPaymentData)
        .then((response: any) => {
            // console.log("PaymentMethod ~ response", response.data);
            if(response.data.object === 'error'){ 
                throw response.data;
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            // console.log("🚀 ~ addPaymentMethod ~ error:", error)
            throw error;
        });
}

// method to delete a payment method
export const deletePaymentMethod = async (paymentMethodId: string) => {
    const deletePaymentMethod = httpsCallable(Functions, 'deletePaymentMethod');
    return deletePaymentMethod({paymentMethodId})
        .then((response: any) => {
            // console.log("PaymentMethod ~ response", response.data);
            if(response.data.object === 'error'){ 
                throw response.data;
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            console.log("🚀 ~ deletePaymentMethod ~ error:", error)
            throw error;
        });
}