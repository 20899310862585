import React, { useEffect, useState } from "react";

import "./CountryList.css";
import { ItemCountry } from "../ItemCountry/ItemCountry";
import { TextField } from "../TextField/TextField";
import { GetCountryList } from "../../Service/Country";
import { toast } from "react-toastify";
import { ErrorInterface } from "../../Interfaces/ErrorInterface";

interface CountryListProps {
    name: string;
    showPhoneCode?: boolean;
    onClick: (name: string,imgFlag: string, alpha2Code: string, phoneCode? : string) => void;
    error?: ErrorInterface;
    value?: string;
    valueFlag?: string;
    disabled?: boolean;
}

export const CountryList: React.FC<CountryListProps> = ({
    name,
    showPhoneCode = false,
    onClick,
    error = {
        status: false,
        message: ""
    },
    value = "",
    valueFlag = "",
    disabled = false
}) => {

    // state to manage country list
    const [countryList, setCountryList] = useState<{country: string; alpha2Code: string, phoneCode: string}[]>([]);
    // state to manage country selected
    const [countrySelected, setCountrySelected] = useState<{country: string; alpha2Code: string, phoneCode: string, imgFlag: string}>();
    // state to manage search country text
    const [searchCountryText, setSearchCountryText] = React.useState<string>('');
    // state to manage search country list
    const [searchList, setSearchList] = React.useState<{country: string; alpha2Code: string, phoneCode: string }[]>([]);

    const [open, setOpen] = useState<boolean>(false);


    const handleSearchCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        setSearchCountryText(value);
        searchCountry(value);
        setOpen(value.length>0);
    }

    const handleCountryClick = (country: string, alpha2Code: string, phoneCode: string) => {
            setSearchCountryText("");
        setCountrySelected({country, alpha2Code, phoneCode, imgFlag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${alpha2Code.toLowerCase()}.svg`});
       
        showPhoneCode ? onClick(country, `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${alpha2Code.toLowerCase()}.svg`,alpha2Code, phoneCode) :
        onClick(country, `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${alpha2Code.toLowerCase()}.svg`, alpha2Code);
       
        setOpen(false);
    }

    const handleOpenList = () => {
        setOpen(true);
        setSearchCountryText("");
        setCountrySelected(undefined);
    }

    const searchCountry = (value: string) => {
        const filtered = countryList.filter((country) => {
            return country.country.toLocaleLowerCase().includes(value.toLocaleLowerCase());
        });

        if(showPhoneCode) {

            const result = filtered.reduce((acc: any, current) => {
                const split = current.phoneCode.split(",");
                return [...acc, ...split.map((phoneCode: string, idx) =>({country: current.country, alpha2Code: current.alpha2Code, phoneCode: idx === 0 ? phoneCode : "+"+phoneCode}))];
            }, []);
            
            setSearchList(result);
        } else {
            setSearchList(filtered);
        }

        
    }

    // obteniendo lista de paises
    const getCountries = async () => {
        try {
            const response:any = await GetCountryList();
            setCountryList(response);
        } catch (error) {
            console.log("🚀 ~ getCountries ~ error:", error)
            toast.error("Hubo un error al obtener la lista de paises");
        }
    }

    useEffect(() => {
        getCountries();
        if(value.length>0 && valueFlag.length>0) {
            setCountrySelected({country: value, alpha2Code: valueFlag, phoneCode: "", imgFlag: valueFlag});
        }
    }, []);

    return (
        <div className="countryList">
            <div className="countryList-container">
            {name.length>0 && <div className="countryList-text tinytext-header">{name}</div>}
                {!countrySelected  && <TextField
                    placeholder="Ingresa tu país"
                    value={searchCountryText}
                    label=""
                    onChange={handleSearchCountryChange}
                   
                />}
                {countrySelected && <div className="countryList-select" onClick={()=>!disabled && handleOpenList()}>
                    <img className="countryList-country--flag" src={countrySelected?.imgFlag} alt="santa-zinos" />
                    <div className="countryList-select--name">{countrySelected.country}</div>
                </div>}
                {open && 
                <div className="countryList--list">
                    {searchList.length>0 && (searchCountryText.length === 0 ? searchList.splice(0,5) : searchList).map((country, index) => (
                        <ItemCountry 
                            key={index}
                            alpha2Code={country.alpha2Code.toLocaleLowerCase()}
                            name={country.country}
                            description={showPhoneCode ? country.phoneCode : ""}
                            handleclick={()=>handleCountryClick(country.country, country.alpha2Code, country.phoneCode)}
                        />
                    ))}
                </div>
}
            </div>
            {error.status && <div className="tinytext-error">{error.message}</div>}
        </div>
    )
}