import { httpsCallable } from "firebase/functions";
import { DB, Functions } from "../FirebaseConfig/FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { SubscriptionInterface } from "../Interfaces/SubscriptionInterface";

export const CreateNewSuscription = async (data: {toke: string; userId: string, planId:string}) => {
    console.log("🚀 ~ CreateNewSuscription ~ data:", data);
    const processSupscripion = httpsCallable(Functions, 'processSupscripion');
    return processSupscripion(data)
        .then((response) => {
            console.log("🚀 ~ files: Quotation.service.ts ~ line 69 ~ .then ~ response", response)
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const GetSubscriptionById = async(subscriptionId: string): Promise<SubscriptionInterface> => {
    const response = await getDoc(doc(DB, "Subscription", subscriptionId)); // trae el documento segun "id" de la coleccion de firestore
    // console.log(response)
    return new Promise((resolve, reject) => {
        if(response.data()) {
            resolve({id: response.id, ...response.data()} as SubscriptionInterface)
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}

export const updateSuscription = async (data: {paymentMethodId: string, subscriptionId: string}) => {
    // console.log("🚀 ~ updateSuscription ~ data:", data);
    const updatePaymentMethodSubscription = httpsCallable(Functions, 'updatePaymentMethodSubscription');
    return updatePaymentMethodSubscription(data)
        .then((response) => {
            // console.log("🚀 ~ .then ~ response:", response);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}
