import React from "react";

import "./AboutSection.css";
import { Card } from "../Card/Card";
import { CardGradient } from "../CardGradient/CardGradient";
import { Footer } from "../Footer/Footer";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

interface AboutSectionProps {
    
};


export const AboutSection: React.FC<AboutSectionProps> = ({
   
}) => {

  return (
    <div className="aboutSection" style={{ backgroundImage: `url(${STORAGE_ASSETS}/images/png/ZINOS_BOTTOM-FONDO_ROJO_MISTICO_COMPLETO_SINZINOS.png)` }}>
      <section id="aboutSection" className="aboutSection-first">
        <div className="aboutSection-first-detail">
              <div className="mega-header aboutSection-text aboutSection-text-mobile">¿Quién es Zinos?</div>
              <div className="aboutSection-body">
                <div className="aboutSection-first-detail-animation">
                  <img src={STORAGE_ASSETS+'/images/png/zinos_bottom.png'} className="aboutSection-img"/>
                  <img src={STORAGE_ASSETS+'/images/png/zinos-test-1.png'} className="aboutSection-img-web"/>
                </div>
                <div className="aboutSection-second-detail">
                  <div className="mega-header aboutSection-text aboutSection-text-web">¿Quién es Zinos?</div>

                  <CardGradient className="aboutSection-card-gradient">
                    <Card 
                        description="Santa Zinos desde que empezó a publicar sus horóscopos, fue haciéndose de una legión de ﬁeles seguidores debido tanto a sus acertadas predicciones como por su lenguaje directo que motiva a sus lectores afrontar sus problemas. "
                        description2="Zinos, es la 'bruja' con más de tres millones de seguidores en Instagram."
                    />
                  </CardGradient>
                </div>
              </div>
        </div>
      </section>
      <Footer 
          isLogin={true}
        /> 
    </div>
  );
};
