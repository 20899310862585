import React from "react";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

type LogoProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Logo: React.FC<LogoProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/logo.svg"} alt="" />
    </div>
  );
};