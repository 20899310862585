import React from "react";

import "./Card.css";

interface CardProps {
    description: string,
    description2: string,
};


export const Card: React.FC<CardProps> = ({
    description,
    description2
}) => {

  return (
    <div className="card">
        <div className="card-content">
          <div className="smalltext card-subTitle">{description}</div>
          <div className="smalltext card-subTitle">{description2}</div>
        </div>
    </div>
  );
};